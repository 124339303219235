import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { updateCustomerApi } from "../../../../apis/customer.api";
import useValidate from "../../../../store/hooks/useValidator";
import { callApiAction } from "../../../../store/actions/commonAction";
import { callSnackBar } from "../../../../store/actions/snackbarAction";
import {
  CUSTOMER_TYPE,
  ORDER_STATUS,
  PAYMENT_TYPE,
  SNACK_BAR_VARIETNS,
} from "../../../../utils/constants";
import { closeModal } from "../../../../store/actions/modalAction";
import PurchasedProductUi from "./PurchasedProductUi";

const PurchasedProductController = ({
  customer_type,
  id,
  callBack = () => { },
  params,
}) => {
  const dispatch = useDispatch();
  const validate = useValidate();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    id: id,
    customer_type: CUSTOMER_TYPE.PURCHASED,
    payment_type: PAYMENT_TYPE.CASH,
    sold_details: {
      date: moment().toISOString(),
      amount: "",
      product: params?.trial_details?.product ?? "",
      order_status: ORDER_STATUS.PENDING,
    },
  });
  const [step, setStep] = useState(0);

  const validationSchemaTrial = React.useMemo(
    () => [
      {
        required: true,
        value: fields?.sold_details?.product,
        field: "product",
        message: "Product",
      },
      {
        required: true,
        value: fields?.sold_details?.date,
        field: "date",
        message: "Date",
      },
      {
        required: true,
        value: fields?.sold_details?.amount,
        field: "amount",
        message: "Amount",
      },
    ],
    [fields]
  );

  const updateFunction = async (e) => {
    e.preventDefault();
    const validationResponse = validate(validationSchemaTrial);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateCustomerApi({ ...fields, sold_details: { ...fields.sold_details, product: fields.sold_details.product._id, amount: fields.sold_details.amount - params?.trial_details?.amount || 0 } }),
          async (response) => {
            setLoading(false);
            dispatch(closeModal("purchased-product"));
            callBack();
            dispatch(
              callSnackBar(
                "Payment Done Successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
          },
          (err) => {
            setLoading(false);
            dispatch(callSnackBar("Error", SNACK_BAR_VARIETNS.error));
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const validationSchemaArr = [validationSchemaTrial];
    if (step == 1) {
      updateFunction(e);
      return;
    }
    const validationResponse = validate(validationSchemaArr[step]);

    if (validationResponse === true) {
      setStep(step + 1);
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onPrevStep = (e) => {
    e.preventDefault();
    console.log("this is fun call");
    if (!loading) {
      if (step == 0) {
        dispatch(closeModal("purchased-product"));
      } else if (step == 1) {
        setStep(step - 1);
      }
    }
  };

  return (
    <>
      <PurchasedProductUi
        loading={loading}
        onSubmit={onSubmit}
        fields={fields}
        setFields={setFields}
        step={step}
        setStep={setStep}
        onPrevStep={onPrevStep}
        params={params}
      />
    </>
  );
};

export default PurchasedProductController;
