import { useDispatch, useSelector } from "react-redux";

import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { callApiAction } from "../../../store/actions/commonAction";
import { assignedCustomerAPI } from "../../../apis/lead.api";
import { closeModal } from "../../../store/actions/modalAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import AsyncDropDown from "../../../components/inputs/AsyncDropDown";
import CustomInput from "../../../components/inputs/CustomInputs";
import { getUserApi } from "../../../apis/user.api";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants";


const AssignComponent = ({ id, callback = () => { } }) => {
  const dispatch = useDispatch();
  const title = "Assigned ";
  const modalKey = "assign";

  const params = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [dataNow, setDataNow] = useState({
    id: id,
    userId: ""
  });

  const updateFunction = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await assignedCustomerAPI(dataNow),
        async (response) => {
          setLoading(false);
          callback()
          dispatch(closeModal(modalKey));
          dispatch(
            callSnackBar(
              "Customer Assign Successfully",
              SNACK_BAR_VARIETNS.suceess
            )
          );
        },
        (err) => {
          setLoading(false);
          setDataNow({ ...dataNow, err });
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
        }
      )
    );
  };

  const updateClick = async (e) => {
    e.preventDefault();
    updateFunction();
  };

  const { user } = useSelector((state) => state);
  return (
    <>
      <CustomDialog
        id="assign"
        loading={loading}
        onSubmit={updateClick}
        title={title}
        closeText="Close"
        confirmText={"Update"}
      >
        <Box>

          <AsyncDropDown
            InputComponent={(props) => (
              <CustomInput
                label
                placeholder="Select "
                {...props}
              />
            )}
            lazyFun={async (props) => {
              return await getUserApi({
                ...props, assign: true
              });
            }}
            OptionComponent={({ option, ...rest }) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  {...rest}
                >
                  <Typography ml={3} variant="h5">
                    {option.name} {option.city ? `(${option.city})` : ""}
                  </Typography>
                </Box> 
              );
            }}
            onChange={async (changedVal) => {
              setDataNow({ ...dataNow, err: "", userId: changedVal?._id });
            }}
            titleKey={"name"}
            valueKey={"_id"}
          />

        </Box>
      </CustomDialog>
    </>
  );
};
export default AssignComponent;
