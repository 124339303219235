import React, { useEffect, useMemo, useState } from 'react'
import UpdateUserUi from './UpdateUserUi'
import { callApiAction } from '../../store/actions/commonAction';
import { getUserByIdApi, updateUserField } from '../../apis/user.api';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../store/actions/modalAction';
import { callSnackBar } from '../../store/actions/snackbarAction';
import { SNACK_BAR_VARIETNS } from '../../utils/constants';
import useValidate from '../../store/hooks/useValidator';
import { validatePAN } from '../../utils/helper';
import { fetchUserDetails } from '../../store/actions/userReducerAction';

const UpdateUserController = ({ id }) => {

    const [fields, setFields] = useState({
        err: '',
        id,
        bank_details: {
            bank_name: "",
            account_no: null,
            ifsc_code: "",
            cancel_check: "",
        },
        pan_no: "",
        pan_url: "",
        gst_no: "",
        certificate: "",
    });
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const { user } = useSelector(state => state)
    const validate = useValidate()
    const [originalDocument, setOriginalDocument] = useState({});


    const validationSchemaForUpdate = useMemo(() => ([
        {
            required: true,
            value: fields.bank_details.bank_name,
            field: 'Bank Name',
        },
        {
            required: true,
            value: fields.bank_details.account_no,
            field: 'Bank account Number',
        },
        {
            required: true,
            value: fields.bank_details.ifsc_code,
            field: 'Ifsc Code',
        },
        {
            required: true,
            value: fields.bank_details.cancel_check,
            field: 'Bank Proff',
        },
        {
            required: true,
            value: fields.pan_no,
            field: "Pan No",
        },
        {
            required: true,
            value: fields.pan_url,
            field: 'Pan Url',
        },
        {
            required: true,
            value: fields.gst_no,
            field: 'Gst No',
        },
        {
            required: true,
            value: fields.certificate,
            field: 'Gst Certificate',
        },
    ]), [fields])


    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { id }
        for (let field in fields) {

            if (originalDocument[field] && fields[field] && fields[field] != originalDocument[field]) {
                updatedData[field] = fields[field]
            }
            if (!originalDocument[field]) {
                updatedData[field] = fields[field]
            }
        }
        updatedData['mail_pass'] = fields['mail_pass']
        updatedData['phone'] = fields['phone']

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateUserField(updatedData),
                    async (response) => {
                        // await callBack(response, updatedData)
                        setLoading(false)
                        dispatch(callSnackBar("User Updated Successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal("UpdateUser"))
                        dispatch(closeModal("messagedialogupdate"))
                        dispatch(fetchUserDetails(undefined,
                            (res) => {
                                setLoading(false);
                            },
                            (err) => {
                                setLoading(false);
                            }));
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (id) {
            updateFunction()
        }
    }

    useEffect(() => {
        if (id) {
            setFields(user?.data)
            setOriginalDocument(user?.data)
        }
    }, [id]);
    console.log(" fields", fields)
    return (
        <UpdateUserUi
            fields={fields}
            setFields={setFields}
            onSubmit={onSubmit}
            setLoading={setLoading}
            loading={loading}
        />
    )
}

export default UpdateUserController