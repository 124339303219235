import {
  getLastReadTimestampApi,
  getNotifcationsApi,
  readNotifcationsApi,
  setLastReadTimestampApi,
} from "../../apis/notification.api";
import { actions, NOTIFICATION_TYPE } from "../../utils/constants";
import { callApiAction } from "./commonAction";

const redirectTo = (type, id) => {
  switch (type) {
    case NOTIFICATION_TYPE.general:
      return null;
    case NOTIFICATION_TYPE.leave:
      return `/calendar/leave/id/${id}`;
    case NOTIFICATION_TYPE.coff:
      return `/calendar/c-off/id/${id}`;
    case NOTIFICATION_TYPE.task:
      return `/tasks/detail/${id}`;
    case NOTIFICATION_TYPE.reimbursement:
      return `/reimbursement/get/${id}`;
    case NOTIFICATION_TYPE.wfh:
      return `/calendar/Pending/`;
    case NOTIFICATION_TYPE.announcements:
      return `/dashboard/`;
    default:
      return null;
  }
};

export const fetchNotificationAction = () => {
  return async (dispatch, getState) => {
    const userId = getState().user.id;
    const lastReadTimestamp = await getLastReadTimestampApi();

    dispatch(
      callApiAction(
        async () => await getNotifcationsApi(),
        (response) => {
          let newNotification = 0;

          if (response && Array.isArray(response) && response.length > 0) {
            for (let i = 0; i < response.length; i++) {
              response[i].redirect = redirectTo(
                response[i].notificationType,
                response[i].contentId
              );
              if (
                new Date(response[i].createdAt) > new Date(lastReadTimestamp)
              ) {
                newNotification++;
              }
            }
          }
          dispatch({
            type: actions.SET_NOTIFICATION_DATA,
            new: newNotification,
            data: response,
          });
        },
        (err) => {
          console.error(err);
        }
      )
    );
  };
};

export const readNotificationAction = () => {
  return async (dispatch, getState) => {
    const notifications = getState().notifications?.data;

    if (notifications && notifications.length > 0) {
      const lastReadTimestamp = notifications[0].createdAt;
      await setLastReadTimestampApi(lastReadTimestamp);
      dispatch(
        callApiAction(
          async () =>
            await readNotifcationsApi({
              ids: notifications.map((notification) => notification._id),
            }),
          (response) => {},
          (err) => {
            console.error(err);
          }
        )
      );
    }

    dispatch({ type: actions.NOTIFICATION_READ });
  };
};

export const readIndividualNotificationAction = (notificationId) => {
  return async (dispatch, getState) => {
    return dispatch(
      callApiAction(
        async () => await readNotifcationsApi({ id: [notificationId] }),
        (response) => {},
        (err) => {
          console.error(err);
        }
      )
    );
  };
};
