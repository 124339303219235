import { useDispatch } from "react-redux";
import { closeModal } from "../store/actions/modalAction";
import CustomDialog from "./layouts/common/CustomDialog";
import { useState } from "react";

const MessageDilog = ({
  title = "Error",
  message,
  onSubmit,
  confirmText,
  modalId = "error",
  closeText
}) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };

  const [loading, setLoading] = useState(false);

  const onClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    onSubmit ? await onSubmit(e) : handleClose();
  };

  return (
    <>
      <CustomDialog
        id={modalId}
        loading={loading}
        onClose={handleClose}
        onSubmit={onClick}
        title={title}
        closeText={closeText??"Close"}
        confirmText={confirmText ?? `Okay`}
      >
        {message}
      </CustomDialog>
    </>
  );
};
export default MessageDilog; 