import { useEffect, useState, useMemo, memo } from "react";
import { useDispatch } from "react-redux";
import { Chip, Typography } from "@mui/material";
import moment from "moment";
import {
  findObjectKeyByValue,
  OrderColor,
  titleCase,
  trialColor,
} from "../../utils/main";
import { CUSTOMER_TYPE, TRIALS_STATUS } from "../../utils/constants";
import { callApiAction } from "../../store/actions/commonAction";
import TrialsListUi from "./TrialsListUi";
import { getTrialsCountApi, getTrialsDataApi } from "../../apis/trials.api";
import CustomerInformationController from "../customer/CustomerViews/CustomerInformationController";
import { openModal } from "../../store/actions/modalAction";

const TrialsListController = ({ userId, isAccordion = false }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});
  const [salesValue, setSalesValue] = useState(0);

  const [selectTrialsType, setSelectTrialsType] = useState(CUSTOMER_TYPE.ALL);
  const [count, setCount] = useState([
    {
      count: 0,
      status: TRIALS_STATUS.ALL,
      _id: 0,
    },
    {
      count: 0,
      status: TRIALS_STATUS.ACTIVE,
      _id: 1,
    },
    {
      count: 0,
      status: TRIALS_STATUS.RETURN,
      _id: 2,
    },
    {
      count: 0,
      status: TRIALS_STATUS.CONVERTED,
      _id: 3,
    },
  ]);
  const [countLoading, setCountLoading] = useState(false);
  const [filters, setFilters] = useState({
    userId,
    pageNo: 1,
    pageSize: 10,
    searchable: [
      "customer_id.first_name",
      "last_name",
      "product_name",
      "box_id",
    ],
    search: "",
    role: "",
    sort: "",
    sortDirection: -1,
    startDate: "",
    endDate: "",
    deleted: false,
  });
  const fetchcountApi = getTrialsCountApi;
  const title = "Customers";

  const onVeiw = (params) => {
    dispatch(
      openModal(
        <CustomerInformationController id={params._id} />,
        "xl",
        true,
        "CustomerInformation"
      )
    );
  };

  const columns = useMemo(() => {
    return [
      {
        id: 1,
        fieldName: "trial_details.date",
        label: "Date",
        align: "left",
        sort: true,
        renderValue: (params) =>
          moment(params?.trial_details?.date).format("DD/MM/YYYY"),
      },
      {
        id: 2,
        fieldName: "first_name",
        label: "Name",
        align: "left",
        sort: true,
        renderValue: (params) => (
          <Typography
            variant="body2"
            onClick={() => {
              onVeiw(params);
            }}
            sx={{ cursor: "pointer" }}
          >
            {params?.first_name + " " + params?.last_name}
          </Typography>
        ),
      },
      {
        id: 3,
        fieldName: "product",
        label: "Product",
        align: "left",
        renderValue: (params) => params?.trial_details?.product?.product_name,
      },
      {
        id: 4,
        fieldName: "box_id",
        label: "Device Id",
        align: "left",
        renderValue: (params) => params?.trial_details?.box_id,
      },
      {
        id: 4,
        fieldName: "box_id",
        label: "Trial Period",
        align: "left",
        renderValue: (params) => {
          let date = moment(params?.trial_details?.date).format("YYYY-MM-DD");
          let today = moment().format("YYYY-MM-DD");
          const diffDays = moment(today).diff(date, "day");
          return (
            <Typography color={diffDays > 7 && "red"} variant="body2">
              {diffDays}&nbsp;days
            </Typography>
          );
        },
      },
      {
        id: 5,
        fieldName: "trial_details.trial_status",
        label: "Status",
        align: "left",
        sort: true,
        renderValue: (params) => {
          return (
            <Chip
              size="small"
              sx={{ minWidth: "80px" }}
              key={params?.trial_details?.trial_status}
              color={trialColor(params?.trial_details?.trial_status)}
              label={titleCase(
                findObjectKeyByValue(
                  params?.trial_details?.trial_status,
                  TRIALS_STATUS
                )
              )}
            />
          );
        },
      },
    ];
  }, [dispatch]);

  const fetchTrialsDataFun = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await getTrialsDataApi({ ...filters, status: selectTrialsType }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const fetchTrialsCount = () => {
    setCountLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchcountApi({ ...filters }),
        (response) => {
          setCount(response);
          setCountLoading(false);
        },
        (err) => {
          setCountLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchTrialsDataFun();
  }, [filters, selectTrialsType]);

  useEffect(() => {
    fetchTrialsCount();
  }, [filters.startDate, filters.endDate]);

  return (
    <TrialsListUi
      salesValue={salesValue}
      columns={columns}
      list={list}
      filters={filters}
      setFilters={setFilters}
      count={count}
      setSelectTrialsType={setSelectTrialsType}
      selectTrialsType={selectTrialsType}
      title={"Trials"}
      countLoading={countLoading}
      loading={loading}
    />
  );
};
export default memo(TrialsListController);
