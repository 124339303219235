import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SOLUTION_STATUS } from "../../../utils/constants";
import { useDispatch } from "react-redux";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import {
  fetchFeedbackProblemsApi,
  updateFeedbackProblemsStatusApi,
} from "../../../apis/feedback.api";
import { callApiAction } from "../../../store/actions/commonAction";

const ProblemSummaryDetailsView = ({ id, callBack = () => {},isNotModel=false }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchFeedbackProblems = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchFeedbackProblemsApi({ feedback_id: id }),
        (response) => {
          setList([]);
          for (let i = 1; i <= 48; i++) {
            setList((prev) => {
              let temp = [...prev];
              return [...temp, ...(response[`q${i}`]?.problems || [])];
            });
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const updateProblemStatus = (problemId, qNum) => {
    // setUpdateLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await updateFeedbackProblemsStatusApi({
            id: problemId,
            feedbackId: id,
            qNum: qNum,
          }),
        (response) => {
          fetchFeedbackProblems();
          callBack();
          // setUpdateLoading(false);
        },
        (err) => {
          // setUpdateLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchFeedbackProblems();
  }, [id]);
  return (
    <CustomDialog disableDirectClose={isNotModel} id={"problem-views"} title={"Problem Summary"}>
      <Stack spacing={2} sx={{ margin: "auto", mt: 1 }}>
        <TableContainer
          component={Card}
          sx={{ boxShadow: "none", border: "1px solid #e0e0e0" }}
        >
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: "grey.100" }}>
                <TableCell sx={{ fontWeight: "700" }}>#</TableCell>
                <TableCell sx={{ fontWeight: "700" }}>Problem</TableCell>
                <TableCell sx={{ fontWeight: "700" }} align="right">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                list.map((problem, index) => (
                  <TableRow
                    key={problem._id}
                    sx={{
                      "&:nth-of-type(odd)": {
                        backgroundColor: "grey.50",
                      },
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{problem.problem}</TableCell>

                    <TableCell align="right">
                      {!updateLoading ? (
                        problem?.status == SOLUTION_STATUS.SOLVED ? (
                          <Chip color="success" label="Solved" />
                        ) : (
                          <Chip
                            onClick={() => {
                              updateProblemStatus(problem._id, problem.qNum);
                            }}
                            color="error"
                            label="Pending"
                          />
                        )
                      ) : (
                        <CircularProgress />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {!loading && list.length == 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body">No Problems Found.</Typography>
          </Box>
        )}

        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Stack>
    </CustomDialog>
  );
};

export default ProblemSummaryDetailsView;
