import { useEffect, useState, useMemo, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import moment from "moment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  findObjectKeyByValue,
  OrderColor,
  OrderProcessColor,
  titleCase,
} from "../../utils/main";
import {
  CUSTOMER_TYPE,
  ORDER_PROCESS_STATUS,
  ORDER_PROCESS_STATUS_CHILDREN,
  ORDER_STATUS,
  TRIALS_STATUS,
  USER_ROLES,
} from "../../utils/constants";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal, openModal } from "../../store/actions/modalAction";
import MessageDilog from "../../components/MessageDilog";
import { Check, Clear, DoneAll } from "@mui/icons-material";
import {
  fetchOrderCountApi,
  fetchOrders,
  updateOrder,
} from "../../apis/order.api";
import OrderShippController from "./Lead/CreateLeadController";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OrderViewController from "./OrderViewController";
import CustomerInformationController from "../customer/CustomerViews/CustomerInformationController";
import LeadListUi from "./LeadListUi";
import {
  createLead,
  fetchleadsCount,
  fetchleadsData,
} from "../../apis/lead.api";
import CreateLeadController from "./Lead/CreateLeadController";
import LoginIcon from "@mui/icons-material/Login";
import AssignComponent from "./Lead/AssignComponent";
import NetworkPingIcon from "@mui/icons-material/NetworkPing";

const ActionComponent = ({ callback = () => { }, params, setParams }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  const onVeiw = () => {
    dispatch(
      openModal(
        <CustomerInformationController id={params._id} params={params} />,
        "xl",
        true,
        "CustomerInformation"
      )
    );
  };

  const onAssign = () => {
    dispatch(
      openModal(
        <AssignComponent callback={callback} id={params._id} params={params} />,
        "md",
        true,
        "assign"
      )
    );
  };

  const onEdit = (e) => {
    e.preventDefault();
    dispatch(closeModal("CustomerInformation"));
    navigate("/customer/create", { state: { id: params._id } });
  };

  return (
    <Box sx={{ width: "100%", alignItems: "center", display: "flex" }}>
      <Tooltip title="View">
        <IconButton size="small" onClick={onVeiw}>
          <VisibilityIcon color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      {user.data.role == USER_ROLES.CRM && params?.parent_id?.length < 1 && (
        <Tooltip title="Assign">
          <IconButton size="small" onClick={onAssign}>
            <LoginIcon color="info" fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
      {user.data.role != USER_ROLES.CRM &&
        params.customer_type == null &&
        params?.primary_parent_id?._id == user?.data?._id && (
          <Tooltip title="Connect">
            <IconButton size="small" onClick={onEdit}>
              <NetworkPingIcon color="info" fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
    </Box>
  );
};
const LeadListController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});
  const [selectOrderType, setSelectOrderType] = useState("all");
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    searchable: [
      "customer_id.first_name",
      "last_name",
      "product_name",
      "box_id",
    ],
    search: "",
    role: "",
    sort: "createdAt",
    sortDirection: -1,
    startDate: "",
    endDate: "",
    deleted: false,
  });

  const onVeiw = (params) => {
    dispatch(
      openModal(
        <CustomerInformationController id={params._id} />,
        "xl",
        true,
        "CustomerInformation"
      )
    );
  };

  const columns = useMemo(() => {
    return [
      {
        id: 1,
        fieldName: "date",
        label: "Date",
        align: "left",
        sort: true,
        renderValue: (params) =>
          moment(params?.lead?.date).format("DD/MM/YYYY"),
      },
      {
        id: 2,
        fieldName: "customer",
        label: "Customer",
        align: "left",
        sort: true,
        renderValue: (params) => (
          <Typography
            variant="body2"
            onClick={() => {
              onVeiw(params);
            }}
            sx={{ cursor: "pointer" }}
          >
            {params?.first_name + " " + params?.last_name}
          </Typography>
        ),
      },
      {
        id: 3,
        fieldName: "phone",
        label: "Phone No.",
        align: "left",
        sort: true,
      },
      {
        id: 4,
        fieldName: "lead_by",
        label: "Lead By",
        align: "left",
        renderValue: (params) => params?.lead_by?.name ?? "NA",
      },
      {
        id: 5,
        fieldName: "assign_to",
        label: "Assign To",
        align: "left",
        renderValue: (params) => params?.primary_parent_id?.name ?? "NA",
      },
      {
        id: 6,
        fieldName: "status",
        label: "Status",
        align: "left",
        renderValue: (params) => params?.parent_id?.length == 0 ? "Unassign" : params?.customer_type == null ? "Assign" : params?.customer_type == 4 ? "Connected" : findObjectKeyByValue(params?.customer_type, CUSTOMER_TYPE),
      },
      {
        id: 7,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            params={params}
            setParams={setParams}
            callback={() => {
              fetchLeadsList();
              fetchLeadCountFun();
            }}
          />
        ),
      },
    ];
  }, [dispatch]);

  const fetchLeadsList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchleadsData({ ...filters, filter_type: selectOrderType }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const onCreateLead = () => {
    dispatch(
      openModal(
        <CreateLeadController
          callBack={() => {
            fetchLeadCountFun();
          }}
        />,
        "xl",
        true,
        "CreateLead"
      )
    );
  };

  const [countLoading, setCountLoading] = useState(false);
  const [count, setCount] = useState();
  const fetchLeadCountFun = () => {
    setCountLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchleadsCount({ ...filters }),
        (response) => {
          setCount(response);
          setCountLoading(false);
        },
        (err) => {
          setCountLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchLeadCountFun();
  }, [filters.startDate, filters.endDate]);

  useEffect(() => {
    if (selectOrderType == null) {
      setSelectOrderType("all");
    } else {
      fetchLeadsList();
    }
  }, [count, selectOrderType]);

  return (
    <LeadListUi
      columns={columns}
      list={list}
      filters={filters}
      loading={loading}
      setFilters={setFilters}
      setSelectOrderType={setSelectOrderType}
      selectOrderType={selectOrderType}
      setCountLoading={setCountLoading}
      countLoading={countLoading}
      count={count}
      createLead={onCreateLead}
    />
  );
};
export default memo(LeadListController);
