import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { PAYMENT_TYPE, SNACK_BAR_VARIETNS } from "../../../../utils/constants";
import { callApiAction } from "../../../../store/actions/commonAction";
import { callSnackBar } from "../../../../store/actions/snackbarAction";
import { addPaymentWithUPI } from "../../../../apis/payment.api";
import Payment from "../PaymentDetails/Payment";
import loadingImg from "../../../../assets/images/loading.gif";
import { formatNumberCustomPattern } from "../../../../utils/helper";
import { ContentCopy } from "@mui/icons-material";

const TrialProductPaymentUi = ({ fields, setFields }) => {
  const [url, setUrl] = useState("");
  const dispatch = useDispatch();
  const [buttonstep, setButtonStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const onUpiPayment = (e) => {
    e.preventDefault();
    setButtonStep(1);
    dispatch(
      callApiAction(
        async () =>
          await addPaymentWithUPI({
            ...fields,
            amount: fields.sold_details.amount,
            upiId: "wehearinnovations.62738955@hdfcbank",
          }),
        async (response) => {
          setUrl(response);
          setFields({ ...fields, payment_type: PAYMENT_TYPE.UPI });
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          dispatch(callSnackBar("Error", SNACK_BAR_VARIETNS.error));
          setFields({ ...fields, err });
        }
      )
    );
  };

  const onCashPayment = (e) => {
    e.preventDefault();
    setButtonStep(0);
    setFields({ ...fields, payment_type: PAYMENT_TYPE.CASH });
    setUrl("");
  };

  return (
    <>
      <Box
        mb={2}
        sx={{
          display: "flex",
          gap: "5px",
        }}
      >
        <ButtonGroup
          sx={{ color: "#101536" }}
          fullWidth
          size="large"
          aria-label="Large button group"
        >
          <Button
            sx={buttonstep !== 0 && { backgroundColor: "white" }}
            variant={buttonstep == 0 ? "contained" : "outlined"}
            onClick={(e) => onCashPayment(e)}
            fullWidth
          >
            Cash
          </Button>

          <Button
            sx={buttonstep !== 1 && { backgroundColor: "white" }}
            variant={buttonstep == 1 ? "contained" : "outlined"}
            onClick={(e) => onUpiPayment(e)}
            fullWidth
          >
            UPI
          </Button>

          <Button
            sx={buttonstep !== 2 && { backgroundColor: "white" }}
            variant={buttonstep == 2 ? "contained" : "outlined"}
            onClick={() => { setButtonStep(2); setFields({ ...fields, payment_type: PAYMENT_TYPE.CARD }) }}
            fullWidth
          >
            Credit Card/Debit Card
          </Button>
        </ButtonGroup>
      </Box>

      {buttonstep == 0 && (
        <Grid item xs={5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              padding: "16px",
            }}
          >
            <Typography variant="body1">
              <strong>Amount :</strong>{" "}
              {formatNumberCustomPattern(fields?.sold_details?.amount)}
            </Typography>
            <Typography variant="body1">
              <strong>Date :</strong>{" "}
              {new Date(fields?.sold_details?.date).toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}
            </Typography>
          </Box>
        </Grid>
      )}

      {buttonstep == 1 && (
        <Box
          sx={{
            width: "100%",
            minHeight: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading || !url ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "60vh",
                justifyContent: "center",
              }}
            >
              <img
                src={loadingImg}
                alt="Loading..."
                style={{ width: "60px", marginTop: "10px" }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                padding: "16px",
                minWidth: "60%",
              }}
            >
              {" "}
              <Typography
                variant="h4"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Scan the QR code to proceed with payment
              </Typography>
              <Grid container alignItems="center">
                {/* Left Section: Basic Details */}
                <Grid item xs={5}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      width: "100%",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      gap: "8px",
                      padding: "16px",
                    }}
                  >
                    <Typography variant="body1">
                      <strong>Amount :</strong>{" "}
                      {formatNumberCustomPattern(fields?.sold_details?.amount)}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Date :</strong>{" "}
                      {new Date(fields?.sold_details?.date).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )}
                    </Typography>
                  </Box>
                </Grid>

                {/* Middle Section: Vertical Line */}
                <Grid item xs={1}>
                  <Box
                    sx={{
                      height: "100%",
                      borderLeft: "2px solid #CCCCCC",
                    }}
                  ></Box>
                </Grid>

                {/* Right Section: QR Code */}
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={url}
                        alt="QR Code"
                        style={{ width: "200px", marginTop: "10px" }}
                      />

                      <Typography
                        variant="body1"
                        sx={{ marginTop: 2, marginBottom: 2 }}
                      >
                        wehearinnovations.62738955@hdfcbank
                      </Typography>

                      <IconButton
                        onClick={() => {
                          navigator.clipboard
                            .writeText("wehearinnovations.62738955@hdfcbank")
                            .then(() => {
                              dispatch(
                                callSnackBar(
                                  "Copied to clipboard",
                                  SNACK_BAR_VARIETNS.suceess
                                )
                              );
                            })
                            .catch((err) => {
                              dispatch(
                                callSnackBar(
                                  "Failed to Copy",
                                  SNACK_BAR_VARIETNS.error
                                )
                              );
                            });
                        }}
                      >
                        <ContentCopy />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      )}

      {buttonstep == 2 && (
        <Payment
          type="Trial"
          date={fields?.sold_details?.date}
          amount={formatNumberCustomPattern(fields?.sold_details?.amount)}
        />
      )}
    </>
  );
};

export default TrialProductPaymentUi;
