import { useEffect, useState, useMemo, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import moment from "moment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  findObjectKeyByValue,
  OrderColor,
  OrderProcessColor,
  titleCase,
} from "../../utils/main";
import {
  CUSTOMER_TYPE,
  ORDER_PROCESS_STATUS,
  ORDER_PROCESS_STATUS_CHILDREN,
  ORDER_STATUS,
  TRIALS_STATUS,
  USER_ROLES,
} from "../../utils/constants";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal, openModal } from "../../store/actions/modalAction";
import MessageDilog from "../../components/MessageDilog";
import { Check, Clear, DoneAll } from "@mui/icons-material";
import OrderListUi from "./OrderListUi";
import {
  fetchOrderCountApi,
  fetchOrders,
  updateOrder,
} from "../../apis/order.api";
import OrderShippController from "./Order Shipp/OrderShippController";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OrderViewController from "./OrderViewController";
import CustomerInformationController from "../customer/CustomerViews/CustomerInformationController";

const ActionComponent = ({ callback = () => { }, params, setParams }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  const actionFun = (data) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await updateOrder({ ...data, customer_id: params.customer_id._id, }),
        (response) => {
          setParams({ ...params });
          callback();
          dispatch(closeModal());
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const onTakeReturn = (id) => {
    dispatch(closeModal());
    dispatch(
      openModal(
        <CustomerInformationController
          id={id}
          callBack={() => {
            dispatch(closeModal());
            callback();
          }}
        />,
        "xl",
        true,
        "CustomerInformation"
      )
    );
  };

  const onFullFillFun = (data) => {
    console.log("data", data);
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={() => {
            data?.params?.customer_id?.trial_details?.status ==
              CUSTOMER_TYPE.TRIALS &&
              data?.params?.customer_id?.trial_details?.trial_status ==
              TRIALS_STATUS.ACTIVE
              ? onTakeReturn(params.customer_id._id)
              : actionFun(data);
          }}
          confirmText={
            data?.params?.customer_id?.trial_details?.status ==
              CUSTOMER_TYPE.TRIALS &&
              data?.params?.customer_id?.trial_details?.trial_status ==
              TRIALS_STATUS.ACTIVE
              ? "Okay"
              : "Yes"
          }
          title="FulFill Order"
          message={(data?.params?.customer_id?.trial_details?.status === CUSTOMER_TYPE.TRIALS &&
            data?.params?.customer_id?.trial_details?.trial_status === TRIALS_STATUS.ACTIVE
            ? `You need to take the trial device return before fulfilling the order.`
            : data?.status === ORDER_PROCESS_STATUS.FULFILLED
              ? `Confirm that the device and all accessories are delivered to the customer.`
              : `Are you sure your status is marked as ${findObjectKeyByValue(
                data?.status,
                ORDER_PROCESS_STATUS
              )} ?`)}
        />,
        "sm"
      )
    );
  };

  const onOrderFun = (data) => {
    dispatch(
      openModal(
        <OrderShippController params={params} callBack={callback} />,
        "sm",
        false,
        "orderShip"
      )
    );
  };

  const onVeiw = (id) => {
    dispatch(
      openModal(
        <OrderViewController id={params._id} params={params} />,
        "md",
        true,
        "OrderInformation"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "center", display: "flex" }}>
      <Tooltip title="View">
        <IconButton size="small" onClick={onVeiw}>
          <VisibilityIcon size="small" color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      {(user.data._id == params.from._id ||
        user.data.role == USER_ROLES.WEHEAR_ADMIN ||
        user.data.role == USER_ROLES.SUPER_ADMIN) && (
          <>
            {(((user.data.role == USER_ROLES.WEHEAR_ADMIN ||
              user.data.role == USER_ROLES.SUPER_ADMIN) &&
              params.status == ORDER_PROCESS_STATUS.ORDER_RECEIVED) ||
              (!(
                user.data.role == USER_ROLES.WEHEAR_ADMIN ||
                user.data.role == USER_ROLES.SUPER_ADMIN
              ) &&
                params.status ==
                ORDER_PROCESS_STATUS_CHILDREN.ORDER_SHIPPED)) && (
                <>
                  <Tooltip
                    title={
                      user.data.role == USER_ROLES.WEHEAR_ADMIN ||
                        user.data.role == USER_ROLES.SUPER_ADMIN
                        ? findObjectKeyByValue(
                          ORDER_PROCESS_STATUS.ORDER_ACCEPTED,
                          ORDER_PROCESS_STATUS
                        )
                        : findObjectKeyByValue(
                          ORDER_PROCESS_STATUS.ORDER_DELIVERED,
                          ORDER_PROCESS_STATUS
                        )
                    }
                  >
                    <IconButton
                      size="small"
                      onClick={() => {
                        onFullFillFun({
                          status:
                            user.data.role == USER_ROLES.WEHEAR_ADMIN ||
                              user.data.role == USER_ROLES.SUPER_ADMIN
                              ? ORDER_PROCESS_STATUS.ORDER_ACCEPTED
                              : ORDER_PROCESS_STATUS.ORDER_DELIVERED,
                          id: params._id,
                        });
                      }}
                    >
                      <Check color="success" fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </>
              )}

            {!(
              user.data.role == USER_ROLES.WEHEAR_ADMIN ||
              user.data.role == USER_ROLES.SUPER_ADMIN
            ) &&
              params.status == ORDER_PROCESS_STATUS_CHILDREN.ORDER_DELIVERED && (
                <>
                  <Tooltip
                    title={"Fulfill"}
                  >
                    <IconButton
                      size="small"
                      onClick={() => {
                        onFullFillFun({
                          status: ORDER_PROCESS_STATUS.FULFILLED,
                          id: params._id,
                          params: params,
                        });
                      }}
                    >
                      <DoneAll color="success" fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </>
              )}

            {(user.data.role == USER_ROLES.WEHEAR_ADMIN ||
              user.data.role == USER_ROLES.SUPER_ADMIN) &&
              params.status == ORDER_PROCESS_STATUS.ORDER_ACCEPTED && (
                <>
                  <Tooltip title="Shipping Order">
                    <IconButton
                      size="small"
                      onClick={() => {
                        onOrderFun({
                          status: ORDER_PROCESS_STATUS.ORDER_ACCEPTED,
                          id: params._id,
                        });
                      }}
                    >
                      <LocalShippingIcon color="primary" fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
          </>
        )}
    </Box>
  );
};
const OrderListController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});
  const [selectOrderType, setSelectOrderType] = useState(null);
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    searchable: [
      "customer_id.first_name",
      "last_name",
      "product_name",
      "box_id",
    ],
    search: "",
    role: "",
    sort: "createdAt",
    sortDirection: -1,
    startDate: "",
    endDate: "",
    deleted: false,
  });

  const onVeiw = (params) => {
    dispatch(
      openModal(
        <CustomerInformationController id={params.customer_id._id} />,
        "xl",
        true,
        "CustomerInformation"
      )
    );
  };

  const columns = useMemo(() => {
    return [
      {
        id: 1,
        fieldName: "date",
        label: "Date",
        align: "left",
        sort: true,
        renderValue: (params) => moment(params?.date).format("DD/MM/YYYY"),
      },
      {
        id: 2,
        fieldName: "customer",
        label: "Customer",
        align: "left",
        sort: true,
        renderValue: (params) => (
          <Typography variant="body2"
            onClick={() => {
              onVeiw(params);
            }}
            sx={{ cursor: "pointer" }}
          >
            {params?.customer_id?.first_name +
              " " +
              params?.customer_id?.last_name}
          </Typography>
        ),
      },
      {
        id: 3,
        fieldName: "product",
        label: "Product",
        align: "left",
        renderValue: (params) => params?.product?.product_name,
      },
      {
        id: 4,
        fieldName: "order_from",
        label: "Order From",
        align: "left",
        renderValue: (params) => params?.from?.name,
      },
      {
        id: 5,
        fieldName: "status",
        label: "Status",
        align: "left",
        sort: true,
        renderValue: (params) => {
          return (
            <Chip
              sx={{ minWidth: "120px" }}
              size="medium"
              color={OrderProcessColor(params?.status)}
              label={titleCase(
                findObjectKeyByValue(
                  params?.status,
                  user.data.role == USER_ROLES.WEHEAR_ADMIN ||
                    user.data.role == USER_ROLES.SUPER_ADMIN
                    ? ORDER_PROCESS_STATUS
                    : ORDER_PROCESS_STATUS_CHILDREN
                )
              )}
            />
          );
        },
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            params={params}
            setParams={setParams}
            callback={fetchOrderCount}
          />
        ),
      },
    ];
  }, [dispatch]);

  const fetchOrderList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchOrders({ ...filters, status_type: selectOrderType }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (selectOrderType != null) {
      fetchOrderList();
    }
  }, [filters, selectOrderType]);
  const [countLoading, setCountLoading] = useState(false);
  const [count, setCount] = useState();
  const fetchOrderCount = () => {
    setCountLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchOrderCountApi({ ...filters }),
        (response) => {
          setCount(response);
          setCountLoading(false);
        },
        (err) => {
          setCountLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchOrderCount();
  }, [filters.startDate, filters.endDate]);

  useEffect(() => {
    if (count?.length > 0) {
      if (selectOrderType == null) {
        setSelectOrderType(count[0].status);
      } else {
        fetchOrderList();
      }
    }
  }, [count]);

  return (
    <OrderListUi
      columns={columns}
      list={list}
      filters={filters}
      loading={loading}
      setFilters={setFilters}
      setSelectOrderType={setSelectOrderType}
      selectOrderType={selectOrderType}
      setCountLoading={setCountLoading}
      countLoading={countLoading}
      count={count}
    />
  );
};
export default memo(OrderListController);
