import { Box, ListItem, Typography } from "@mui/material";
import AsyncDropDown from "../../../../components/inputs/AsyncDropDown";
import { fetchProductApi } from "../../../../apis/product.api";
import { StyledSearchBar } from "../../../../components/inputs/SearchBar";
import CustomInput from "../../../../components/inputs/CustomInputs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const PurchasedProductDetailsUi = ({ loading, fields, setFields }) => {
  return (
    <>
      <Box mt={4}>
        <Box height={"10px"}>
          <Typography variant="h5" color={"red"}>
            {fields?.err}
          </Typography>
        </Box>
        <Box
          gap={4}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box mt={2} width={"50%"}>
            <AsyncDropDown
              defaultVal={
                fields?.sold_details?.product?._id && {
                  _id: fields?.sold_details?.product?._id,
                  product_name: fields?.sold_details?.product?.product_name,
                }
              }
              lazyFun={async (para) =>
                await fetchProductApi({ ...para, allStatus: true })
              }
              OptionComponent={({ option, ...rest }) => {
                return <ListItem {...rest}>{option.product_name}</ListItem>;
              }}
              onChange={(changedVal) => {
                console.log("this is changes value", changedVal);
                setFields((prevFields) => ({
                  ...prevFields,
                  sold_details: {
                    ...prevFields.sold_details,
                    product: changedVal ?? {},
                  },
                  err: "",
                }));
              }}
              titleKey={"product_name"}
              valueKey={"_id"}
              InputComponent={(params) => (
                <StyledSearchBar
                  placeholder={"Select Product*"}
                  {...params}
                  margin="none"
                />
              )}
            />
          </Box>

          <Box width={"50%"}>
            <CustomInput
              disabled={loading}
              value={fields?.sold_details?.amount}
              onChange={(e) =>
                setFields({
                  err: "",
                  ...fields,
                  sold_details: {
                    ...fields.sold_details,
                    amount: e.target.value,
                  },
                })
              }
              type="text"
              label={"Amount*"}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PurchasedProductDetailsUi;
