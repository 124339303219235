import React, { useState } from "react";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { closeModal } from "../../../store/actions/modalAction";
import { useDispatch } from "react-redux";
import {
  FEEDBACK_STATUS,
  FEEDBACK_TYPE,
  ORDER_PROCESS_STATUS,
  SOLUTION_STATUS,
} from "../../../utils/constants";
import { callApiAction } from "../../../store/actions/commonAction";
import FeedbackUpdateUi from "./FeedbackUpdateUi";
import { updateFeedBackDataApi } from "../../../apis/feedback.api";
import { useEffect } from "react";
import FeedBack1Ui from "./FeedBack1Ui";
import useValidate from "../../../store/hooks/useValidator";
import { Box } from "@mui/material";

const FeedBack1Controller = ({ callBack = () => {}, fb1 }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const validate = useValidate();
  const [error, setError] = useState("");
  const [fields, setFields] = useState({
    q1: {
      ans: "",
      problems: [],
    },
    q2: {
      ans: "",
      problems: [],
    },
    q3: {
      ans: "",
      problems: [],
    },
    q4: {
      ans: "",
      problems: [],
    },
    q5: {
      ans: "",
      problems: [],
    },
    q6: {
      ans: "",
      problems: [],
    },
    q7: {
      ans: "",
      problems: [],
    },
    q8: {
      ans: "",
      problems: [],
    },
    q9: {
      ans: "",
      problems: [],
    },
    q10: {
      ans: "",
      problems: [],
    },
    q11: {
      ans: "",
      problems: [],
    },
    q12: {
      ans: "",
      problems: [],
    },
    q13: {
      ans: "",
      problems: [],
    },
    q14: {
      ans: "",
      problems: [],
    },
    q15: {
      ans: "",
      problems: [],
    },
  });

  const validation = () => {
    let i = 0;
    Object.keys(fields).map((key, index) => {
      if (fields[key].ans.length > 0) {
        i++;
      }

      console.log(
        "this is data regarding the question ans",
        fields[key].ans,
        i
      );
    });

    if (i >= 5) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // setLoading(true);

    const validationResponse = validation();

    console.log("this is fields", validationResponse);
    const cleanedFields = Object.keys(fields).reduce((acc, key) => {
      acc[key] = {
        ans: fields[key].ans,
        problems: fields[key].problems?.filter((problem) => problem !== ""),
      };
      return acc;
    }, {});

    console.log("this is problems fields", fields);

    if (validationResponse === true) {
      dispatch(
        callApiAction(
          async () =>
            await updateFeedBackDataApi({
              id: fb1,
              feedback_type: FEEDBACK_TYPE["FEEDBACK 1"],
              ...cleanedFields,
            }),
          (response) => {
            callBack();
            dispatch(closeModal("updateFeedback"));
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        )
      );
    } else {
      setError("At least 5 question answers are required before submitting the feedback.");
      setLoading(false);
    }
  };

  return (
    <CustomDialog
      id={"updateFeedback"}
      loading={loading}
      onSubmit={onSubmit}
      title={"Add Feedback 1"}
      closeText="Close"
      confirmText={"Submit"}
    >
      <Box height="70vh" width="100%">
        <FeedBack1Ui
          onSubmit={onSubmit}
          error={error}
          fields={fields}
          setFields={setFields}
        />
      </Box>
    </CustomDialog>
  );
};

export default FeedBack1Controller;
