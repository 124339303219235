import React, { useState } from "react";
import OrderShippUi from "./OrderShippUi";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { updateOrder } from "../../../apis/order.api";
import { closeModal } from "../../../store/actions/modalAction";
import { useDispatch } from "react-redux";
import { ORDER_PROCESS_STATUS } from "../../../utils/constants";
import { callApiAction } from "../../../store/actions/commonAction";

const OrderShippController = ({ callBack = () => {}, params }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [fields, setFields] = useState({
    box_id: "",
    tracking_id: "",
    tracking_link: "",
    courier_name: "",
    status: ORDER_PROCESS_STATUS.ORDER_SHIPPED,
    shipping_date: new Date(),
    err: "",
  });

  console.log("first errorrrrrr", error);

  const [loadingVerified, setLoadingVerified] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    if (fields.box_id.length < 1) {
      setFields({ ...fields, err: "Box ID is required" });
      return;
    }

    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await updateOrder({
            id: params._id,
            customer_id: params.customer_id._id,
            ...fields,
          }),
        (response) => {
          callBack();
          dispatch(closeModal("orderShip"));
          setLoading(false);
        },
        (err) => {
          console.log("currenygd g error", err);
          setLoading(false);
          setFields({ ...fields, err });
        }
      )
    );
  };

  return (
    <CustomDialog
      id={"orderShip"}
      loading={loading}
      onSubmit={
        !(error.length > 0 || loadingVerified)
          ? onSubmit
          : (e) => {
              e.preventDefault();
            }
      }
      title={"Order Ship"}
      closeText="Close"
      confirmText={"Submit"}
    >
      <OrderShippUi
        error={error}
        setError={setError}
        params={params}
        fields={fields}
        setFields={setFields}
        setLoadingVerified={setLoadingVerified}
        setLoading={setLoading}
      />
    </CustomDialog>
  );
};

export default OrderShippController;
