import {
    Box,
    Button,
    ButtonGroup,
    InputAdornment,
    Paper,
    Skeleton,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomisedMonthPicker from "../../components/layouts/common/CustomisedMonthPicker";
import DataTable from "../../components/tables/DataTable";
import OrderComponentsUi from "../../components/button/OrderComponentsUi";
import { FilterTitleBox } from "../../components/layouts/OneViewBox";
import { useSelector } from "react-redux";
import ButtonComponentsUi from "../../components/button/ButtonComponentsUi";

const IncentiveListUi = ({
    data,
    setuserId,
    loading,
    columns,
    list,
    filters,
    setFilters,
    step,
    userIncentive,
    date,
    setDate,
}) => {
    // const IncentiveListUi = ({ date, setDate, setStep, step, columns, list, filters, setFilters, loading, roles, setRoles }) => {
    //     const { user } = useSelector(state => state)
    //     console.log("object roles", roles);

    return (
        <Paper
            elevation={0}
            sx={{ width: "100%", minHeight: "100%", padding: 3, marginTop: "10px" }}
        >
            <Box>
                <FilterTitleBox>
                    <Typography variant="h3" color={"primary"}>
                        Incentive
                    </Typography>
                </FilterTitleBox>
                <Box
                    mt={2}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <CustomisedMonthPicker date={date} setDate={setDate} />
                </Box>

                <Box>
                    {loading ? (
                        <Box
                            mb={5}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                                height: "15vh",
                            }}
                        >
                            {[0, 1, 2, 3]?.map((row) => (
                                <Skeleton
                                    key={row}
                                    variant="rectangular"
                                    width={"24%"}
                                    height={120}
                                    sx={{
                                        borderRadius: "8px",
                                        boxShadow:
                                            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                    }}
                                />
                            ))}
                        </Box>
                    ) : (
                        <>
                            <Box
                                mt={4}
                                gap={4}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    width: "100%",
                                    alignItems: "center",
                                }}
                            >
                                <OrderComponentsUi
                                    count={list?.result?.totalSalesAmount ?? 0}
                                    loading={false}
                                    Title={"Total Sales"}
                                />
                                <OrderComponentsUi
                                    count={list?.result?.totalOnHoldIncentive ?? 0}
                                    loading={false}
                                    Title={"On Hold Incentive"}
                                />
                                <OrderComponentsUi
                                    count={list?.result?.totalIncentive ?? 0}
                                    loading={false}
                                    Title={"Receivable Incentive"}
                                />

                                <OrderComponentsUi
                                    count={list?.result?.paidIncentive ?? 0}
                                    loading={false}
                                    Title={"Paid Incentive"}
                                />

                                <OrderComponentsUi
                                    count={list?.result?.unpaidIncentive ?? 0}
                                    loading={false}
                                    Title={"Unpaid Incentive"}
                                />
                            </Box>
                        </>
                    )}
                </Box>
                {/* <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                    <PaddingBoxInDesktop mt={4} mb={4} sx={{ display: "flex", width: "20vw" }}>
                        <AsyncSearchBar
                            fullWidth
                            title="Search Seller Name"
                            size="small"
                            placeholder={"Search Seller Name"}
                            defaultValue={filters?.search}
                            onChange={(changedVal) => {
                                setFilters({
                                    ...filters,
                                    pageNo: 1,
                                    pageSize: 10,
                                    search: changedVal,
                                });
                            }}
                        />
                    </PaddingBoxInDesktop>
                </Box> */}
                <Box mt={4}>
                    <DataTable
                        columns={columns}
                        rows={list?.result?.list?.length > 0 ? list?.result?.list : []}
                        count={list?.result?.list?.length ?? 0}
                        filters={filters}
                        setFilters={setFilters}
                        loading={loading}
                    />
                </Box>
            </Box>
        </Paper>
    );
};

export default IncentiveListUi;
