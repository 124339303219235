import React from "react";
import { Box, Paper, Typography, Grid, Step, StepLabel } from "@mui/material";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import {
  CustomStepper,
  CustomConnector,
  CustomStepIcon,
} from "../../components/layouts/common/Stepper";
import moment from "moment";
import { ORDER_PROCESS_STATUS } from "../../utils/constants";

const OrderViewController = ({ params }) => {
  // STEP MAPPING: Map each status to the correct step index
  const stepMap = {
    [ORDER_PROCESS_STATUS.ORDER_RECEIVED]: 0,
    [ORDER_PROCESS_STATUS.ORDER_ACCEPTED]: 1,
    [ORDER_PROCESS_STATUS.ORDER_REJECTED]: 2, // If rejected, it will appear as the 3rd step
    [ORDER_PROCESS_STATUS.ORDER_SHIPPED]: 2, // SHIFT this to index 2 because "Order Rejected" won't appear
    [ORDER_PROCESS_STATUS.ORDER_DELIVERED]: 3,
    [ORDER_PROCESS_STATUS.FULFILLED]: 4,
  };

  // Dynamically create the steps array
  const steps =
    params?.status === ORDER_PROCESS_STATUS.ORDER_REJECTED
      ? ["Order Received", "Order Accepted", "Order Rejected"]
      : [
        "Order Received",
        "Order Accepted",
        "Order Shipped",
        "Delivered",
        "Fulfilled",
      ];

  // Determine the active step index based on our custom mapping
  const activeStep = stepMap[params?.status] ?? -1;

  return (
    <CustomDialog id={"OrderInformation"} title={"Order Details"}>
      <Box>
        <Paper
          elevation={0}
          sx={{ width: "100%", minHeight: "100%", padding: 3 }}
        >
          <Box
            p={2}
            sx={{
              border: "1px solid #1B4381",
              borderRadius: "5px",
              backgroundColor: "white",
            }}
          >
            <Box mb={2}>
              <Typography variant="h6" gutterBottom>
                Order No :- {params?.order_id}
              </Typography>
              <Typography variant="body1">
                Order Date :-{" "}
                {params?.date
                  ? moment(params?.date).format("DD/MM/YYYY")
                  : "NA"}
              </Typography>
            </Box>

            <CustomStepper
              alternativeLabel
              activeStep={activeStep}
              connector={<CustomConnector />}
            >
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <CustomStepIcon
                        {...props}
                        label={label}
                        completed={index <= activeStep}
                        index={index}
                        activeStep={activeStep}
                        isLastStep={index === steps.length - 1}
                      />
                    )}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </CustomStepper>
          </Box>

          <Box mt={4} sx={{ border: "1px solid #1B4381", borderRadius: "5px" }}>
            <Grid container sx={{ mt: 2, p: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body1">Box Id :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {params?.box_id ?? "NA"}
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ p: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body1">Customer Name :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {params?.customer_id?.first_name ?? "NA"}{" "}
                  {params?.customer_id?.last_name}
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ p: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body1">Product :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {params?.product?.product_name ?? "NA"}
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ p: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body1">Shipping Date :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {params?.shipping_date
                    ? moment(params?.shipping_date).format("DD/MM/YYYY")
                    : "NA"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ p: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body1">Order From :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {params?.from?.name ?? "NA"}
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ p: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body1">Courier Name :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {params?.courier_name ?? "NA"}
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ p: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body1">Tracking Id :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {params?.tracking_id ?? "NA"}
                </Typography>
              </Grid>
            </Grid>


            <Grid container sx={{ p: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body1">Tracking Link :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography sx={{ wordBreak: "break-word" }}>
                  {params?.tracking_link ? (
                    <a
                      href={params?.tracking_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#1B4381",
                        textDecoration: "none",
                        fontWeight: "bold",
                      }}
                    >
                      {params?.tracking_link}
                    </a>
                  ) : (
                    "NA"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </CustomDialog>
  );
};

export default OrderViewController;
