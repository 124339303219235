import React, { useState } from "react";
import {
  Box,
  Button,
  Grid2,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  CUSTOMER_TYPE,
  FEEDBACK_STATUS,
  GENDER,
  LEVEL_OF_HEARING_LOSS,
  ORDER_STATUS,
  PAYMENT_TYPE,
  PROOF_TYPE,
  TRIALS_STATUS,
  TYPE_OF_HEARING_LOSS,
  USER_ROLES,
} from "../../../utils/constants";
import { findObjectKeyByValue } from "../../../utils/main";
import moment from "moment";
import SubmitButton from "../../../components/button/SubmitButton";
import ChartComponent from "../../../components/ChartComponent";
import {
  formatNumberCustomPattern,
  titleCaseUp,
  titleCaseUpperCase,
} from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { Edit } from "@mui/icons-material";
import FileDownloadComponent from "../../../components/layouts/FileDownloadComponent";
import { openModal } from "../../../store/actions/modalAction";
import FeedBackViewUi from "../../Feedback/FeedbackView/FeedBackViewUi";

const CustomerInformation = ({
  params,
  trialFun = () => {},
  purchaseFun = () => {},
  onTrialReturn = () => {},
  onDownloadClick = () => {},
  onInitiateRefund = () => {},
  onFeedBackView = () => {},
  onEditAudiogram = () => {},
  downloadLoading,
}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [rightAcData, setRightAcData] = useState([
    params.rf1,
    params.rf2,
    params.rf3,
    params.rf4,
    params.rf5,
    params.rf6,
    params.rf7,
    params.rf8,
  ]);
  const [rightBcData, setRightBcData] = useState([
    params.brf1,
    params.brf2,
    params.brf3,
    params.brf4,
    params.brf5,
    params.brf6,
    params.brf7,
    params.brf8,
  ]);
  const [rightUclData, setRightUclData] = useState([
    params.urf1,
    params.urf2,
    params.urf3,
    params.urf4,
    params.urf5,
    params.urf6,
    params.urf7,
    params.urf8,
  ]);
  const [rightMclData, setRightMclData] = useState([
    params.mrf1,
    params.mrf2,
    params.mrf3,
    params.mrf4,
    params.mrf5,
    params.mrf6,
    params.mrf7,
    params.mrf8,
  ]);
  const [leftAcData, setLeftAcData] = useState([
    params.lf1,
    params.lf2,
    params.lf3,
    params.lf4,
    params.lf5,
    params.lf6,
    params.lf7,
    params.lf8,
  ]);
  const [leftUclData, setLeftUclData] = useState([
    params.ulf1,
    params.ulf2,
    params.ulf3,
    params.ulf4,
    params.ulf5,
    params.ulf6,
    params.ulf7,
    params.ulf8,
  ]);
  const [leftBcData, setLeftBcData] = useState([
    params.blf1,
    params.blf2,
    params.blf3,
    params.blf4,
    params.blf5,
    params.blf6,
    params.blf7,
    params.blf8,
  ]);
  const [leftMclData, setLeftMclData] = useState([
    params.mlf1,
    params.mlf2,
    params.mlf3,
    params.mlf4,
    params.mlf5,
    params.mlf6,
    params.mlf7,
    params.mlf8,
  ]);

  return (
    <Box>
      <Box>
        <Grid2 container spacing={2} size={12}>
          <Grid2
            size={6}
            sx={{ border: "1px solid #101536", borderRadius: "8px" }}
          >
            <Box
              p={2}
              sx={{
                height: "7vh",
                borderBottom: "1px solid #101536",
                backgroundColor: "#F6FAFF",
                borderRadius: "8px 8px 0 0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography fontWeight={800} variant="h3">
                Customer Details{" "}
              </Typography>
            </Box>
            <Grid2 container p={2} size={12}>
              <Grid2 item mt={2} size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Full Name :{" "}
                </Typography>
              </Grid2>

              <Grid2 item mt={2} size={3.5}>
                <Typography
                  variant="body1"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params.first_name ? params?.first_name : "NA"}{" "}
                  {params.middle_name ? params.middle_name : ""}{" "}
                  {params.last_name ? params.last_name : ""}
                </Typography>
              </Grid2>

              <Grid2 item mt={2} size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Gender :{" "}
                </Typography>
              </Grid2>

              <Grid2 item mt={2} size={3.5}>
                <Typography
                  variant="body1"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {findObjectKeyByValue(params.gender, GENDER)}
                </Typography>
              </Grid2>
            </Grid2>

            <Grid2 container p={2} size={12}>
              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Contact :
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography
                  variant="body1"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params.phone ? params?.phone : "NA"}
                </Typography>
              </Grid2>

              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  DOB :{" "}
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography
                  variant="body1"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {moment(params.dob).format("DD/MM/YYYY")}
                </Typography>
              </Grid2>
            </Grid2>

            <Grid2 container p={2} size={12}>
              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Email :{" "}
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                  {params.email ? params?.email : "NA"}
                </Typography>
              </Grid2>

              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Zip :{" "}
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography
                  variant="body1"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params.zip_code ? params.zip_code : "NA"}
                </Typography>
              </Grid2>
            </Grid2>

            <Grid2 container p={2} size={12}>
              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  State :{" "}
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography
                  variant="body1"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params.state ? params?.state : "NA"}
                </Typography>
              </Grid2>

              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  City :{" "}
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography
                  variant="body1"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params.city ? params.city : "NA"}
                </Typography>
              </Grid2>
            </Grid2>
            <Grid2 container p={2} size={12}>
              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Visit Date :{" "}
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography
                  variant="body1"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {moment(params?.createdAt).format("DD/MM/YYYY")}
                </Typography>
              </Grid2>

              {params?.lead?.by_lead && (
                <>
                  {" "}
                  <Grid2 item size={2.5}>
                    <Typography
                      fontWeight="bold"
                      variant="body1"
                      sx={{ color: "#808080" }}
                    >
                      Lead By :{" "}
                    </Typography>
                  </Grid2>
                  <Grid2 item size={3.5}>
                    <Typography
                      variant="body1"
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      {params?.lead?.from?.name
                        ? params?.lead?.from?.name
                        : "NA"}
                    </Typography>
                  </Grid2>
                </>
              )}
            </Grid2>

            <Grid2 container p={2} size={12}>
              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Address :
                </Typography>
              </Grid2>

              <Grid2 item size={9.5}>
                <Typography
                  variant="body1"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params.address ? params?.address : "NA"}
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>

          <Grid2
            size={6}
            sx={{ border: "1px solid #101536", borderRadius: "8px" }}
          >
            <Box
              p={2}
              sx={{
                height: "7vh",
                backgroundColor: "#F6FAFF",
                borderBottom: "1px solid #101536",
                borderRadius: "8px 8px 0 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontWeight={800} variant="h4">
                  Audiogram Details{" "}
                </Typography>
                <IconButton onClick={onEditAudiogram}>
                  <Edit color="primary" />
                </IconButton>
              </Box>

              <SubmitButton
                onClick={onDownloadClick}
                loading={downloadLoading}
                sx={{ width: "120px" }}
                title={<Typography variant="h5">Download</Typography>}
              />
            </Box>

            <Grid2
              p={4}
              gap={2}
              container
              size={12}
              display={"flex"}
              justifyContent={"space-evenly"}
            >
              <Grid2 size={5}>
                <Box
                  sx={{
                    backgroundColor: "#FFF6F6",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "10vh",
                    maxHeight: "25vh",
                    minWidth: "10vw",
                    maxWidth: "15vw",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      // width: "fit-content", // Matches the content width
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography color="error" variant="body1" ml={4}>
                      • Right
                    </Typography>
                  </Box>

                  <ChartComponent
                    allowed_ac={params?.allowed_ac}
                    allowed_bc={params?.allowed_bc}
                    allowed_ucl={params?.allowed_ucl}
                    allowed_mcl={params?.allowed_mcl}
                    data={rightAcData}
                    title="AC"
                    boneData={rightBcData}
                    boneTitle="BC"
                    UclData={rightUclData}
                    UclTitle="UCL"
                    MclData={rightMclData}
                    MclTitle="MCL"
                    rightEar
                    readonly={true}
                  />
                </Box>
              </Grid2>

              <Grid2 size={5}>
                <Box
                  sx={{
                    backgroundColor: "#F6FAFF",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "10vh",
                    maxHeight: "25vh",
                    minWidth: "10vw",
                    maxWidth: "15vw",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography color="primary" variant="body1" ml={4}>
                      • Left
                    </Typography>
                  </Box>
                  <ChartComponent
                    allowed_ac={params?.allowed_ac}
                    allowed_bc={params?.allowed_bc}
                    allowed_ucl={params?.allowed_ucl}
                    allowed_mcl={params?.allowed_mcl}
                    data={leftAcData}
                    title="AC"
                    boneData={leftBcData}
                    boneTitle="BC"
                    UclData={leftUclData}
                    UclTitle="UCL"
                    MclData={leftMclData}
                    MclTitle="MCL"
                    readonly={true}
                  />
                </Box>
              </Grid2>

              <Grid2 container size={12} mt={2}>
                <Grid2 item size={isSmallScreen ? 3 : 3}>
                  <Typography fontWeight="bold" variant="body1" color="#808080">
                    Type Of Loss :
                  </Typography>
                </Grid2>
                <Grid2 item size={isSmallScreen ? 3 : 3}>
                  <Typography>
                    {findObjectKeyByValue(
                      params?.type_of_hearing_loss,
                      TYPE_OF_HEARING_LOSS
                    )}
                  </Typography>
                </Grid2>
                <Grid2 item size={isSmallScreen ? 3 : 3}>
                  <Typography fontWeight="bold" variant="body1" color="#808080">
                    Level Of Loss :
                  </Typography>
                </Grid2>
                <Grid2 item size={isSmallScreen ? 3 : 3}>
                  <Typography>
                    {findObjectKeyByValue(
                      params?.level_of_hearing_loss,
                      LEVEL_OF_HEARING_LOSS
                    )}
                  </Typography>
                </Grid2>
              </Grid2>
              <Grid2 container size={12}>
                <Box>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    color="#808080"
                    // mt={1}
                  >
                    Suggested Products :{" "}
                  </Typography>
                </Box>

                {params?.suggested_product?.length ? (
                  <Box
                    ml={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {params?.suggested_product?.map((item, index) => (
                      <Box
                        p={1}
                        sx={{
                          borderRadius: "5px",
                        }}
                      >
                        <Typography>
                          {item?.product_name}{" "}
                          {params?.suggested_product.length != index + 1
                            ? ", "
                            : ""}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box
                    ml={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#808080",
                    }}
                  >
                    <Box
                      // p={4}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        textAlign={"center"}
                        variant="h6"
                        color="primary"
                      >
                        No Product.
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Box>

      <Grid2 mt={2} container spacing={2} size={12}>
        <Grid2
          sx={{ border: "1px solid #101536", borderRadius: "8px" }}
          size={6}
          minHeight={"30vh"}
        >
          <Grid2>
            <Box
              p={2}
              sx={{
                height: "7vh",
                borderBottom: "1px solid #101536",
                backgroundColor: "#F6FAFF",
                borderRadius: "8px 8px 0 0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography fontWeight={800} variant="h4">
                Trial Product Details
              </Typography>
              {params?.trial_details?.trial_status == TRIALS_STATUS.ACTIVE &&
                params.trial_details.status == CUSTOMER_TYPE.TRIALS && (
                  <SubmitButton
                    disabled={params.primary_parent_id != user.data._id}
                    onClick={onTrialReturn}
                    sx={{ width: "150px" }}
                    title={<Typography variant="h5">Take Return</Typography>}
                  />
                )}
              {params?.trial_details?.trial_status == TRIALS_STATUS.RETURN &&
                !params.trial_details.refund_id &&
                !(params.sold_details.status == CUSTOMER_TYPE.PURCHASED) && (
                  <SubmitButton
                    disabled={params.primary_parent_id != user.data._id}
                    onClick={onInitiateRefund}
                    sx={{ width: "190px" }}
                    title={
                      <Typography variant="h5">Initiate Refund</Typography>
                    }
                  />
                )}
            </Box>
          </Grid2>

          {params?.trial_details?.status == CUSTOMER_TYPE.TRIALS ||
          params?.trial_details?.status == CUSTOMER_TYPE.RETURN ? (
            <Box p={4}>
              <Grid2 container size={12}>
                <Grid2 item size={4}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    sx={{
                      color: "#808080",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Device :{" "}
                  </Typography>
                </Grid2>
                <Grid2 item size={4}>
                  <Typography
                    color="primary"
                    variant="body1"
                    sx={{
                      borderRadius: "5px",
                    }}
                  >
                    {params?.trial_details?.product?.product_name}
                  </Typography>
                </Grid2>
              </Grid2>

              <Grid2 container size={12}>
                <Grid2 item size={4}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    sx={{
                      color: "#808080",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Status :{" "}
                  </Typography>
                </Grid2>
                <Grid2 item size={4}>
                  <Typography
                    color="primary"
                    variant="body1"
                    sx={{
                      borderRadius: "5px",
                    }}
                  >
                    {findObjectKeyByValue(
                      params?.trial_details?.trial_status,
                      TRIALS_STATUS
                    ) ?? "NA"}
                  </Typography>
                </Grid2>
              </Grid2>

              <Grid2 mt={1} container size={12}>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    sx={{
                      color: "#808080",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Box Id :{" "}
                  </Typography>
                </Grid2>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {params?.trial_details?.box_id
                      ? params?.trial_details?.box_id
                      : "NA"}
                  </Typography>
                </Grid2>
              </Grid2>

              <Grid2 mt={1} container size={12}>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    sx={{
                      color: "#808080",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    {params?.customer_type == CUSTOMER_TYPE.PURCHASED
                      ? "Purchase Date :"
                      : params?.customer_type == CUSTOMER_TYPE.TRIALS
                      ? "Trial Date :"
                      : "Return Date :"}
                  </Typography>
                </Grid2>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {moment(params?.trial_details?.date).format("DD/MM/YYYY")}
                  </Typography>
                </Grid2>
              </Grid2>

              <Grid2 mt={1} container size={12}>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    sx={{
                      color: "#808080",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Amount :{" "}
                  </Typography>
                </Grid2>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {formatNumberCustomPattern(
                      params?.trial_details?.amount
                        ? params?.trial_details?.amount
                        : "NA"
                    )}
                  </Typography>
                </Grid2>
              </Grid2>

              <Grid2 mt={1} container size={12}>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    sx={{
                      color: "#808080",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Payment Mode :{" "}
                  </Typography>
                </Grid2>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {params?.payment_details?.filter(
                      (item) => item?.customer_type == CUSTOMER_TYPE.TRIALS
                    )[0]?.payment_type
                      ? titleCaseUpperCase(
                          findObjectKeyByValue(
                            params?.payment_details?.filter(
                              (item) =>
                                item?.customer_type == CUSTOMER_TYPE.TRIALS
                            )[0]?.payment_type,
                            PAYMENT_TYPE
                          )
                        )
                      : "NA"}
                  </Typography>
                </Grid2>
              </Grid2>

              <Grid2
                mt={1}
                container
                size={12}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    sx={{
                      color: "#808080",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    ID Proof :{" "}
                  </Typography>
                </Grid2>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    variant="body1"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {params?.trial_details?.id_proof_type ? (
                      <>
                        {findObjectKeyByValue(
                          params?.trial_details?.id_proof_type,
                          PROOF_TYPE
                        )}
                        <FileDownloadComponent
                          color="primary"
                          src={params?.trial_details?.id_proof ?? "NA"}
                        />
                      </>
                    ) : (
                      "NA"
                    )}
                  </Typography>
                </Grid2>
              </Grid2>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "25vh",
              }}
            >
              <SubmitButton
                disabled={
                  params?.sold_details?.status == CUSTOMER_TYPE.PURCHASED ||
                  user.data.role == USER_ROLES.CRM ||
                  params.primary_parent_id != user.data._id
                }
                onClick={trialFun}
                sx={{ width: "150px" }}
                title={`Give Trial`}
              />
            </Box>
          )}
        </Grid2>
        <Grid2
          sx={{ border: "1px solid #101536", borderRadius: "8px" }}
          size={6}
          minHeight={"30vh"}
        >
          <Grid2>
            <Box
              p={2}
              sx={{
                height: "7vh",
                borderBottom: "1px solid #101536",
                backgroundColor: "#F6FAFF",
                borderRadius: "8px 8px 0 0",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography fontWeight={800} variant="h4">
                Purchased Product Details
              </Typography>
            </Box>
          </Grid2>

          {params?.sold_details?.status == CUSTOMER_TYPE.PURCHASED ? (
            <Box p={4}>
              <Grid2 container size={12}>
                <Grid2 item size={4}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    sx={{
                      color: "#808080",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Device :{" "}
                  </Typography>
                </Grid2>
                <Grid2 item size={4}>
                  <Typography
                    color="primary"
                    variant="body1"
                    sx={{
                      borderRadius: "5px",
                    }}
                  >
                    {params?.sold_details?.product?.product_name}
                  </Typography>
                </Grid2>
              </Grid2>

              <Grid2 container size={12}>
                <Grid2 item size={4}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    sx={{
                      color: "#808080",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Status :{" "}
                  </Typography>
                </Grid2>
                <Grid2 item size={4}>
                  <Typography
                    color="primary"
                    variant="body1"
                    sx={{
                      borderRadius: "5px",
                    }}
                  >
                    {findObjectKeyByValue(
                      params?.customer_type,
                      CUSTOMER_TYPE
                    ) === "Return"
                      ? "Demo Device Returned"
                      : findObjectKeyByValue(
                          params?.customer_type,
                          CUSTOMER_TYPE
                        ) ?? "NA"}
                  </Typography>
                </Grid2>
              </Grid2>

              <Grid2 mt={1} container size={12}>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    sx={{
                      color: "#808080",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Box Id :{" "}
                  </Typography>
                </Grid2>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {params?.sold_details?.box_id
                      ? params?.sold_details?.box_id
                      : "NA"}
                  </Typography>
                </Grid2>
              </Grid2>

              <Grid2 mt={1} container size={12}>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    sx={{
                      color: "#808080",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    {params?.customer_type == CUSTOMER_TYPE.PURCHASED
                      ? "Purchase Date :"
                      : params?.customer_type == CUSTOMER_TYPE.TRIALS
                      ? "Trial Date :"
                      : "Return Date :"}
                  </Typography>
                </Grid2>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {moment(params?.sold_details?.date).format("DD/MM/YYYY")}
                  </Typography>
                </Grid2>
              </Grid2>

              <Grid2 mt={1} container size={12}>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    sx={{
                      color: "#808080",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Amount :{" "}
                  </Typography>
                </Grid2>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {formatNumberCustomPattern(
                      params?.sold_details?.amount +
                        params?.trial_details?.amount ?? "NA"
                    )}
                  </Typography>
                </Grid2>
              </Grid2>
              <Grid2 mt={1} mb={2} container size={12}>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    fontWeight="bold"
                    variant="body1"
                    sx={{
                      color: "#808080",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Payment Mode :{" "}
                  </Typography>
                </Grid2>
                {/* <Grid2 item size={2}></Grid2> */}
                <Grid2 item size={4}>
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {params?.payment_details?.filter(
                      (item) => item?.customer_type == CUSTOMER_TYPE.PURCHASED
                    )[0]?.payment_type
                      ? titleCaseUpperCase(
                          findObjectKeyByValue(
                            params?.payment_details?.filter(
                              (item) =>
                                item?.customer_type == CUSTOMER_TYPE.PURCHASED
                            )[0]?.payment_type,
                            PAYMENT_TYPE
                          ).toUpperCase()
                        )
                      : "NA"}
                  </Typography>
                </Grid2>
              </Grid2>

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {params.sold_details?.order_status ==
                  ORDER_STATUS.FULFILLED && (
                  <Grid2 container size={12}>
                    <Grid2 item size={4}>
                      <Typography
                        fontWeight="bold"
                        variant="body1"
                        sx={{
                          color: "#808080",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        Fulfill Date :{" "}
                      </Typography>
                    </Grid2>
                    <Grid2 item size={4}>
                      <Typography
                        color="primary"
                        variant="body1"
                        sx={{
                          borderRadius: "5px",
                        }}
                      >
                        {moment(params?.sold_details?.fulfill_date).format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </Grid2>
                  </Grid2>
                )}

                <Grid2 container size={12}>
                  <Grid2 item size={4}>
                    <Typography
                      fontWeight="bold"
                      variant="body1"
                      sx={{
                        color: "#808080",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      Order Status :{" "}
                    </Typography>
                  </Grid2>
                  <Grid2 item size={4}>
                    <Typography
                      color="primary"
                      variant="body1"
                      sx={{
                        borderRadius: "5px",
                      }}
                    >
                      {findObjectKeyByValue(
                        params?.sold_details?.order_status,
                        ORDER_STATUS
                      ) ?? "NA"}
                    </Typography>
                  </Grid2>
                </Grid2>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "25vh",
              }}
            >
              <SubmitButton
                disabled={
                  user.data.role == USER_ROLES.CRM ||
                  params.primary_parent_id != user.data._id
                }
                onClick={purchaseFun}
                sx={{ width: "120px" }}
                title={<Typography variant="h5">Purchase</Typography>}
              />
            </Box>
          )}
        </Grid2>
      </Grid2>

      <Grid2 mt={2} container size={12} spacing={2}>
        <Grid2
          sx={{
            border: "1px solid #101536",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          size={4}
          minHeight={"10vh"}
        >
          {" "}
          <Button
            disabled={
              !(
                params?.sold_details?.fd1?.feedback_status ==
                FEEDBACK_STATUS.COMPLETED
              )
            }
            onClick={() => {
              onFeedBackView(params?.sold_details?.fd1, true, false, false);
            }}
            sx={{ width: "100%", height: "100%" }}
          >
            FeedBack 1
          </Button>
        </Grid2>
        <Grid2
          sx={{
            border: "1px solid #101536",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          size={4}
          minHeight={"10vh"}
        >
          {" "}
          <Button
            disabled={
              !(
                params?.sold_details?.fd2?.feedback_status ==
                FEEDBACK_STATUS.COMPLETED
              )
            }
            onClick={() => {
              onFeedBackView(params?.sold_details?.fd2, false, true, false);
            }}
            sx={{ width: "100%", height: "100%" }}
          >
            FeedBack 2
          </Button>
        </Grid2>
        <Grid2
          sx={{
            border: "1px solid #101536",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          size={4}
          minHeight={"10vh"}
        >
          {" "}
          <Button
            onClick={() => {
              onFeedBackView(params?.sold_details?.fd3, false, false, true);
            }}
            disabled={
              !(
                params?.sold_details?.fd3?.feedback_status ==
                FEEDBACK_STATUS.COMPLETED
              )
            }
            sx={{ width: "100%", height: "100%" }}
          >
            FeedBack 3
          </Button>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default CustomerInformation;
