import React, { useEffect } from 'react'
import FileInput from '../../components/upload/FileInput';
import CustomInput from '../../components/inputs/CustomInputs';
import { Box, CircularProgress, Grid, Stack } from '@mui/material';
import CustomDialog from '../../components/layouts/common/CustomDialog';
import { CenteredBox } from '../../components/layouts/common/boxes';

const UpdateUserUi = ({ setFields, fields, loading, onSubmit, setLoading }) => {

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLoading(false)
        }, 100);
        return () => clearTimeout(timeoutId);

    }, [loading])
    return (
        <CustomDialog
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={"Personal Details"}
            closeText="Close"
            confirmText={"Okay"}
            id={"UpdateUser"}
        >
            {loading ? (
                <CenteredBox>
                    <CircularProgress />
                </CenteredBox>
            ) : (
                <>
                    <Stack spacing={2} sx={{ mb: 2 }}>
                        <Grid container rowGap={3} columnSpacing={3}>
                            <Grid xs={12} md={6} item>
                                <Box>
                                    <CustomInput
                                        disabled={loading}
                                        label="Bank Name*"
                                        type="text"
                                        name="bank_name"
                                        value={fields?.bank_details?.bank_name}
                                        onChange={(e) =>
                                            setFields({
                                                ...fields,
                                                err: "",
                                                bank_details: { ...fields.bank_details, bank_name: e.target.value },
                                            })
                                        }
                                        sx={{ backgroundColor: "background.paper" }}
                                    />
                                </Box>
                            </Grid>

                            <Grid xs={12} md={6} item>
                                <Box>
                                    <CustomInput
                                        disabled={loading}
                                        name="ifsc_code"
                                        value={fields?.bank_details?.ifsc_code}
                                        onChange={(e) =>
                                            setFields({
                                                ...fields,
                                                err: "",
                                                bank_details: { ...fields.bank_details, ifsc_code: e.target.value },
                                            })
                                        }
                                        type="text"
                                        label={"IFSC Code No.*"}
                                    />
                                </Box>
                            </Grid>

                            <Grid xs={12} md={6} item>
                                <Box>
                                    <CustomInput
                                        disabled={loading}
                                        name="account_no"
                                        value={fields.bank_details.account_no}
                                        onChange={(e) =>
                                            setFields({
                                                ...fields,
                                                err: "",
                                                bank_details: { ...fields.bank_details, account_no: e.target.value },
                                            })
                                        }
                                        type="number"
                                        label={"Acccount No.*"}
                                    />
                                </Box>
                            </Grid>

                            <Grid xs={12} md={6} item>
                                <Box>
                                    <FileInput
                                        onChange={(files) => {
                                            setFields({
                                                ...fields,
                                                err: "",
                                                bank_details: { ...fields.bank_details, cancel_check: files },
                                            })
                                        }}
                                        defaults={fields?.bank_details?.cancel_check ? [fields?.bank_details?.cancel_check] : []}
                                        accept="image/*,.pdf"
                                        title="Upload cancel-check/pass-book/bank-statement"
                                        subTitle="Only png,jpeg,jpg,pdf files are allowed! less than 1 MB"
                                    />
                                </Box>
                            </Grid>

                            <Grid xs={12} md={6} item>
                                <Box>
                                    <CustomInput
                                        disabled={loading}
                                        name="pan_no"
                                        value={fields.pan_no}
                                        onChange={(e) =>
                                            setFields({
                                                ...fields,
                                                err: "",
                                                pan_no: e.target.value,
                                            })
                                        }
                                        type="text"
                                        label={"Pan No.*"}
                                    />
                                </Box>
                            </Grid>

                            <Grid xs={12} md={6} item>
                                <Box>
                                    <FileInput
                                        onChange={(files) => {
                                            setFields({
                                                ...fields,
                                                err: "",
                                                pan_url: files,
                                            })
                                        }}
                                        defaults={
                                            fields.pan_url ? [fields.pan_url] : []
                                        }
                                        accept="image/*,.pdf"
                                        title="Upload Pan No photo"
                                        subTitle="Only png,jpeg,jpg,pdf files are allowed! less than 1 MB"
                                    />
                                </Box>
                            </Grid>

                            <Grid xs={12} md={6} item>
                                <Box>
                                    <CustomInput
                                        disabled={loading}
                                        name="gst_no"
                                        value={fields.gst_no}
                                        onChange={(e) =>
                                            setFields({
                                                ...fields,
                                                err: "",
                                                gst_no: e.target.value,
                                            })
                                        }
                                        type="text"
                                        label={"GST No.*"}
                                    />
                                </Box>
                            </Grid>

                            <Grid xs={12} md={6} item>
                                <Box>
                                    <FileInput
                                        onChange={(files) => {
                                            setFields({
                                                ...fields,
                                                err: "",
                                                certificate: files,
                                            })
                                        }}
                                        defaults={
                                            fields?.certificate ? [fields?.certificate] : []
                                        }
                                        accept="image/*,.pdf"
                                        title="Upload Gst No Certificate"
                                        subTitle="Only png,jpeg,jpg,pdf files are allowed! less than 1 MB"
                                    />
                                </Box>
                            </Grid>

                        </Grid>
                    </Stack>
                </>)}
        </CustomDialog>)
}

export default UpdateUserUi