import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/main";



export const getScpOrFpDataApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.scpOrFpDataApi,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};
export const getFpDataApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.fpDataApi,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};

export const productDashboardDataApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.productDashboardDataApi,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};

export const dashboardCountDataApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.dashboardCountData,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};

