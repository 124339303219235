import { useEffect, useState, useMemo, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import moment from "moment";
import { FEEDBACK_STATUS } from "../../utils/constants";
import { callApiAction } from "../../store/actions/commonAction";
import { openModal } from "../../store/actions/modalAction";
import { Add } from "@mui/icons-material";
import CustomerInformationController from "../customer/CustomerViews/CustomerInformationController";
import {
  getFeedBackCountApi,
  getFeedBackDataApi,
} from "../../apis/feedback.api";
import FeedbackListUi from "./FeedbackListUi";
import FeedbackUpdateController from "./FeedbackUpdate/FeedbackUpdateController";

const ActionComponent = ({ callback = () => { }, params, setParams }) => {
  const dispatch = useDispatch();

  const isDisabled =
    params?.sold_details?.fd3?.feedback_status == FEEDBACK_STATUS.COMPLETED;

  const updateFeedbackFun = () => {
    dispatch(
      openModal(
        <FeedbackUpdateController params={params} callBack={callback} />,
        "lg",
        true,
        "updateFeedback"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "center", display: "flex" }}>
      <Tooltip
        title={
          params?.sold_details?.fd3?.feedback_status ==
            FEEDBACK_STATUS.COMPLETED
            ? "Completed"
            : isDisabled
              ? "Feedback not yet due"
              : "Add Feedback"
        }
        arrow
      >
        <span>
          <IconButton
            disabled={isDisabled}
            size="small"
            onClick={updateFeedbackFun}
          >
            <Add color={isDisabled ? "gray" : "info"} size="large" />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

const FeedbackListController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});
  const [selectOrderType, setSelectOrderType] = useState(0);
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    searchable: ["first_name", "last_name", "product_name", "box_id"],
    search: "",
    role: "",
    sort: "createdAt",
    sortDirection: -1,
    startDate: "",
    endDate: "",
  });

  const onVeiw = (params) => {
    dispatch(
      openModal(
        <CustomerInformationController id={params._id} />,
        "xl",
        true,
        "CustomerInformation"
      )
    );
  };

  const columns = useMemo(() => {
    return [
      {
        id: 3,
        fieldName: "purchasedDate",
        label: "Purchase Date",
        align: "left",
        renderValue: (params) =>
          moment(params?.sold_details?.fulfill_date).format("DD/MM/YYYY"),
      },
      {
        id: 1,
        fieldName: "customer",
        label: "Customer",
        align: "left",
        sort: true,
        renderValue: (params) => (
          <Typography
            variant="body2"
            onClick={() => {
              onVeiw(params);
            }}
            sx={{ cursor: "pointer" }}
          >
            {params?.first_name + " " + params?.last_name}
          </Typography>
        ),
      },
      {
        id: 2,
        fieldName: "product",
        label: "Product",
        align: "left",
        sort: true,
        renderValue: (params) => params?.sold_details?.product?.product_name,
      },

      {
        id: 5,
        fieldName: "presheduleDate",
        label: "Previous FB Date",
        align: "left",
        renderValue: (params) => {
          if (
            params?.sold_details?.fd3?.feedback_status ==
            FEEDBACK_STATUS.COMPLETED
          ) {
            return moment(params?.sold_details?.fd3?.completed_date).format(
              "DD/MM/YYYY"
            );
          } else if (
            params?.sold_details?.fd2?.feedback_status ==
            FEEDBACK_STATUS.COMPLETED
          ) {
            return moment(params?.sold_details?.fd2?.completed_date).format(
              "DD/MM/YYYY"
            );
          } else if (
            params?.sold_details?.fd1?.feedback_status ==
            FEEDBACK_STATUS.COMPLETED
          ) {
            return moment(params?.sold_details?.fd1?.completed_date).format(
              "DD/MM/YYYY"
            );
          } else {
            return "NA";
          }
        },
      },
      {
        id: 4,
        fieldName: "feedback_level",
        label: "FeedBack Level",
        align: "left",
        renderValue: (params) => {
          if (
            params?.sold_details?.fd1?.feedback_status ==
            FEEDBACK_STATUS.PENDING
          ) {
            return "No Feedback";
          } else if (
            params?.sold_details?.fd2?.feedback_status ==
            FEEDBACK_STATUS.PENDING
          ) {
            return "Feedback 1 ";
          } else if (
            params?.sold_details?.fd3?.feedback_status ==
            FEEDBACK_STATUS.PENDING
          ) {
            return "Feedback 2 ";
          } else if (
            params?.sold_details?.fd1 &&
            params?.sold_details?.fd2 &&
            params?.sold_details?.fd3
          ) {
            return "Completed";
          } else {
            return "Feedback 1";
          }
        },
      },
      {
        id: 6,
        fieldName: "nextsheduleDate",
        label: "Next FB Date",
        align: "left",
        renderValue: (params) => {
          if (
            params?.sold_details?.fd1?.feedback_status ==
            FEEDBACK_STATUS.PENDING
          ) {
            return moment(params?.sold_details?.fd1?.scheduled_date).format(
              "DD/MM/YYYY"
            );
          } else if (
            params?.sold_details?.fd2?.feedback_status ==
            FEEDBACK_STATUS.PENDING
          ) {
            return moment(params?.sold_details?.fd2?.scheduled_date).format(
              "DD/MM/YYYY"
            );
          } else if (
            params?.sold_details?.fd3?.feedback_status ==
            FEEDBACK_STATUS.PENDING
          ) {
            return moment(params?.sold_details?.fd3?.scheduled_date).format(
              "DD/MM/YYYY"
            );
          } else {
            return "Completed";
          }
        },
      },
      {
        id: 7,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            params={params}
            setParams={setParams}
            callback={() => {
              fetchFeedbackCount();
              fetchFeedbackList();
            }}
          />
        ),
      },
    ];
  }, [dispatch]);

  const fetchFeedbackList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await getFeedBackDataApi({
            ...filters,
            feedback_type: selectOrderType,
          }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (selectOrderType != null) {
      fetchFeedbackList();
    }
  }, [filters, selectOrderType]);
  const [countLoading, setCountLoading] = useState(false);
  const [count, setCount] = useState();

  const fetchFeedbackCount = () => {
    setCountLoading(true);
    dispatch(
      callApiAction(
        async () => await getFeedBackCountApi({ ...filters }),
        (response) => {
          setCount(response);
          setCountLoading(false);
        },
        (err) => {
          setCountLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchFeedbackCount();
  }, [filters.startDate, filters.endDate]);

  // useEffect(() => {
  //   if (count?.length > 0) {
  //     fetchFeedbackList();
  //   }
  // }, [count]);

  return (
    <FeedbackListUi
      columns={columns}
      list={list}
      filters={filters}
      loading={loading}
      setFilters={setFilters}
      setSelectOrderType={setSelectOrderType}
      selectOrderType={selectOrderType}
      setCountLoading={setCountLoading}
      countLoading={countLoading}
      count={count}
    />
  );
};
export default memo(FeedbackListController);
