import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import { USER_ROLES } from "../../../utils/constants";
import { findObjectKeyByValue } from "../../../utils/main";

const ScpListUi = ({ dataList, role, loading }) => {
  const headers = [
    "Sold Unit",
    "Sales Value",
    "Incentive",
    "Customers",
    "Trials",
    "Demo Units",
    "Sold Unit",
    "Sales Value",
  ];

  return (
    <Box p={4}>
      <Typography variant="h4" align="left" color="#1B4381" gutterBottom>
        {findObjectKeyByValue(role, USER_ROLES)} &nbsp;wise Analysis
      </Typography>
      <TableContainer
        sx={{
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
          borderBottomRightRadius: dataList.length > 0 && "5px",
          borderBottomLeftRadius: dataList.length > 0 && "5px",
          maxHeight: 300,
          overflowY: "auto",
          border: dataList.length > 0 && "1px solid rgb(129, 166, 222)",
          "&::-webkit-scrollbar": {
            width: 4,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#1B4381",
            borderRadius: 2,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#1B4381" }}>
              <TableCell
                align="left"
                sx={{
                  backgroundColor: "#1B4381",
                  color: "#fff",
                  fontWeight: "bold",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                {role == USER_ROLES.SALES_CHANNEL_PARTNER
                  ? findObjectKeyByValue(
                      USER_ROLES.SALES_CHANNEL_PARTNER,
                      USER_ROLES
                    )
                  : findObjectKeyByValue(
                      USER_ROLES.FRANCHISE_PARTNER,
                      USER_ROLES
                    )}
              </TableCell>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  align="center"
                  sx={{
                    backgroundColor: "#1B4381",
                    color: "#fff",
                    fontWeight: "bold",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <Box>
                    <Typography variant="h6" fontWeight={"bold"}>
                      {(index == 6 || index == 7) && "FP"}
                    </Typography>

                    <Typography variant="body" fontWeight={"bold"}>
                      {header}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              dataList.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontWeight: "bold" }} align="left">
                    {row?.name ?? "NA"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.customer?.direct_sold_units ?? 0}
                  </TableCell>
                  <TableCell align="center">
                    {row?.customer?.direct_value_of ?? 0}
                  </TableCell>
                  <TableCell align="center">
                    {row?.customer?.total_incentive ?? 0}
                  </TableCell>
                  <TableCell align="center">
                    {row?.customer?.totalCustomers ?? 0}
                  </TableCell>
                  <TableCell align="center">
                    {row?.customer?.trial_units ?? 0}
                  </TableCell>
                  <TableCell align="center">
                    {row?.demounits?.demo_units ?? 0}
                  </TableCell>
                  <TableCell align="center">
                    {row?.customer?.fp_sold_units ?? 0}
                  </TableCell>
                  <TableCell align="center">
                    {row?.customer?.fp_value_of ?? 0}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && dataList.length < 1 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-1px",
            width: "100%",
            height: "10vh",
            border: "1px solid rgb(129, 166, 222)",
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
          }}
        >
          <Typography color="rgb(32, 65, 115)" fontWeight={"bold"}>
            {`No ${findObjectKeyByValue(role, USER_ROLES)} Found.`}
          </Typography>
        </Box>
      )}
      {loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-1px",
            width: "100%",
            height: "10vh",
            border: "1px solid rgb(129, 166, 222)",
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
          }}
        >
          <Typography color="rgb(32, 65, 115)" fontWeight={"bold"}>
            Loading...
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ScpListUi;
