import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { callApiAction } from "../../store/actions/commonAction";
import { USER_ROLES } from "../../utils/constants";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeModal, openModal } from "../../store/actions/modalAction";
import MessageDilog from "../../components/MessageDilog";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { Check } from "@mui/icons-material";
import ScpOrFranchiseList from "../../components/ScpOrFranchiseList";
import { useDispatch, useSelector } from "react-redux";
import IncentiveListController from "./IncentiveListController";
import { FetchChildRolesApi } from "../../apis/incentive.api";
const IncentiveMain = () => {
  const [list, setList] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const FetchChildRoles = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await FetchChildRolesApi(),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  useEffect(() => {
    FetchChildRoles();
  }, []);
  const { user } = useSelector((state) => state);
  const [step, setStep] = useState(
    user.data.role === USER_ROLES.WEHEAR_ADMIN ||
      user.data.role === USER_ROLES.SUPER_ADMIN
      ? 1
      : 6
  );

  const [mystep, setMyStep] = useState(6);

  return (
    <Box mb={4} display="flex" flexDirection="column" width="100%">
      <ButtonGroup
        sx={{
          width: "100%",
          flexWrap: "wrap",
          color: "#101536",
        }}
      >
        {!(
          user.data.role == USER_ROLES.WEHEAR_ADMIN ||
          user.data.role == USER_ROLES.SUPER_ADMIN ||
          user.data.role == USER_ROLES.CRM ||
          user.data.role == USER_ROLES.SALES_STAFF
        ) && (
            <Button
              sx={step !== mystep ? { backgroundColor: "white", flex: 1, minWidth: "100px" } : { flex: 1, minWidth: "100px" }}

              variant={step == mystep ? "contained" : "outlined"}
              onClick={() => setStep(6)}
            >
              <Typography variant="body1" color={step === mystep && "white"}>
                My Incentive
              </Typography>
            </Button>
          )}
        {(user.data.role === USER_ROLES.WEHEAR_ADMIN ||
          user.data.role === USER_ROLES.SUPER_ADMIN) && (
            <Button
              sx={step !== 1 ? { backgroundColor: "white", flex: 1, minWidth: "100px" } : { flex: 1, minWidth: "100px" }}
              variant={step == 1 ? "contained" : "outlined"}
              onClick={() => setStep(1)}
              disabled={
                user.data.role === USER_ROLES.WEHEAR_ADMIN ||
                  user.data.role === USER_ROLES.SUPER_ADMIN
                  ? false
                  : !list?.SCP
              }
            >
              <Typography variant="body1" color={step === 1 && "white"}>
                SCP
              </Typography>
            </Button>
          )}
        {(user.data.role === USER_ROLES.WEHEAR_ADMIN ||
          user.data.role === USER_ROLES.SUPER_ADMIN ||
          user.data.role === USER_ROLES.SALES_CHANNEL_PARTNER) && (
            <Button
              sx={step !== 2 ? { backgroundColor: "white", flex: 1, minWidth: "100px" } : { flex: 1, minWidth: "100px" }}
              onClick={() => setStep(2)}
              variant={step == 2 ? "contained" : "outlined"}
              disabled={
                user.data.role === USER_ROLES.WEHEAR_ADMIN ||
                  user.data.role === USER_ROLES.SUPER_ADMIN
                  ? false
                  : !list?.FP
              }
            >
              <Typography variant="body1" color={step === 2 && "white"}>
                FP
              </Typography>
            </Button>
          )}
        {(user.data.role === USER_ROLES.WEHEAR_ADMIN ||
          user.data.role === USER_ROLES.SUPER_ADMIN ||
          user.data.role === USER_ROLES.SALES_CHANNEL_PARTNER ||
          user.data.role === USER_ROLES.FRANCHISE_PARTNER) && (
            <Button
              sx={step !== 3 ? { backgroundColor: "white", flex: 1, minWidth: "100px" } : { flex: 1, minWidth: "100px" }}
              onClick={() => setStep(3)}
              disabled={
                user.data.role === USER_ROLES.WEHEAR_ADMIN ||
                  user.data.role === USER_ROLES.SUPER_ADMIN
                  ? false
                  : !list?.SS
              }
              variant={step == 3 ? "contained" : "outlined"}
            >
              <Typography variant="body1" color={step === 3 && "white"}>
                Sales Staff
              </Typography>
            </Button>
          )}
        {(user.data.role === USER_ROLES.WEHEAR_ADMIN ||
          user.data.role === USER_ROLES.SUPER_ADMIN) && (
            <Button
              sx={step !== 4 ? { backgroundColor: "white", flex: 1, minWidth: "100px" } : { flex: 1, minWidth: "100px" }}
              onClick={() => setStep(4)}
              disabled={
                user.data.role === USER_ROLES.WEHEAR_ADMIN ||
                  user.data.role === USER_ROLES.SUPER_ADMIN
                  ? false
                  : !list?.CRM
              }
              variant={step == 4 ? "contained" : "outlined"}
            >
              <Typography variant="body1" color={step === 4 && "white"}>
                CRM
              </Typography>
            </Button>
          )}
      </ButtonGroup>

      {step === mystep ? (
        <IncentiveListController userId={user.data._id} role={user.data.role} />
      ) : (
        <ScpOrFranchiseList
          key={step}
          Component={(props) => <IncentiveListController {...props} />}
          step={step}
        />
      )}
    </Box>
  );
};

export default IncentiveMain;
