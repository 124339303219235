import React, { useState } from "react";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { closeModal } from "../../../store/actions/modalAction";
import { useDispatch } from "react-redux";
import {
  FEEDBACK_STATUS,
  ORDER_PROCESS_STATUS,
} from "../../../utils/constants";
import { callApiAction } from "../../../store/actions/commonAction";
import FeedbackUpdateUi from "./FeedbackUpdateUi";
import { updateFeedBackDataApi } from "../../../apis/feedback.api";
import { useEffect } from "react";

const FeedbackUpdateController = ({ callBack = () => {}, params }) => {
  const [error, setError] = useState("");

  const [fb1, setFb1] = useState(null);
  const [fb2, setFb2] = useState(null);
  const [fb3, setFb3] = useState(null);

  const [lastCompleted, setLastCompleted] = useState("");

  useEffect(() => {
    if (
      params?.sold_details?.fd1?.feedback_status === FEEDBACK_STATUS.PENDING
    ) {
      setFb1(params?.sold_details?.fd1?._id);
    } else if (
      params?.sold_details?.fd2?.feedback_status === FEEDBACK_STATUS.PENDING
    ) {
      setFb2(params?.sold_details?.fd2?._id);
      setLastCompleted(params?.sold_details?.fd1?._id);
    } else if (
      params?.sold_details?.fd3?.feedback_status === FEEDBACK_STATUS.PENDING
    ) {
      setFb3(params?.sold_details?.fd3?._id);
      setLastCompleted(params?.sold_details?.fd2?._id);
    }
  }, [params]);

  return (
    <FeedbackUpdateUi
      error={error}
      setError={setError}
      params={params}
      fb1={fb1}
      fb2={fb2}
      fb3={fb3}
      lastCompleted={lastCompleted}
      callBack={callBack}
    />
  );
};

export default FeedbackUpdateController;
