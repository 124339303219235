import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomInput from "../../../components/inputs/CustomInputs";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { motion } from "framer-motion";
import pathImg from "../../../assets/images/download.png";
import truck from "../../../assets/images/truck.png";
import QrScannerPopup from "../../Scanner/QrScannerPopup";
import { closeModal, openModal } from "../../../store/actions/modalAction";
import { useDispatch } from "react-redux";
import { QrCodeScanner } from "@mui/icons-material";
import { callApiAction } from "../../../store/actions/commonAction";
import { fetchFindByBoxIdApi } from "../../../apis/productStock.api";

const OrderShippUi = ({
  fields,
  loading,
  setFields,
  params,
  error,
  setError,
  setLoadingVerified,
  setLoading,
}) => {
  const [truckStopped, setTruckStopped] = useState(false);
  console.log("fields", fields.err);
  const dispatch = useDispatch();

  const onScan = (data) => {
    setFields({
      ...fields,
      box_id: data.text,
    });
  };

  // const onVerifiedBoxId = () => {
  //   setLoadingVerified(true);
  //   dispatch(
  //     callApiAction(
  //       async () =>
  //         await fetchFindByBoxIdApi({
  //           startBoxId: fields.box_id,
  //           endBoxId: fields.box_id,
  //           isShip: true,
  //           product_id: params?.product?._id,
  //         }),
  //       (response) => {
  //         console.log(" response", response);
  //         if (response.length < 1) {
  //           setFields({ ...fields, err: "Enter a valid Box ID" });
  //         } else {
  //           setFields({ ...fields, err: "" });
  //         }
  //         setLoadingVerified(false);
  //       },
  //       (err) => {
  //         setFields({ ...fields, err });
  //         setLoadingVerified(false);
  //       }
  //     )
  //   );
  // };

  // useEffect(() => {
  //   onVerifiedBoxId();
  // }, []);

  const onQrBtnClick = () => {
    dispatch(
      openModal(
        <QrScannerPopup
          onScan={onScan}
          onClose={() => dispatch(closeModal("scanner"))}
        />, // Pass onClose to handle modal close
        "sm",
        false,
        "scanner"
      )
    );
  };

  return (
    <Box position="relative" overflow="hidden" height={"100%"} width="100%">
      <Typography color="error" variant="body1">
        {fields?.err}
      </Typography>
      <Box position="relative" height={50}>
        <motion.div
          animate={{ x: ["0%", "-100%"] }}
          transition={{ repeat: Infinity, duration: 3, ease: "linear" }}
          style={{
            position: "absolute",
            width: "200%",
            height: "100%",
            backgroundImage: `url(${pathImg})`,
            backgroundSize: "cover",
            top: "-20px",
          }}
        ></motion.div>

        <motion.div
          animate={!truckStopped ? { x: ["0px", "350px"] } : { x: "350px" }}
          transition={{ duration: 5, ease: "linear" }}
          onAnimationComplete={() => setTruckStopped(true)}
          style={{ position: "absolute", left: 0, top: 0 }}
        >
          <img
            src={truck}
            alt="Truck"
            style={{ width: "60px", height: "40px" }}
          />
          {/* <LocalShippingIcon color="primary" fontSize="large" /> */}
        </motion.div>

        <motion.div
          animate={!truckStopped ? { x: ["-550px", "300px"] } : { x: "350" }}
          transition={{ duration: 12, ease: "linear" }}
          style={{
            position: "absolute",
            top: "39px",
            left: 0,
            width: "100%",
            height: "4px",
            backgroundColor: "rgb(248 113 113 / 1)",
          }}
        ></motion.div>
      </Box>

      <CustomInput
        disabled={true}
        value={params?.product?.product_name}
        type="text"
        label={"Product"}
      />
      <CustomInput
        disabled={true}
        value={params?.from?.name}
        type="text"
        label={"From"}
      />
      <FormControl
        sx={{ marginTop: "5px" }}
        color="primary"
        fullWidth
        variant="outlined"
      >
        <InputLabel color="primary" htmlFor="outlined-adornment-boxid">
          Enter Box Id*
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-boxid"
          value={fields?.box_id}
          onChange={(e) =>
            setFields({
              ...fields,
              box_id: e.target.value,
            })
          }
          type="text"
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={onQrBtnClick} edge="end">
                <QrCodeScanner />
              </IconButton>
            </InputAdornment>
          }
          color="primary"
          label="Enter Box Id*"
        />
      </FormControl>
      <CustomInput
        disabled={loading}
        value={fields?.courier_name}
        onChange={(e) =>
          setFields({ ...fields, err: "", courier_name: e.target.value })
        }
        type="text"
        label={"Courier Name"}
      />
      <CustomInput
        disabled={loading}
        value={fields?.tracking_id}
        onChange={(e) =>
          setFields({ ...fields, err: "", tracking_id: e.target.value })
        }
        type="text"
        label={"Tracking Id"}
      />
      <CustomInput
        disabled={loading}
        value={fields?.tracking_link}
        onChange={(e) =>
          setFields({ ...fields, err: "", tracking_link: e.target.value })
        }
        type="text"
        label={"Tracking Link"}
      />
    </Box>
  );
};

export default OrderShippUi;
