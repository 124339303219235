import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/main";

export const fetchOrders = async (params) => {
  const callResponse = await axios({
    url: endpoints.getOrderAPI,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateOrder = async (data) => {
  const callResponse = await axios({
    url: endpoints.updateOrderAPI,
    method: "patch",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const fetchOrderCountApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchOrderCountApi,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
