import {
  Box,
  Button,
  ButtonGroup,
  Grid2,
  IconButton,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "../../components/tables/DataTable";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import {
  FilterTitleBox,
  FiltersBox,
} from "../../components/layouts/OneViewBox";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";

import { USER_ROLES } from "../../utils/constants";
import ButtonComponentsUi from "../../components/button/ButtonComponentsUi";
import DownloadIcon from '@mui/icons-material/Download';
import { Send } from "@mui/icons-material";


const StockOfProductListUi = ({
  columns,
  list,
  filters,
  setFilters,
  loading,
  onExportClick,
  onChannelSaleClick,
  title,
  categoryType,
  setCategoryType,
  setSelectedButtonId,
  selectedButtonId,
  count,
  loadingCount,
  onDownloadCsv,setSelectedButtonName
}) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const id = "product";
  const { settings } = useSelector((state) => state);

  return (
    <>
      <Box>
        <Paper
          sx={{
            width: "100%",
            padding: 4,
            borderRadius: "8px"
          }}
        >
          <Box
            mb={4}
            display="flex"
            justifyContent={"flex-start"}
            flexDirection="column"
            gap={isSmallScreen ? 2 : 4}
          >
            <Box>
              <Typography
                variant={isSmallScreen ? "h2" : "h3"}
                textAlign={isSmallScreen ? "left" : "left"}
                marginBottom={isSmallScreen ? "5px" : "0"}
                color={"primary"}
              >
                {title}
              </Typography>

            </Box>

            {loadingCount ? (
              <Box
                mt={4}
                mb={4}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  height: "15vh",
                }}
              >
                {[0, 1, 2, 3].map((row) => (
                  <Skeleton
                    key={row}
                    variant="rectangular"
                    width={"24%"}
                    height={120}
                    sx={{
                      borderRadius: "8px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  />
                ))}
              </Box>
            )
              :
              (<Grid2
                container
                mt={4}
                pl={2}
                gap={1}
                size={12}
                justifyContent={"space-between"}
              >
                {count?.length > 0 && count?.map((item) => (<>
                  <Grid2
                    display={"flex"}
                    mb={2}
                    width={"24%"}
                    flexDirection={"row"}
                    justifyContent={"flex-start"}
                    flexWrap={"wrap"}
                    size={count?.length.length / 3}
                  >
                    <ButtonComponentsUi onSubmit={() => {
                      setSelectedButtonId(item?._id);
                      setSelectedButtonName(item?.product_name);
                    }}
                      count={item?.count ?? 0}
                      colorType={selectedButtonId === item._id}
                      Title={item?.product_name}
                    />
                  </Grid2>
                </>))}
              </Grid2>)
            }
          </Box>
        </Paper>

        <Paper sx={{ marginTop: "15px", borderRadius: "11px" }}>
          <Box
            p={4}
            display="flex"
            justifyContent={isSmallScreen ? "space-between" : "space-between"}
            alignItems="center"
            mt={1}
          >
            <Box p={2} display={"flex"}>
              {/* <Box p={2}> */}
              <PaddingBoxInDesktop
                sx={{
                  display: "flex",
                  width: "15vw",
                }}
              >
                <AsyncSearchBar
                  fullWidth
                  title="Search By Product Name"
                  size="small"
                  placeholder={"Search By Product Box Id"}
                  defaultValue={filters?.search}
                  onChange={(changedVal) =>
                    setFilters({
                      ...filters,
                      pageNo: 1,
                      pageSize: 10,
                      search: changedVal,
                    })
                  }
                />
              </PaddingBoxInDesktop>
              {/* </Box> */}

            </Box>


            <Box sx={{ display: "flex", direction: "row", justifyContent: "space-between" }}>

              <Box p={2}>
                <Button sx={{
                  width: "100%",
                  minHeight: "5vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  '&:hover': {
                    backgroundColor: "#1B4381",
                    color: "white"
                  },
                }} onClick={onDownloadCsv} variant="outlined" component="label" fullWidth={true}>

                  <Typography variant="h5" sx={{ ml: 1 }}>Download Format&nbsp;&nbsp;</Typography>
                  <DownloadIcon fontSize="medium" />
                  {/* <input hidden  type="file" /> */}
                </Button>
              </Box>

              <Box p={2}>
                <Button
                  onClick={onChannelSaleClick}
                  sx={{
                    width: "100%",
                    minHeight: "5vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    '&:hover': {
                      backgroundColor: "#1B4381",
                      color: "white"
                    },
                  }}
                  variant="outlined"
                >
                  <Typography variant="h5" sx={{ display: "flex", ml: 1 }}>
                    Send Demo Units&nbsp;&nbsp;
                  </Typography>
                  <Send />
                </Button>
              </Box>
              {(user.data.role == USER_ROLES.WEHEAR_ADMIN || user.data.role == USER_ROLES.SUPER_ADMIN) && <Box p={2}>
                <Button
                  onClick={onExportClick}
                  sx={{
                    width: "100%",
                    minWidth: "200px",
                    minHeight: "5vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    '&:hover': {
                      backgroundColor: "#1B4381",
                      color: "white"
                    },
                  }}
                  variant="outlined"
                >
                  <Typography variant="h4" >
                    Import
                  </Typography>
                </Button>
              </Box>}
            </Box>
          </Box>


          <Box
            p={4}
            sx={{
              minHeight: "40vh",
            }}
          >
            <DataTable
              columns={columns}
              rows={list?.result ? list?.result : []}
              count={list?.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper >
      </Box >
    </>
  );
};

export default StockOfProductListUi;
