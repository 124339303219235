import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { TextField } from "@mui/material";
import { Add, BugReport, Delete, RemoveCircle } from "@mui/icons-material";
import { SOLUTION_STATUS } from "../../../utils/constants";

const ProblemField = ({ fields, setFields, index, qNum }) => {
  console.log("fields", fields);
  return (
    <OutlinedInput
      value={fields[qNum]?.problems[index]?.problem}
      fullWidth
      onChange={(e) =>
        setFields((prev) => {
          let temp = [...prev[qNum]?.problems];
          temp[index] = { ...temp[index], problem: e.target.value };
          return { ...prev, [qNum]: { ...prev[qNum], problems: temp } };
        })
      }
      id="outlined-adornment-weight"
      endAdornment={
        <InputAdornment position="end">
          {" "}
          <Tooltip title="Remove Problem">
            <RemoveCircle
              sx={{ cursor: "pointer" }}
              onClick={(e) =>
                setFields((prev) => {
                  let temp = [...prev[qNum]?.problems];
                  temp.splice(index, 1);
                  return { ...prev, [qNum]: { ...prev[qNum], problems: temp } };
                })
              }
              color="error"
            />
          </Tooltip>
        </InputAdornment>
      }
      aria-describedby="outlined-weight-helper-text"
      inputProps={{
        "aria-label": "weight",
      }}
    />
  );
};
const ProblemComponent = ({ fields, setFields, qNum }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {/* <IconButton onClick={() => { setFields({ ...fields, [qNum]: { ...fields[qNum], problems: [...fields[qNum].problems, { problem: "", status: SOLUTION_STATUS.PENDING }] } }) }}>
        <Tooltip title="Add Problem">
          <BugReport fontSize={"medium"} color="primary" />
        </Tooltip>
      </IconButton> */}
      <Button
        variant="outlined"
        sx={{ backgroundColor: "primary.main", color: "white" }}
        startIcon={<Add />}
        onClick={() => {
          setFields({
            ...fields,
            [qNum]: {
              ...fields[qNum],
              problems: [
                ...fields[qNum].problems,
                { problem: "", status: SOLUTION_STATUS.PENDING },
              ],
            },
          });
        }}
      >
        <Typography>Add Problem</Typography>
      </Button>
    </Box>
  );
};

const FeedBack1Ui = ({ fields, setFields, error }) => {
  return (
    <div>
      <Typography color="error">{error}</Typography>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "50px",
            justifyContent: "space-between",
          }}
        >
          <FormLabel
            color="primary.main"
            id="demo-row-radio-buttons-group-label"
          >
            1.Are you wearing the device regularly?
          </FormLabel>
          <Box minHeight={"38px"}>
            {fields?.q1.ans == "no" && (
              <ProblemComponent
                qNum={"q1"}
                fields={fields}
                setFields={setFields}
              />
            )}
          </Box>
        </FormControl>
        <RadioGroup
          defaultValue={"no"}
          onChange={(e, newVal) => {
            setFields({ ...fields, q1: { ...fields?.q1, ans: newVal } });
          }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
        <Box width={"100%"}>
          {fields["q1"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q1"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>2.How long do you wear it for?</Typography>
          <ProblemComponent qNum={"q2"} fields={fields} setFields={setFields} />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q2: { ...fields?.q2, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q2"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q2"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            3.How was your experience talking to people one on one at home?
          </Typography>
          <ProblemComponent qNum={"q3"} fields={fields} setFields={setFields} />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q3: { ...fields?.q3, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q3"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q3"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            4.How was your experience talking to people one on one outside in a
            crowd?
          </Typography>
          <ProblemComponent qNum={"q4"} fields={fields} setFields={setFields} />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q4: { ...fields?.q4, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q4"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q4"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            5.How is your experience with being in a crowded area with loud
            noises?
          </Typography>
          <ProblemComponent qNum={"q5"} fields={fields} setFields={setFields} />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q5: { ...fields?.q5, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q5"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q5"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            6.How is your experience listening to people in front of you/ behind
            you?
          </Typography>
          <ProblemComponent qNum={"q6"} fields={fields} setFields={setFields} />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q6: { ...fields?.q6, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q6"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q6"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            7.How is your experience listening to one person when you are with
            multiple people?
          </Typography>
          <ProblemComponent qNum={"q7"} fields={fields} setFields={setFields} />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q7: { ...fields?.q7, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q7"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q7"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            8.How is your experience listening to one person when you are with
            multiple people and in a crowded area?
          </Typography>
          <ProblemComponent qNum={"q8"} fields={fields} setFields={setFields} />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q8: { ...fields?.q8, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q8"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q8"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            9.How is the sound experience when a person is talking from a
            distance?
          </Typography>
          <ProblemComponent qNum={"q9"} fields={fields} setFields={setFields} />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q9: { ...fields?.q9, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q9"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q9"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "50px",
          }}
        >
          <FormLabel color="primary" id="demo-row-radio-buttons-group-label">
            10.Have you charged the device since you bought it?
          </FormLabel>
          <ProblemComponent
            qNum={"q10"}
            fields={fields}
            setFields={setFields}
          />
        </FormControl>
        <RadioGroup
          row
          onChange={(e, newVal) => {
            setFields({ ...fields, q10: { ...fields?.q10, ans: newVal } });
          }}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="yes " control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
        <Box width={"100%"}>
          {fields["q10"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q10"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      {/* Other */}

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            11.How long did you let it charge approximately?
          </Typography>
          <ProblemComponent
            qNum={"q11"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q11: { ...fields?.q11, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q11"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q11"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            12.Have you tried connecting the device with any device via
            bluetooth?
          </Typography>
          <ProblemComponent
            qNum={"q12"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q12: { ...fields?.q12, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q12"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q12"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            13.Have you tried using the streaming function of the device?
          </Typography>
          <ProblemComponent
            qNum={"q13"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q13: { ...fields?.q13, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q13"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q13"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>14.How’s your comfort in wearing the device?</Typography>
          <ProblemComponent
            qNum={"q14"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q14: { ...fields?.q14, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q14"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q14"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            15.Any additional feedback you would like to give?
          </Typography>
          <ProblemComponent
            qNum={"q15"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q15: { ...fields?.q15, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box mb={3} width={"100%"}>
          {fields["q15"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q15"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default FeedBack1Ui;
