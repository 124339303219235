import { Box, Button, ButtonGroup, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import StaffListController from "./staffWiseAnalysis/StaffListController";
import { USER_ROLES } from "../../utils/constants";
import { getReimbursementListApi, getUserEmailListApi } from "../../apis/user.api";
import { callApiAction } from "../../store/actions/commonAction";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
}));

const LeadDashboardMain = () => {
  const { user } = useSelector((state) => state);
  const [step, setStep] = useState(USER_ROLES.CRM);
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    sort: "createdAt",
    sortDirection: -1,
    startDate: "",
    endDate: "",
    deleted: false,
  });
  console.log("filters", filters);

  const [reiList, setReiList] = useState([]);
  const [emails, setEmails] = useState([]);
  const dispatch = useDispatch();


  const fetchEmails = () => {
    dispatch(
      callApiAction(
        async () => await getUserEmailListApi(),
        async (response) => {
          setEmails(response);
        },
        (err) => {
        }
      )
    );
  };

  useEffect(() => {
    if (step == USER_ROLES.SALES_STAFF)
      fetchEmails();
  }, [step]);

  const fetchData = () => {

    dispatch(
      callApiAction(
        async () =>
          await getReimbursementListApi({
            email: emails.map((item) => item.email),
            ...filters,
          }),
        async (response) => {

          setReiList(response);
        },
        (err) => {
        }
      )
    );
  };

  useEffect(() => {
    if (step == USER_ROLES.SALES_STAFF && emails.length > 0)
      fetchData();
  }, [emails])


  return (<>
    <Box mb={2}>
      <ButtonGroup
        sx={{ color: "#101536" }}
        fullWidth
        size="large"
        aria-label="Large button group"
      >

        <Button
          sx={step !== USER_ROLES.CRM && { backgroundColor: "white" }}
          variant={step == USER_ROLES.CRM ? "contained" : "outlined"}
          onClick={() => setStep(USER_ROLES.CRM)}
          fullWidth
        >
          CRM
        </Button>
        <Button
          sx={step !== USER_ROLES.SALES_STAFF && { backgroundColor: "white" }}
          variant={step == USER_ROLES.SALES_STAFF ? "contained" : "outlined"}
          onClick={() => setStep(USER_ROLES.SALES_STAFF)}
          fullWidth
        >
          Sales Staff
        </Button>
      </ButtonGroup>
    </Box>
    <Box width={"100%"} p={2} sx={{ backgroundColor: "white" }} height={"100%"}>
      <Box sx={{ m: 4 }}>
        <FilterTitleBox>
          <Typography color="primary" variant="h4">
            Lead Dashboard
          </Typography>

          <PaddingBoxInDesktop
            sx={{
              display: "flex",
              width: "30vw",
              flexDirection: "flex-start",
            }}
          >
            <TimeRangeSelector
              defaultVal={{ label: "Lifetime", _id: null }}
              onChange={(val) => setFilters({ ...filters, ...val })}
            />
          </PaddingBoxInDesktop>
        </FilterTitleBox>
      </Box>



      <StaffListController reiList={reiList} step={step} filters={filters} />
    </Box>
  </>
  );
};
export default LeadDashboardMain;
