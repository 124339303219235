import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/main";

export const getPaymentURL = async (data) => {
  const callResponse = await axios({
    url: endpoints.getPaymentUrl,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addPaymentWithUPI = async (data) => {
  const callResponse = await axios({
    url: endpoints.addPaymentWithUpi,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updatePaymentApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.updatePaymentApi,
    method: "patch",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const initiateRefundApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.initiateRefundApi,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const RefundApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.refundApi,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const fetchRefundDataApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchRefundDataApi,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const fetchRefundCountApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchRefundCountApi,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
