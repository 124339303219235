import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Grid2,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../components/inputs/CustomInputs";
import { GENDER, SOURCE_TYPE, USER_ROLES } from "../../../utils/constants";
import moment from "moment";
import COUNTRY_CODES from "../../../utils/CountryCode";
import { CenteredBox } from "../../../components/layouts/OneViewBox";
import { calculateAge } from "../../../utils/helper";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { findObjectKeyByValue, titleCase } from "../../../utils/main";

const CreateLeadUi = ({ fields, setFields, loading }) => {
  let source = Object.keys(SOURCE_TYPE).map((key) => ({
    label: titleCase(key.replace(/_/g, " ")), // Convert keys to readable format
    _id: SOURCE_TYPE[key],
  }));

  return (
    <>
      <Box height={"10px"}>
        <Typography variant="h5" color={"red"}>
          {fields?.err}
        </Typography>
      </Box>

      <Box
        p={1}
        mt={2}
        sx={{
          borderRadius: "4px",
        }}
      >
        <Grid2 container spacing={{ md: 2 }} size={12}>
          <Grid2 size={{ md: 6, xl: 6, xs: 12, sm: 6 }}>
            <CustomInput
              autoFocus={true}
              disabled={loading}
              value={fields?.first_name}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: "",
                  first_name: e.target.value,
                })
              }
              type="text"
              label={"First Name*"}
            />
          </Grid2>
          <Grid2 size={{ md: 6, xl: 6, xs: 12, sm: 6 }}>
            <CustomInput
              disabled={loading}
              value={fields?.last_name}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: "",
                  last_name: e.target.value,
                })
              }
              type="text"
              label={"Last Name"}
            />
          </Grid2>
        </Grid2>

        <Grid2 container spacing={{ md: 2 }} size={12}>
          <Grid2 size={{ md: 3.5, xl: 3.5, xs: 12 }}>
            {" "}
            <CustomInput
              disabled={loading}
              value={fields?.phone}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: "",
                  phone: e.target.value,
                })
              }
              type="number"
              label={"Phone*"}
              onWheel={(e) => e.target.blur()}
              sx={{
                "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input": {
                  MozAppearance: "textfield",
                },
              }}
            />
          </Grid2>
          <Grid2 size={{ md: 4, xl: 4, xs: 12 }}>
            {" "}
            <CustomInput
              disabled={loading}
              value={fields?.email}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: "",
                  email: e.target.value,
                })
              }
              type="text"
              label={"Email"}
            />
          </Grid2>
          <Grid2 size={{ md: 3, xl: 3, xs: 12 }}>
            <DesktopDatePicker
              sx={{ width: "18vw", height: "100%" }}
              inputFormat="DD/MM/yyyy"
              renderInput={(props) => <CustomInput {...props} />}
              value={fields?.dob ? moment(fields?.dob) : null}
              onChange={(val) => {
                if (val && val.isValid()) {
                  const isoString = val.toISOString();
                  setFields({
                    ...fields,
                    err: "",
                    dob: isoString,
                    age: calculateAge(isoString),
                  });
                  // setIsAgeModified(false);
                } else {
                  setFields({
                    ...fields,
                    err: "",
                    dob: "",
                    age: 0,
                  });
                }
              }}
              label={"DOB"}
            />
          </Grid2>
          <Grid2 size={{ md: 1.5, xl: 1.5, xs: 12 }}>
            <CustomInput
              disabled={true}
              value={fields?.age}
              nputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setFields({ ...fields, age: e.target.value });
              }}
              onBlur={(e) => {
                const dob = moment()
                  .set("date", 1)
                  .set("month", 5)
                  .add(-1 * e.target.value, "year");
                setFields({
                  ...fields,
                  err: "",
                  dob: dob.toISOString(),
                });
              }}
              type="number"
              label={"Age*"}
            />
          </Grid2>
        </Grid2>

        <Box>
          <CustomInput
            multiline
            rows={2}
            disabled={loading}
            value={fields?.address}
            onChange={(e) =>
              setFields({
                ...fields,
                err: "",
                address: e.target.value,
              })
            }
            type="text"
            label={"Address*"}
          />
        </Box>

        <Grid2 container spacing={{ md: 2 }} size={12}>
          <Grid2 size={{ md: 4, xl: 4, xs: 12 }}>
            <CustomInput
              disabled={loading}
              value={fields?.state}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: "",
                  state: e.target.value,
                })
              }
              type="text"
              label={"State*"}
            />
          </Grid2>
          <Grid2 size={{ md: 4, xl: 4, xs: 12 }}>
            <CustomInput
              disabled={loading}
              value={fields?.city}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: "",
                  city: e.target.value,
                })
              }
              type="text"
              label={"City*"}
            />
          </Grid2>
          <Grid2 size={{ md: 4, xl: 4, xs: 12 }}>
            <CustomInput
              disabled={loading}
              value={fields?.zip_code}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: "",
                  zip_code: e.target.value,
                })
              }
              type="number"
              label={"Zip/Postal*"}
              InputLabelProps={{
                shrink: true,
              }}
              onWheel={(e) => e.target.blur()}
              sx={{
                "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input": {
                  MozAppearance: "textfield",
                },
              }}
            />
          </Grid2>
        </Grid2>

        <Grid2 spacing={2} container display={"flex"} alignItems={"center"}>
          <Grid2 size={{ md: 6, xl: 12, xs: 12 }}>
            <Box
              mt={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                border: "1px solid black",
                borderRadius: "5px",
                padding: "5px",
                height: "7.7vh",
                borderColor: "#1B4381",
              }}
            >
              <FormLabel
                sx={{ fontSize: "16px" }}
                disabled={loading}
                id="demo-row-radio-buttons-group-label"
              >
                Gender*
              </FormLabel>
              <RadioGroup
                sx={{}}
                value={fields?.gender}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    gender: e.target.value,
                  })
                }
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {Object.keys(GENDER).map((item) => (
                  <FormControlLabel
                    disabled={loading}
                    key={item}
                    value={GENDER[item]}
                    control={<Radio />}
                    label={titleCase(item)}
                  />
                ))}
              </RadioGroup>
            </Box>
          </Grid2>
          <Grid2 size={{ md: 6, xl: 12, xs: 12 }}>
            <Autocomplete
              disableClearable
              options={source}
              value={findObjectKeyByValue(fields.source, SOURCE_TYPE)}
              onChange={(e, newVal) => {
                setFields({ ...fields, source: newVal ? newVal._id : null });
              }}
              sx={{
                width: "100%",
                display: "flex",
                "*": { display: "flex", justifyContent: "center" },
              }}
              renderInput={(params) => (
                <CustomInput
                  placeholder="Select Source*"
                  {...params}
                  label="Select Source*"
                />
              )}
            />
          </Grid2>
        </Grid2>
        <Box>
          <CustomInput
            multiline
            rows={2}
            disabled={loading}
            value={fields?.comment}
            onChange={(e) =>
              setFields({
                ...fields,
                err: "",
                comment: e.target.value,
              })
            }
            type="text"
            label={"Comment*"}
          />
        </Box>
      </Box>
    </>
  );
};

export default CreateLeadUi;
