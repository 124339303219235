import { getFpDataApi, getScpOrFpDataApi } from "../../../apis/dashboard.api";
import { useEffect } from "react";
import { callApiAction } from "../../../store/actions/commonAction";
import { useDispatch } from "react-redux";
import { useState } from "react";
import FpListUi from "./FpListUi";

const FpListController = ({ role,filters }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);

  const fetchData = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getScpOrFpDataApi({ role: role,...filters }),
        async (response) => {
          setDataList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  return <FpListUi loading={loading} dataList={dataList} role={role} />;
};
export default FpListController;
