import {
  Button,
  Grid2,
  Paper,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import { memo } from "react";
import { findObjectKeyByValue, toTitleCase } from "../../utils/main";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import { DATE_TIME_FILTERS } from "../../utils/constants";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import DataTable from "../../components/tables/DataTable";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import ButtonComponentsUi from "../../components/button/ButtonComponentsUi";
import OrderComponentsUi from "../../components/button/OrderComponentsUi";
import MODULES from "../../utils/module.constant";
import { useSelector } from "react-redux";
import { AddIcCallOutlined } from "@mui/icons-material";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
}));

const LeadListUi = ({
  filters,
  setFilters,
  loading,
  columns,
  selectOrderType,
  list,
  setSelectOrderType,
  countLoading,
  count,
  createLead,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <Paper elevation={0} sx={{ width: "100%", minHeight: "100%", padding: 3 }}>
      <Box>
        <FilterTitleBox>
          <Typography variant="h3" color={"primary"}>
            Leads
          </Typography>
          {user.data.modules?.includes(MODULES.LEAD_CREATE) && (
            <Box>
              <Button
                onClick={createLead}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
                variant="contained"
              >
                <AddIcCallOutlined /> &nbsp;
                <Typography variant="h5">Create Lead</Typography>
              </Button>
            </Box>
          )}
        </FilterTitleBox>
      </Box>
      {countLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            height: "15vh",
          }}
          mt={4}
          pl={2}
        >
          {[0, 1, 2, 3]?.map((row) => (
            <Skeleton
              key={row}
              variant="rectangular"
              width={"24%"}
              height={120}
              sx={{
                borderRadius: "8px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            />
          ))}
        </Box>
      ) : (
        <Grid2
          container
          mt={4}
          pl={2}
          size={12}
          justifyContent={"space-between"}
        >
          <Grid2
            display={"flex"}
            mb={2}
            flexDirection={"row"}
            justifyContent={"flex-start"}
            flexWrap={"wrap"}
            size={12 / (Object.values(count ?? {}).length - 1)}
          >
            <OrderComponentsUi
              count={count?.all ?? 0}
              onSubmit={() => setSelectOrderType("all")}
              width={"98%"}
              loading={loading}
              colorType={selectOrderType === "all"}
              Title={"All"}
            />
          </Grid2>

          {count?.unassigned != null && (
            <Grid2
              display={"flex"}
              mb={2}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              flexWrap={"wrap"}
              size={12 / (Object.values(count ?? {}).length - 1)}
            >
              <OrderComponentsUi
                count={count?.unassigned ?? 0}
                onSubmit={() => setSelectOrderType("unassigned")}
                width={"98%"}
                loading={loading}
                colorType={selectOrderType === "unassigned"}
                Title={"Unassigned"}
              />
            </Grid2>
          )}
          {count?.assigned != null && (
            <Grid2
              display={"flex"}
              mb={2}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              flexWrap={"wrap"}
              size={12 / (Object.values(count ?? {}).length - 1)}
            >
              <OrderComponentsUi
                count={count?.assigned ?? 0}
                onSubmit={() => setSelectOrderType("assigned")}
                loading={loading}
                width={"98%"}
                colorType={selectOrderType === "assigned"}
                Title={"Assigned"}
              />
            </Grid2>
          )}
          <Grid2
            display={"flex"}
            mb={2}
            flexDirection={"row"}
            justifyContent={"flex-start"}
            flexWrap={"wrap"}
            size={12 / (Object.values(count ?? {}).length - 1)}
          >
            <OrderComponentsUi
              count={count?.connected ?? 0}
              onSubmit={() => setSelectOrderType("connected")}
              loading={loading}
              width={"98%"}
              colorType={selectOrderType === "connected"}
              Title={"Connected"}
            />
          </Grid2>
          <Grid2
            display={"flex"}
            mb={2}
            flexDirection={"row"}
            justifyContent={"flex-start"}
            flexWrap={"wrap"}
            size={12 / (Object.values(count ?? {}).length - 1)}
          >
            <OrderComponentsUi
              count={count?.trial ?? 0}
              onSubmit={() => setSelectOrderType("trial")}
              loading={loading}
              width={"98%"}
              colorType={selectOrderType === "trial"}
              Title={"Trials"}
            />
          </Grid2>

          <Grid2
            display={"flex"}
            mb={2}
            flexDirection={"row"}
            justifyContent={"flex-start"}
            flexWrap={"wrap"}
            size={12 / (Object.values(count ?? {}).length - 1)}
          >
            <OrderComponentsUi
              count={count?.converted ?? 0}
              onSubmit={() => setSelectOrderType("converted")}
              loading={loading}
              width={"98%"}
              colorType={selectOrderType === "converted"}
              Title={"Converted"}
            />
          </Grid2>
        </Grid2>
      )}

      <Box sx={{ mt: 4 }}>
        <FilterTitleBox>
          <PaddingBoxInDesktop
            sx={{
              display: "flex",
              width: "30vw",
              flexDirection: "flex-start",
            }}
          >
            <TimeRangeSelector
              defaultVal={{
                label: "Lifetime",
                _id: findObjectKeyByValue(
                  DATE_TIME_FILTERS.today,
                  DATE_TIME_FILTERS
                ),
              }}
              onChange={(val) => setFilters({ ...filters, ...val })}
            />
          </PaddingBoxInDesktop>

          <PaddingBoxInDesktop p={2} sx={{ display: "flex", width: "20vw" }}>
            <AsyncSearchBar
              fullWidth
              title="Search Name | Email"
              size="small"
              placeholder={"Search Name | Email"}
              defaultValue={filters?.search}
              onChange={(changedVal) =>
                setFilters({
                  ...filters,
                  pageNo: 1,
                  pageSize: 10,
                  search: changedVal,
                })
              }
            />
          </PaddingBoxInDesktop>
        </FilterTitleBox>

        <Box mt={2}>
          <DataTable
            key={JSON.stringify(list)}
            columns={columns}
            rows={list?.result ? list.result : []}
            count={list?.total ?? 0}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
          />
        </Box>
      </Box>
    </Paper>
  );
};
export default memo(LeadListUi);
