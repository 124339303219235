import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";

const ProductListUi = ({ dataList, loading }) => {
  const headers = ["Unit Sold", "Unit Trials", "Return", "Demo Units In-Store"];

  return (
    <Box p={4}>
      <Typography variant="h4" align="left" color="#1B4381" gutterBottom>
        Product List Analysis
      </Typography>
      <TableContainer
        sx={{
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
          borderBottomRightRadius: dataList.length > 0 && "5px",
          borderBottomLeftRadius: dataList.length > 0 && "5px",
          maxHeight: 325, // Fix the height of the table container
          overflowY: "auto",
          border: dataList.length > 0 && "1px solid rgb(129, 166, 222)",
          "&::-webkit-scrollbar": {
            width: 4, // Width of the scrollbar
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#1B4381", // Scrollbar thumb color
            borderRadius: 2,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0", // Scrollbar track color
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#1B4381" }}>
              <TableCell
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  backgroundColor: "#1B4381",
                }}
              >
                Product
              </TableCell>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  align="center"
                  sx={{
                    color: "#fff",
                    fontWeight: "bold",
                    backgroundColor: "#1B4381",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              dataList?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {row?.product_name ?? "NA"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.statusCounts?.purchased ?? 0}
                  </TableCell>
                  <TableCell align="center">
                    {row?.statusCounts?.on_trials ?? 0}
                  </TableCell>
                  <TableCell align="center">
                    {row?.statusCounts?.returned ?? 0}
                  </TableCell>
                  <TableCell align="center">
                    {row?.statusCounts?.in_store ?? 0}
                  </TableCell>
                  {/* <TableCell align="center">{row?.customer?.trial_units ?? 0}</TableCell>
                      <TableCell align="center">{row?.demounits?.demo_units ?? 0}</TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!loading && dataList.length < 1 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-1px",
            width: "100%",
            height: "10vh",
            border: "1px solid rgb(129, 166, 222)",
            borderBottomRightRadius: dataList.length < 1 && "5px",
            borderBottomLeftRadius: dataList.length < 1 && "5px",
          }}
        >
          {" "}
          <Typography color="rgb(32, 65, 115)" fontWeight={"bold"}>
            No Products Found
          </Typography>
        </Box>
      )}

      {loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-1px",
            width: "100%",
            height: "10vh",
            border: "1px solid rgb(129, 166, 222)",
            borderBottomRightRadius: dataList.length < 1 && "5px",
            borderBottomLeftRadius: dataList.length < 1 && "5px",
          }}
        >
          {" "}
          <Typography color="rgb(32, 65, 115)" fontWeight={"bold"}>
            Loading...
          </Typography>
        </Box>
      )}
      {/* </Paper> */}
    </Box>
  );
};
export default ProductListUi;
