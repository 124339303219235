import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/main";


export const getNotifcationsApi = async () => {
    try {
      const response = await axios.get(endpoints?.notificationFetch, { headers: getHeaders() });
      return response?.data;
    } catch (err) {
      return { status: 0, response: err?.response, code: err?.response?.status };
    }
  };
  
  export const readNotifcationsApi = async data => {
    try {
      const response = await axios.patch(endpoints?.notificationBase, data, { headers: getHeaders() });
      return response.data;
    } catch (err) {
      return { status: 0, response: err?.response, code: err?.response?.status };
    }
  };
  
  export const getLastReadTimestampApi = async () => {
    try {
      const response = await axios.get(endpoints?.notificationLastReadTimeStamp, { headers: getHeaders() });
      return response?.data?.data?.lastReadTimestamp;
    } catch (err) {
      return { status: 0, response: err?.response, code: err?.response?.status };
    }
  };
  
  export const setLastReadTimestampApi = async (lastReadTimestamp) => {
    try {
      const response = await axios.post(endpoints?.notificationSetTimeStamp, { lastReadTimestamp }, { headers: getHeaders() });
      return response?.data;
    } catch (err) {
      return { status: 0, response: err?.response, code: err?.response?.status };
    }
  };