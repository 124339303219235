import axios from "axios";
// import { getFileHeaders, getHeaders, unEscapeStr } from "../utils/main";
import endpoints from "./endpoints";
import { setup } from "axios-cache-adapter";
import localforage from "localforage";
import localforageMemoryStorageDriver from 'localforage-memoryStorageDriver';
import { getFileHeaders, getHeaders } from "../utils/main";
import { unEscapeStr } from "../utils/helper";

const configureAxios = async () => {

  await localforage.defineDriver(localforageMemoryStorageDriver);

  const forageStore = localforage.createInstance({
    driver: [
      localforage.INDEXEDDB,
      localforage.LOCALSTORAGE,
      localforageMemoryStorageDriver._driver
    ],
    name: 'hrms-cache'
  });

  return setup({

    // Options passed to `axios.create()` method
    headers: getHeaders(),

    // `axios-cache-adapter` options
    cache: {
      readHeaders: false,
      maxAge: 3600 * 60 * 1000,
      exclude: {
        query: false
      },
      store: forageStore,
    }
  });
};

export const getFileOrImage = async (data) => {
  const api = await configureAxios();

  return await api({
    url: endpoints.fileOrImage,
    method: "get",
    headers: getHeaders(),
    params: { ...data, fileUrl: unEscapeStr(data.fileUrl) },
    responseType: "blob",
  })
    .then((response) => {

      return response.data
    })
    .catch((err) => ({ status: 0, response: err.response, code: err.response }));
};

export const uploadFile = async (data, onUploadProgress = () => { }) => {
  const callResponse = await axios({
    url: endpoints.file,
    method: "post",
    data,
    onUploadProgress,
    headers: getFileHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
};

export const uploadImage = async (data, onUploadProgress = () => { }) => {
  const callResponse = await axios({
    url: endpoints.image,
    method: "post",
    data,
    onUploadProgress,
    headers: getFileHeaders(),
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
};

export const deletefileOrImage = async (src) => {
  const callResponse = await axios({
    url: endpoints.fileOrImage,
    method: "delete",
    headers: getHeaders(),
    data: { fileUrl: src },
  })
    .then((response) => response.data)
    .catch((err) => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
};


