import React, { useEffect, useMemo, useState } from "react";
import IncentiveListUi from "./IncentiveListUi";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateIncentiveApi,
  FetchDetailIncentiveApi,
  FetchIncentiveApi,
  FetchuserIncentiveApi,
  UpdateIncentiveApi,
} from "../../apis/incentive.api";
import { callApiAction } from "../../store/actions/commonAction";
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants";
import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { closeModal, openModal } from "../../store/actions/modalAction";
import MessageDilog from "../../components/MessageDilog";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { Check } from "@mui/icons-material";
import CustomerInformationController from "../customer/CustomerViews/CustomerInformationController";

const ActionComponent = ({ params, setcallBack, callBack }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  const UpdateFun = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await CreateIncentiveApi({
            sellerId: params?.sellerId,
            customerId: params?.customerId,
            salesAmount: params?.amount,
            incentivePercentage: params?.incentivePercentage,
            incentiveAmount: params?.total_incentive_amount,
            isPaid: true,
          }),
        (response) => {
          setLoading(false);
          setcallBack({ ...callBack, data: false });
          dispatch(closeModal("messagedialogupdate"));
          dispatch(callSnackBar("Incentive Paid", SNACK_BAR_VARIETNS.suceess));
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const UpdateReceivedFun = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await UpdateIncentiveApi({
            sellerId: params?.sellerId,
            customerId: params?.customerId,
            salesAmount: params?.amount,
            incentiveAmount: params?.total_incentive_amount,
          }),
        (response) => {
          setLoading(false);
          setcallBack({ ...callBack, data: false });
          dispatch(closeModal("messagedialogupdate"));
          dispatch(
            callSnackBar("Incentive Received", SNACK_BAR_VARIETNS.suceess)
          );
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const statusUpdate = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={(e) =>
            params?.isReceived === false && params?.isPaid === true
              ? UpdateReceivedFun(e)
              : UpdateFun(e)
          }
          title="Alert!"
          modalId="messagedialogupdate"
          message={`Are you sure Incentive Amount has been ${params?.isReceived === false && params?.isPaid === true
            ? "Received"
            : "Paid"
            }?`}
          loading={loading}
        />,
        "sm",
        false,
        "messagedialogupdate"
      )
    );
  };



  return (
    <>
      <Box sx={{ width: "100%", alignItems: "center", display: "flex" }}>
        {(((user.data.role === USER_ROLES.WEHEAR_ADMIN ||
          user.data.role === USER_ROLES.SUPER_ADMIN) &&
          params?.isPaid === false) ||
          (user.data.role !== USER_ROLES.WEHEAR_ADMIN &&
            user.data.role !== USER_ROLES.SUPER_ADMIN &&
            params?.isReceived === false &&
            params?.isPaid === true)) && params?.total_incentive_amount > 0 && (
            <Tooltip
              title={
                params?.isReceived === false && params?.isPaid === true
                  ? "Received"
                  : "Paid"
              }
            >
              <IconButton size="inherit" onClick={statusUpdate}>
                <Check color="success" fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
      </Box>
    </>
  );
};

const IncentiveListController = ({ userId, role }) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    err: "",
    pageNo: 1,
    pageSize: 10,
    date: moment(),
    data: false,
    searchable: ["first_name", "last_name"],
    searchableFields: ["name"],
    search: "",
    sort: "createdAt",
    sortDirection: -1,
    usersearch: "",
  });
  const [callBack, setcallBack] = useState({ data: false });
  const { user } = useSelector((state) => state);
  const TabAccess = (user) => {
    switch (user) {
      case USER_ROLES.WEHEAR_ADMIN:
        return 2;
      case USER_ROLES.SALES_CHANNEL_PARTNER:
        return 2;
      case USER_ROLES.FRANCHISE_PARTNER:
        return 3;
      case USER_ROLES.SALES_STAFF:
        return 5;
      default:
        return 2;
    }
  };
  const [step, setStep] = useState(TabAccess(user.data.role));
  const handleDateChange = (newDate) => {
    setFilters({ ...filters, date: newDate });
  };

  const onVeiw = (params) => {
    dispatch(
      openModal(
        <CustomerInformationController id={params.customerId} />,
        "xl",
        true,
        "CustomerInformation"
      )
    );
  };

  const columns = useMemo(
    () =>
      [
        {
          id: 1,
          fieldName: "createdAt",
          label: "Date",
          align: "left",
          sort: true,
          renderValue: (params) => moment(params.date).format("DD/MM/YYYY"),
        },
        {
          id: 6,
          fieldName: "sellerName",
          label: "Seller",
          align: "left",
          sort: true,
        },
        {
          id: 2,
          fieldName: "customerName",
          label: "Customer",
          align: "left",
          renderValue: (params) => (
            <Typography
              variant="body2"
              onClick={() => {
                onVeiw(params);
              }}
              sx={{ cursor: "pointer" }}
            >
              {params?.customerName}
            </Typography>
          ),
          sort: true,
        },
        {
          id: 8,
          fieldName: "productName",
          label: "Product",
          align: "left",
          sort: false,
        },
        // {
        //     id: 9,
        //     fieldName: "total_incentive_amount",
        //     label: "Incentive",
        //     align: "left",
        //     sort: false,
        // },
        {
          id: 11,
          fieldName: "amount",
          label: "Sales Amount",
          align: "left",
          sort: false,
        },
        {
          id: 18,
          fieldName: "hold_incentive_amount",
          label: "Hold Incentive",
          align: "left",
          sort: false,
        },
        {
          id: 45,
          fieldName: "total_incentive_amount",
          label: "Receiveable Incentive",
          align: "left",
          sort: false,
        },
        {
          id: 7,
          fieldName: "",
          label: "Status",
          align: "left",
          renderValue: (params) => (
            <>
              {" "}
              {params?.isReceived === true ? (
                <Tooltip title="Paid">
                  <Chip
                    sx={{ minWidth: "100px" }}
                    label="Paid"
                    color="success"
                  />
                </Tooltip>
              ) : params?.isPaid === true ? (
                <Tooltip title="Transfferd">
                  <Chip
                    sx={{ minWidth: "100px" }}
                    label="Transfferd"
                    color="info"
                  />
                </Tooltip>
              ) : (
                <Tooltip title="UnPaid">
                  <Chip
                    sx={{ minWidth: "100px" }}
                    label="UnPaid"
                    color="error"
                  />
                </Tooltip>
              )}
            </>
          ),
        },
        {
          id: 9,
          fieldName: "",
          label: "Action",
          align: "right",
          renderValue: (params) => (
            <ActionComponent
              params={params}
              setcallBack={setcallBack}
              callBack={callBack}
            />
          ),
        },
      ].filter(Boolean),
    [dispatch, user.data.role, callBack]
  );
  const [userIncentive, setIncentive] = useState([]);
  const [listloading, Setlistloading] = useState(false);

  const FetchIncentiveList = () => {
    setLoading(true);
    const month = moment(filters.date).format("MM");
    const year = moment(filters.date).format("YYYY");
    dispatch(
      callApiAction(
        async () =>
          await FetchDetailIncentiveApi({
            userId: userId,
            month,
            year,
            role: role,
            ...filters,
          }),
        (response) => {
          console.log("Incentive response", response);
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  // const FetchuserIncentiveList = () => {
  //     Setlistloading(true);
  //     const month = moment(filters.date).format('MM');
  //     const year = moment(filters.date).format('YYYY');
  //     dispatch(
  //         callApiAction(
  //             async () => await FetchuserIncentiveApi({ search: filters.usersearch, month, year, role: step }),
  //             (response) => {
  //                 setIncentive(response);
  //                 Setlistloading(false);
  //             },
  //             (err) => {
  //                 Setlistloading(false);
  //             }
  //         )
  //     );
  // };

  // useEffect(() => {
  //     FetchuserIncentiveList();
  // }, [step, filters?.usersearch, filters.date]);

  useEffect(() => {
    if (callBack.data) {
      if (userId) {
        FetchIncentiveList();
      }
    } else {
      setcallBack({ ...callBack, data: true });
    }
  }, [userId, filters, callBack, step]);

  return (
    <IncentiveListUi
      date={filters.date}
      setDate={handleDateChange}
      loading={loading}
      columns={columns}
      list={list}
      filters={filters}
      setFilters={setFilters}
      setStep={setStep}
      step={step}
      userIncentive={userIncentive}
      // setuserId={setuserId}
      userId={userId}
      listloading={listloading}
    />
  );
};

export default IncentiveListController;
