import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { getUserByIdApi, updateUserField } from "../../apis/user.api";
import UpdateIncentivePercentageUi from "./UpdateIncentivePercentageUi";
import { updateUserIncentiveApi } from "../../apis/incentive-percentage";
import { FetchIncentiveByIdApi } from "../../apis/incentive.api";

const UpdateIncentivePercentageController = ({ callBack = () => {}, id }) => {
  const dispatch = useDispatch();

  const Title = "Update Incentive";
  const updateApi = updateUserIncentiveApi;
  const getByIdApi = FetchIncentiveByIdApi;

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    id,
    scp_percentage: 0,
    fp_percentage: 0,
    sales_staff_percentage: 0,
    crm_incentive_percentage: 0,
  });

  const updateFunction = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await updateApi(fields),
        async (response) => {
          await callBack(response, fields);
          setLoading(false);
          dispatch(
            callSnackBar("Updated Successfully", SNACK_BAR_VARIETNS.suceess)
          );
          dispatch(closeModal());
        },
        (err) => {
          setLoading(false);
          setFields({ ...fields, err });
        }
      )
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    updateFunction();
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchById(id);
  }, [id]);

  return (
    <UpdateIncentivePercentageUi
      title={Title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(UpdateIncentivePercentageController);
