import {
    Box,
    Button,
    ButtonGroup,
    Card,
    Paper,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React from "react";
import { FilterTitleBox } from "../../components/layouts/OneViewBox";
import { findObjectKeyByValue, titleCase } from "../../utils/main";
import {
    BUTTON_STATUS_COLOR_TYPE,
    BUTTON_TYPE,
    STATUS,
    STATUS_COLOR_TYPE,
} from "../../utils/constants";
import { formatNumberCustomPattern } from "../../utils/helper";
import { current } from "@reduxjs/toolkit";

const OrderComponentsUi = ({
    count,
    onSubmit,
    Title,
    ButtonGroup,
    colorType,
    STATUSWiseData,
    CountButtonGroup,
    width = "18vw"

}) => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("lg"))

    return (
        <>
            {!ButtonGroup ? (
                <Card
                    variant="outlined"
                    onClick={onSubmit}
                    sx={{
                        backgroundColor: STATUS_COLOR_TYPE(colorType),
                        width: width,
                        borderRadius: "8px",
                        border: colorType ? "3px solid #1B4381" : "1px solid rgb(129, 166, 222)",
                        cursor: "pointer",
                        // maxHeight: "17vh",
                        // height: "18vh"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center", // Center content horizontally
                            alignItems: "center", // Center content vertically
                            padding: "12px",
                            flexDirection: "column",
                            textAlign: "center", // Ensure text is centered
                        }}
                    >
                        <Typography
                            variant={"body2"}
                            sx={{
                                fontWeight: "bold",
                                color: colorType ? "white" : "#1B4381",
                                textTransform: "uppercase",
                            }}
                        >
                            {Title}
                        </Typography>

                        <Typography
                            variant={"h4"} s
                            sx={{
                                fontWeight: "bold",
                                color: colorType ? "white" : "#1B4381",
                            }}
                        >
                            {formatNumberCustomPattern(count)}
                        </Typography>
                    </Box>
                </Card>

            ) : (
                <Button
                    onClick={onSubmit}
                    variant={BUTTON_STATUS_COLOR_TYPE(STATUSWiseData)}
                    sx={{
                        height: "5vh",
                        width: "100%",
                        borderRadius: "8px",
                        backgroundColor: STATUS_COLOR_TYPE(STATUSWiseData),
                        border: STATUSWiseData ? "2px solid #1B4381" : "1px solid #1B4381",
                        padding: "5px",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            color: STATUSWiseData ? "#fff" : "text.primary",
                        }}
                    >
                        {Title}  &nbsp; {CountButtonGroup &&
                            <>
                                ( &nbsp;{formatNumberCustomPattern(CountButtonGroup)}&nbsp; )
                            </>
                        }
                    </Typography>

                </Button>
            )}
        </>
    );
}

export default OrderComponentsUi