import { Box, styled } from "@mui/material";
import CountListController from "./CountWiseAnalysis/CountListController";
import ProductListController from "./ProductWiseAnalysis/ProductListController";
import ScpListController from "./ScpWiseAnalysis/ScpListController";
import { USER_ROLES } from "../../utils/constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FpListController from "./FpWiseAnalysis/FpListController";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import { findObjectKeyByValue } from "../../utils/main";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import { generateHash } from "../../utils/helper";

const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
}));

const DashboardMain = () => {
    const { user } = useSelector((state) => state);
    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        sort: "createdAt",
        sortDirection: -1,
        startDate: "",
        endDate: "",
        deleted: false,
    });
    console.log("filters", filters);

    return (
        <Box width={"100%"} p={2} sx={{ backgroundColor: "white" }} height={"100%"}>
            <CountListController setFilters={setFilters} filters={filters} />
            <Box sx={{ mt: 4, ml: 4 }}>
                <FilterTitleBox>
                    <PaddingBoxInDesktop
                        sx={{
                            display: "flex",
                            width: "30vw",
                            flexDirection: "flex-start",
                        }}
                    >
                        <TimeRangeSelector
                            defaultVal={{ label: "Lifetime", _id: null }}
                            onChange={(val) => setFilters({ ...filters, ...val })}
                        />
                    </PaddingBoxInDesktop>
                </FilterTitleBox>


            </Box>
            <ProductListController setFilters={setFilters} filters={filters} />
            {(user?.data?.role === USER_ROLES.WEHEAR_ADMIN || user?.data?.role === USER_ROLES.SUPER_ADMIN) && <ScpListController filters={filters} role={USER_ROLES.SALES_CHANNEL_PARTNER} />}
            {((user?.data?.role === USER_ROLES.WEHEAR_ADMIN || user?.data?.role === USER_ROLES.SUPER_ADMIN || user?.data?.role === USER_ROLES.SALES_CHANNEL_PARTNER)) && <FpListController filters={filters} role={USER_ROLES.FRANCHISE_PARTNER} />}
        </Box>
    );
};
export default DashboardMain;