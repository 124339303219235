import React, { useEffect, useState } from "react";
import CustomerInformation from "./CustomerInformation";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { callApiAction } from "../../../store/actions/commonAction";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadAudiogramApi,
  getCustomerByIdApi,
  updateCustomerApi,
  ini,
} from "../../../apis/customer.api";
import { Box } from "@mui/material";
import loadingImg from "../../../assets/images/loading.gif";
import { closeModal, openModal } from "../../../store/actions/modalAction";
import { CUSTOMER_TYPE, SNACK_BAR_VARIETNS } from "../../../utils/constants";
import TrialProductController from "../CustomerCreate/TrialController/TrialProductController";
import PurchasedProductController from "../CustomerCreate/PurchasedController/PurchasedProductController";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import MessageDilog from "../../../components/MessageDilog";
import { useNavigate } from "react-router";
import fileDownload from "js-file-download";
import { initiateRefundApi } from "../../../apis/payment.api";
import FeedBackViewUi from "../../Feedback/FeedbackView/FeedBackViewUi";

const CustomerInformationController = ({ id, callBack = () => {} }) => {
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const returnTrialFun = (e) => {
    e.preventDefault();
    dispatch(
      callApiAction(
        async () =>
          await updateCustomerApi({
            id: params?._id,
            customer_type: CUSTOMER_TYPE.RETURN,
            sold_details: {
              box_id: params.trial_details.box_id,
            },
          }),
        () => {
          dispatch(closeModal("return-product"));
          fetchDetails();
          callBack();
          dispatch(callSnackBar("Return", SNACK_BAR_VARIETNS.suceess));
        },
        (err) => {
          dispatch(closeModal("return-product"));
        }
      )
    );
  };

  const initiateRefundFun = (e) => {
    e.preventDefault();
    dispatch(
      callApiAction(
        async () =>
          await initiateRefundApi({
            customer_id: params?._id,
          }),
        () => {
          dispatch(closeModal("initiate-refund-product"));
          fetchDetails();
          callBack();
          dispatch(
            callSnackBar("Refund Initiated", SNACK_BAR_VARIETNS.suceess)
          );
        },
        (err) => {
          dispatch(closeModal("initiate-refund-product"));
        }
      )
    );
  };

  const onTrialReturn = (e) => {
    e.preventDefault();
    dispatch(
      openModal(
        <MessageDilog
          title="Take Return"
          message="Check and confirm the device's condition and functioning, then click Okay to proceed with the device return."
          onSubmit={returnTrialFun}
          modalId="return-product"
        />,
        "md",
        true,
        "return-product"
      )
    );
  };

  const onInitiateRefund = (e) => {
    e.preventDefault();
    dispatch(
      openModal(
        <MessageDilog
          title="Initiate Refund"
          message="Are you sure you want to initiate refund?"
          onSubmit={initiateRefundFun}
          modalId="initiate-refund-product"
        />,
        "md",
        true,
        "initiate-refund-product"
      )
    );
  };
  const onEditAudiogram = (e) => {
    e.preventDefault();
    dispatch(closeModal("CustomerInformation"));
    navigate("/customer/create", { state: { currentstep: 1, id } });
  };

  const purchaseFun = () => {
    dispatch(
      openModal(
        <PurchasedProductController
          params={params}
          id={params._id}
          updateValue={2}
          callBack={fetchDetails}
        />,
        "md",
        true,
        "purchased-product"
      )
    );
  };

  const trialFun = () => {
    dispatch(
      openModal(
        <TrialProductController
          callBack={fetchDetails}
          id={params._id}
          updateValue={2}
        />,
        "md",
        true,
        "trial-product"
      )
    );
  };

  const fetchDetails = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getCustomerByIdApi({ id }),
        (response) => {
          setParams(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) {
      fetchDetails();
    }
  }, [id]);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const onDownloadClick = () => {
    setDownloadLoading(true);
    console.log("onDownloadClick triggered, starting API call...");

    dispatch(
      callApiAction(
        async () => {
          const response = await downloadAudiogramApi({ id: params?._id });
          return response;
        },
        (response) => {
          let pdfBlob =
            response instanceof Blob
              ? response
              : new Blob([response], { type: "application/pdf" });
          const reader = new FileReader();
          reader.onloadend = () => {};
          reader.readAsText(pdfBlob);
          fileDownload(
            pdfBlob,
            `${params.first_name} ${params.last_name}'s audiogram.pdf`
          );
          setDownloadLoading(false);
        },
        (err) => {
          dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
          setDownloadLoading(false);
        },
        true
      )
    );
  };

  const onFeedBackView = (data, fd1, fd2, fd3) => {
    dispatch(
      openModal(
        <FeedBackViewUi fields={data} fb1={fd1} fb2={fd2} fb3={fd3} />,
        "md",
        false,
        "feedback-view"
      )
    );
  };

  return (
    <CustomDialog id={"CustomerInformation"} title={`Customer Information`}>
      {!loading ? (
        <>
          <CustomerInformation
            onDownloadClick={onDownloadClick}
            params={params}
            trialFun={trialFun}
            purchaseFun={purchaseFun}
            onTrialReturn={onTrialReturn}
            onInitiateRefund={onInitiateRefund}
            downloadLoading={downloadLoading}
            onEditAudiogram={onEditAudiogram}
            onFeedBackView={onFeedBackView}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={loadingImg} alt="Loading ..." />
        </Box>
      )}
    </CustomDialog>
  );
};

export default CustomerInformationController;
