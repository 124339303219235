import { CircularProgress } from "@mui/material";
import { memo } from "react";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import CustomInput from "../../components/inputs/CustomInputs";

const UpdateIncentivePercentageUi = ({
  title,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${title}`}
        closeText="Close"
        confirmText={"Update"}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <CustomInput
              disabled={loading}
              value={fields.scp_percentage}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: "",
                  scp_percentage: e.target.value,
                })
              }
              type="Number"
              label={"SCP Percentage"}
            />
            <CustomInput
              disabled={loading}
              value={fields.fp_percentage}
              onChange={(e) =>
                setFields({ ...fields, err: "", fp_percentage: e.target.value })
              }
              type="Number"
              label={"FP Percentage"}
            />
            <CustomInput
              disabled={loading}
              value={fields.sales_staff_percentage}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: "",
                  sales_staff_percentage: e.target.value,
                })
              }
              type="Number"
              label={"Sales staff Percentage"}
            />
            <CustomInput
              disabled={loading}
              value={fields.crm_incentive_percentage}
              onChange={(e) =>
                setFields({
                  ...fields,
                  err: "",
                  crm_incentive_percentage: e.target.value,
                })
              }
              type="Number"
              label={"Crm Incentive Percentage"}
            />
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(UpdateIncentivePercentageUi);
