import React, { useState } from "react";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { closeModal } from "../../../store/actions/modalAction";
import { useDispatch } from "react-redux";
import {
  FEEDBACK_STATUS,
  FEEDBACK_TYPE,
  ORDER_PROCESS_STATUS,
  SOLUTION_STATUS,
} from "../../../utils/constants";
import { callApiAction } from "../../../store/actions/commonAction";
import FeedbackUpdateUi from "./FeedbackUpdateUi";
import { updateFeedBackDataApi } from "../../../apis/feedback.api";
import { useEffect } from "react";
import FeedBack3Ui from "./FeedBack3Ui";
import useValidate from "../../../store/hooks/useValidator";
import { Box } from "@mui/material";

const FeedBack3Controller = ({ callBack = () => {}, fb3, lastCompleted }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const validate = useValidate();
  const [error, setError] = useState("");
  const [fields, setFields] = useState({
    q31: {
      ans: "",
      problems: [],
    },
    q32: {
      ans: "",
      problems: [],
    },
    q33: {
      ans: "",
      problems: [],
    },
    q34: {
      ans: "",
      problems: [],
    },
    q35: {
      ans: "",
      problems: [],
    },
    q36: {
      ans: "",
      problems: [],
    },
    q37: {
      ans: "",
      problems: [],
    },
    q38: {
      ans: "",
      problems: [],
    },
    q39: {
      ans: "",
      problems: [],
    },
    q40: {
      ans: "",
      problems: [],
    },
    q41: {
      ans: "",
      problems: [],
    },
    q42: {
      ans: "",
      problems: [],
    },
    q43: {
      ans: "",
      problems: [],
    },
    q44: {
      ans: "",
      problems: [],
    },
    q45: {
      ans: "",
      problems: [],
    },
    q46: {
      ans: "",
      problems: [],
    },
    q47: {
      ans: "",
      problems: [],
    },
    q48: {
      ans: "",
      problems: [],
    },
  });
  const validation = () => {
    let i = 0;
    Object.keys(fields).map((key, index) => {
      if (fields[key].ans.length > 0) {
        i++;
      }

      console.log(
        "this is data regarding the question ans",
        fields[key].ans,
        i
      );
    });

    if (i >= 5) {
      return true;
    }
    return false;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // setLoading(true);

    console.log("this is fields", fields);
    const validationResponse = validation();

    const cleanedFields = Object.keys(fields).reduce((acc, key) => {
      acc[key] = {
        ans: fields[key].ans,
        problems: fields[key].problems?.filter((problem) => problem !== ""),
      };
      return acc;
    }, {});

    if (validationResponse === true) {
      dispatch(
        callApiAction(
          async () =>
            await updateFeedBackDataApi({
              id: fb3,
              feedback_type: FEEDBACK_TYPE["FEEDBACK 3"],
              ...cleanedFields,
            }),
          (response) => {
            callBack();
            dispatch(closeModal("updateFeedback"));
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        )
      );
    } else {
      setError(
        "At least 5 question answers are required before submitting the feedback."
      );
      setLoading(false);
    }
  };

  return (
    <CustomDialog
      id={"updateFeedback"}
      loading={loading}
      onSubmit={onSubmit}
      title={"Add Feedback 3"}
      closeText="Close"
      confirmText={"Submit"}
    >
      <Box height="70vh" width="100%">
        <FeedBack3Ui
          onSubmit={onSubmit}
          error={error}
          fields={fields}
          setFields={setFields}
          lastCompleted={lastCompleted}
        />
      </Box>
    </CustomDialog>
  );
};

export default FeedBack3Controller;
