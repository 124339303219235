import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import { USER_ROLES } from "../../../utils/constants";
import { findObjectKeyByValue } from "../../../utils/main";

const StaffListUi = ({ dataList, role, loading, step, reiList }) => {

  let headers;

  if (step === USER_ROLES.CRM) {
    headers = [
      "Name",
      "Created",
      "UnAssigned",
      "Assigned",
    ];
  } else {
    headers = [
      "Name",
      "Assigned",
      "Connected",
      "Trial",
      "Converted",
      "Expense"
    ];
  }

  const findExpenseByEmail = (email) => {
    console.log("this si reiList", reiList);
    console.log("this si email", email);
    const expense = reiList.find((item) => item.email == email);
    if (expense) {
      return expense.total;
    }
    return 0;
  }


  return (
    <Box p={4}>
      <TableContainer
        sx={{
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
          borderBottomRightRadius: dataList.length > 0 && "5px",
          borderBottomLeftRadius: dataList.length > 0 && "5px",
          maxHeight: 300,
          overflowY: "auto",
          border: dataList.length > 0 && "1px solid rgb(129, 166, 222)",
          "&::-webkit-scrollbar": {
            width: 4,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#1B4381",
            borderRadius: 2,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#1B4381" }}>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  align="left"
                  sx={{
                    backgroundColor: "#1B4381",
                    color: "#fff",
                    fontWeight: "bold",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <Box>
                    <Typography variant="body" fontWeight={"bold"}>
                      {header}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              dataList.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    {row?.name ?? "NA"}
                  </TableCell>
                  {step == USER_ROLES.CRM && <>
                    <TableCell align="left">{row?.lead?.all ?? 0}</TableCell>
                    <TableCell align="left">{row?.lead?.unassigned ?? 0}</TableCell>
                    <TableCell align="left">{row?.lead?.assigned ?? 0}</TableCell></>}
                  {step == USER_ROLES.SALES_STAFF && <><TableCell align="left">{row?.lead?.assigned ?? 0}</TableCell><TableCell align="left">{row?.lead?.connected ?? 0}</TableCell>
                    <TableCell align="left">{row?.lead?.trial ?? 0}</TableCell>
                    <TableCell align="left">{row?.lead?.converted ?? 0}</TableCell>
                    <TableCell align="left">{findExpenseByEmail(row?.email) ?? 0}</TableCell>
                  </>}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {
        !loading && dataList.length < 1 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "-1px",
              width: "100%",
              height: "10vh",
              border: "1px solid rgb(129, 166, 222)",
              borderBottomRightRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
          >
            <Typography color="rgb(32, 65, 115)" fontWeight={"bold"}>
              {`No ${findObjectKeyByValue(role, USER_ROLES)} Found.`}
            </Typography>
          </Box>
        )
      }
      {
        loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "-1px",
              width: "100%",
              height: "10vh",
              border: "1px solid rgb(129, 166, 222)",
              borderBottomRightRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
          >
            <Typography color="rgb(32, 65, 115)" fontWeight={"bold"}>
              Loading...
            </Typography>
          </Box>
        )
      }
    </Box >
  );
};

export default StaffListUi;
