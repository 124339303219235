import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import {
  Add,
  BugReport,
  Delete,
  RemoveCircle,
  Star,
} from "@mui/icons-material";
import { SOLUTION_STATUS } from "../../../utils/constants";
import FileInput from "../../../components/upload/FileInput";
import { callApiAction } from "../../../store/actions/commonAction";
import { fetchFeedbackProblemsApi } from "../../../apis/feedback.api";
import ProblemSummaryDetailsView from "./ProblemSummaryDetailsView";
import { useDispatch } from "react-redux";
import { openModal } from "../../../store/actions/modalAction";

const ProblemField = ({ fields, setFields, index, qNum }) => {
  console.log("fields", fields);
  return (
    <OutlinedInput
      value={fields[qNum]?.problems[index]?.problem}
      fullWidth
      onChange={(e) =>
        setFields((prev) => {
          let temp = [...prev[qNum]?.problems];
          temp[index] = { ...temp[index], problem: e.target.value };
          return { ...prev, [qNum]: { ...prev[qNum], problems: temp } };
        })
      }
      id="outlined-adornment-weight"
      endAdornment={
        <InputAdornment position="end">
          {" "}
          <Tooltip title="Remove Problem">
            <RemoveCircle
              sx={{ cursor: "pointer" }}
              onClick={(e) =>
                setFields((prev) => {
                  let temp = [...prev[qNum]?.problems];
                  temp.splice(index, 1);
                  return { ...prev, [qNum]: { ...prev[qNum], problems: temp } };
                })
              }
              color="error"
            />
          </Tooltip>
        </InputAdornment>
      }
      aria-describedby="outlined-weight-helper-text"
      inputProps={{
        "aria-label": "weight",
      }}
    />
  );
};
const ProblemComponent = ({ fields, setFields, qNum }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Button
        variant="outlined"
        sx={{ backgroundColor: "primary.main", color: "white" }}
        startIcon={<Add />}
        onClick={() => {
          setFields({
            ...fields,
            [qNum]: {
              ...fields[qNum],
              problems: [
                ...fields[qNum].problems,
                { problem: "", status: SOLUTION_STATUS.PENDING },
              ],
            },
          });
        }}
      >
        <Typography>Add Problem</Typography>
      </Button>
    </Box>
  );
};

const FeedBack3Ui = ({ fields, setFields, error, lastCompleted }) => {
  const dispatch = useDispatch();

  const [isProblem, setIsProblem] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchFeedbackProblems = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchFeedbackProblemsApi({ feedback_id: lastCompleted }),
        (response) => {
          setIsProblem(false);
          for (let i = 16; i <= 30; i++) {
            if (response[`q${i}`]?.problems?.length > 0) {
              const isNotSolvedProblem = response[`q${i}`]?.problems?.some(
                (item) => item.status == SOLUTION_STATUS.PENDING
              );
              if (isNotSolvedProblem) {
                setIsProblem(true);
                break;
              }
            }
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (lastCompleted) {
      fetchFeedbackProblems();
    }
  }, [lastCompleted]);

  const problemSummary = () => {
    dispatch(
      openModal(
        <ProblemSummaryDetailsView
          callBack={fetchFeedbackProblems}
          id={lastCompleted}
        />,
        "md",
        false,
        "problem-views"
      )
    );
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        {!isProblem && <Typography color="error">{error}</Typography>}
        {isProblem && (
          <Alert variant="outlined" severity="error">
            Resolve Feedback 2 issue before taking Feedback 3.
          </Alert>
        )}
        <Button variant="outlined" onClick={problemSummary}>
          <Typography variant="body1">FB2 Summary</Typography>
        </Button>
      </Box>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "50px",
            justifyContent: "space-between",
          }}
        >
          <FormLabel color="primary" id="demo-row-radio-buttons-group-label">
            1.Are you wearing the device regularly?
          </FormLabel>
          {fields?.q31.ans == "no" && (
            <ProblemComponent
              qNum={"q31"}
              fields={fields}
              setFields={setFields}
            />
          )}
        </FormControl>
        <RadioGroup
          defaultValue={"no"}
          onChange={(e, newVal) => {
            setFields({ ...fields, q31: { ...fields?.q31, ans: newVal } });
          }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
        <Box width={"100%"}>
          {fields["q31"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q31"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>2.How is the hearing experience?</Typography>
          <ProblemComponent
            qNum={"q32"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q32: { ...fields?.q32, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q32"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q32"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            3.Has your frequency of using bluetooth and app increased?
          </Typography>
          <ProblemComponent
            qNum={"q33"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q33: { ...fields?.q33, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q33"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q33"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            4.What new features have you used via bluetooth?
          </Typography>
          <ProblemComponent
            qNum={"q34"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q34: { ...fields?.q34, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q34"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q34"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            5.What's something new you have watched recently using streaming?
          </Typography>
          <ProblemComponent
            qNum={"q35"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q35: { ...fields?.q35, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q35"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q35"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>6.Does the device sit well around your ear?</Typography>
          <ProblemComponent
            qNum={"q36"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q36: { ...fields?.q36, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q36"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q36"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>7.Does the device stay in place?</Typography>
          <ProblemComponent
            qNum={"q37"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q37: { ...fields?.q37, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q37"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q37"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>8.How is the feel of the device?</Typography>
          <ProblemComponent
            qNum={"q38"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q38: { ...fields?.q38, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q38"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q38"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "50px",
          }}
        >
          <FormLabel color="primary" id="demo-row-radio-buttons-group-label">
            9.How is the feel of the button/ touch ? What functions have you
            tried using?
          </FormLabel>
          <ProblemComponent
            qNum={"q39"}
            fields={fields}
            setFields={setFields}
          />
        </FormControl>
        <RadioGroup
          row
          onChange={(e, newVal) => {
            setFields({ ...fields, q39: { ...fields?.q39, ans: newVal } });
          }}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="yes " control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
        <Box width={"100%"}>
          {fields["q39"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q39"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            10.How often do you place it on charge during the day/week?
          </Typography>
          <ProblemComponent
            qNum={"q40"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q40: { ...fields?.q40, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q40"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q40"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>11.Are the indications clear enough?</Typography>
          <ProblemComponent
            qNum={"q41"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q41: { ...fields?.q41, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q41"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q41"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      {/* Other */}
      <Box
        mb={4}
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #c4c4c4",
          borderRadius: "4px",
        }}
      >
        <Box width={"100%"}>
          <Box
            width={"100%"}
            sx={{
              // ml: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              12.What would you rate the audio experience from 1- 5?
            </Typography>
            <Rating
              onChange={(event, newValue) =>
                setFields({
                  ...fields,
                  q41: {
                    ...fields.q41,
                    ans: newValue,
                  },
                })
              }
              name="text-feedback"
              value={fields.q41.ans}
              sx={{ gap: 2 }}
              precision={0.5}
              icon={<Star style={{ opacity: 1 }} fontSize="large" />}
              emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="large" />}
            />
          </Box>
          <Divider sx={{ backgroundColor: "#e2e2e2" }} />
        </Box>
        <Box width={"100%"}>
          <Box
            width={"100%"}
            sx={{
              // ml: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              13.What would you rate the blue tooth and functionality from 1 -
              5?
            </Typography>
            <Rating
              onChange={(event, newValue) =>
                setFields({
                  ...fields,
                  q42: {
                    ...fields.q42,
                    ans: newValue,
                  },
                })
              }
              name="text-feedback"
              value={fields.q42.ans}
              sx={{ gap: 2 }}
              precision={0.5}
              icon={<Star style={{ opacity: 1 }} fontSize="large" />}
              emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="large" />}
            />
          </Box>
          <Divider sx={{ backgroundColor: "#e2e2e2" }} />
        </Box>{" "}
        <Box width={"100%"}>
          <Box
            width={"100%"}
            sx={{
              // ml: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              14.What would you rate the overall comfort of the device from 1- 5
            </Typography>
            <Rating
              name="text-feedback"
              onChange={(event, newValue) =>
                setFields({
                  ...fields,
                  q43: {
                    ...fields.q43,
                    ans: newValue,
                  },
                })
              }
              value={fields.q43.ans}
              sx={{ gap: 2 }}
              precision={0.5}
              icon={<Star style={{ opacity: 1 }} fontSize="large" />}
              emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="large" />}
            />
          </Box>
          <Divider sx={{ backgroundColor: "#e2e2e2" }} />
        </Box>{" "}
        <Box width={"100%"}>
          <Box
            width={"100%"}
            sx={{
              // ml: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              15.What would you rate the overall working and charging of the
              device from 1 - 5?
            </Typography>
            <Rating
              onChange={(event, newValue) =>
                setFields({
                  ...fields,
                  q44: {
                    ...fields.q44,
                    ans: newValue,
                  },
                })
              }
              name="text-feedback"
              value={fields.q44.ans}
              sx={{ gap: 2 }}
              precision={0.5}
              icon={<Star style={{ opacity: 1 }} fontSize="large" />}
              emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="large" />}
            />
          </Box>
        </Box>{" "}
        <Box width={"100%"}>
          <Box
            width={"100%"}
            sx={{
              // ml: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              16.What would you rate the overall experience from 1 - 5?
            </Typography>
            <Rating
              onChange={(event, newValue) =>
                setFields({
                  ...fields,
                  q45: {
                    ...fields.q45,
                    ans: newValue,
                  },
                })
              }
              name="text-feedback"
              value={fields.q45.ans}
              sx={{ gap: 2 }}
              precision={0.5}
              icon={<Star style={{ opacity: 1 }} fontSize="large" />}
              emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="large" />}
            />
          </Box>
        </Box>{" "}
      </Box>
      {/* //IF - using another device before */}
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            17.What difference do you feel in the device you were using before
            and this one?
          </Typography>
          <ProblemComponent
            qNum={"q46"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q46: { ...fields?.q46, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q46"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q46"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <Typography>
            18.If you had to compare how much would you rate our device and your
            old device?
          </Typography>
          <ProblemComponent
            qNum={"q47"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q47: { ...fields?.q47, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q47"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q47"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <FileInput
        mb={2}
        onChange={(e) => {
          setFields({
            ...fields,
            q48: { ...fields?.q48, ans: e },
          });
        }}
        defaults={fields?.q48?.ans ? [fields?.q48?.ans] : []}
        accept="video/*"
        title="Upload Video"
        subTitle="Only Video files are allowed! less than 1 MB"
      />
    </div>
  );
};

export default FeedBack3Ui;
