import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { callApiAction } from "../../store/actions/commonAction";
import { Delete, Edit } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import MessageDilog from "../../components/texts/MessageDilog";
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants";
import ProductListUi from "./ProductListUi";
import { deleteProductApi, fetchProductApi } from "../../apis/product.api";
import CreateProductController from "./CreateProductController";
import {
  deleteProductAction,
  fetchProductAction,
} from "../../store/actions/setting.Action";
import { callSnackBar } from "../../store/actions/snackbarAction";

const ActionComponent = memo(({ params, setParams }) => {
  const dispatch = useDispatch();
  const modalkey = "productupdate";
  const [loading, setLoading] = useState(false);
  const { settings } = useSelector((state) => state);
  const user = useSelector((state) => state.user);

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateProductController id={params._id} />,
        "sm",
        false,
        modalkey
      )
    );
  };

  const deleteFun = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteProductApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
          dispatch(
            callSnackBar(
              params.product_name + " Deleted Successfully",
              SNACK_BAR_VARIETNS.suceess
            )
          );
          dispatch(closeModal("productidd"));
          // const updateData = settings.rawMaterial_data.filters(item => item._id != params._id)
          // dispatch(deleteProductAction(updateData, settings.rawMaterial_filters))
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const onDelete = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          modalId="productidd"
          message={`Are you sure to delete "${
            params.product_name || params.title
          }" ?`}
        />,
        "sm",
        false,
        "productidd"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      {user.data.role === USER_ROLES.WEHEAR_ADMIN ||
      user.data.role === USER_ROLES.SUPER_ADMIN ? (
        <IconButton size="inherit" onClick={onEdit}>
          <Edit color="info" fontSize="inherit" />
        </IconButton>
      ) : (
        ""
      )}
      {user.data.role === USER_ROLES.WEHEAR_ADMIN ||
      user.data.role === USER_ROLES.SUPER_ADMIN ? (
        <IconButton disabled={loading} size="inherit" onClick={onDelete}>
          <Delete color="error" fontSize="inherit" />
        </IconButton>
      ) : (
        ""
      )}
    </Box>
  );
});

const ProductListController = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { settings } = useSelector((state) => state);
  const title = "Products";
  const fetchApi = fetchProductApi;
  const deleteApi = deleteProductApi;
  const [categoryType, setCategoryType] = useState("production");

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "product_name",
        label: "Product Name",
        align: "left",
        sort: true,
      },
      {
        id: 2,
        fieldName: "product_code",
        label: "Product Code",
        align: "left",
        sort: true,
      },
      {
        id: 2,
        fieldName: "product_type",
        label: "Product Type",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =>
          params?.product_type?.map((e) => e?.name),
      },
      {
        id: 2,
        fieldName: "product_color",
        label: "Product color",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =>
          params?.product_color?.map((e) => e?.name),
      },

      {
        id: 4,
        fieldName: "mrp",
        label: "Product Price",
        align: "left",
        sort: true,
        //renderValue: (params, setParams) => params.mrp,
      },

      // {
      //     id: 6,
      //     fieldName: "categoryId",
      //     label: "Category",
      //     align: "left",
      //     sort: true,
      //     renderValue: (params, setParams) => params.categoryId.map((e) => e.name)
      // },

      {
        id: 8,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent params={params} setParams={setParams} />
        ),
      },
    ],
    []
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["product_name"],
    sort: "createdAt",
    sortDirection: -1,
    // category:0,
  });
  //   const handleLeadTypeChange = (newCategoryType) => {
  //     setCategoryType(newCategoryType);
  //     setFilters(prevFilters => ({
  //         ...prevFilters,
  //         category: newCategoryType === 'production' ? LOG_TYPE.In : LOG_TYPE.Out,
  //     }));
  // };

  const getProductList = () => {
    if (
      !settings.product_data ||
      settings.product_data.length === 0 ||
      JSON.stringify(filters) != JSON.stringify(settings.product_filters)
    ) {
      dispatch(fetchProductAction(filters));
    }
  };

  useEffect(() => {
    getProductList();
  }, [filters]);

  return (
    <>
      <ProductListUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={settings.product_loading}
        list={settings.product_data}
        columns={columns}
        categoryType={categoryType}
        setCategoryType={setCategoryType}
      />
    </>
  );
};
export default ProductListController;
