import { Box, Skeleton } from "@mui/material";
import ButtonComponentsUi from "../../../components/button/ButtonComponentsUi";
import { FilterTitleBox } from "../../../components/layouts/OneViewBox";
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxDesktop";
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector";
import AsyncSearchBar from "../../../components/inputs/AsyncSearchBar";
import { Paper } from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router";


const CountListUi = ({ data, loading }) => {

    const navigate = useNavigate();

    return (

        <Box p={4}>
            <FilterTitleBox>
                <Typography variant="h3" color={"primary"}>
                    Dashboard
                </Typography>
            </FilterTitleBox>

            <Box>
                {loading ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            height: "15vh",

                        }} mt={4}
                    >
                        {[0, 1, 2, 3]?.map((row) => (
                            <Skeleton
                                key={row}
                                variant="rectangular"
                                width={"24%"}
                                height={120}
                                sx={{
                                    borderRadius: "8px",
                                    boxShadow:
                                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                }}
                            />
                        ))}
                    </Box>
                ) : (
                    <Box
                        mt={4}
                        gap={2}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <ButtonComponentsUi
                            count={data?.value_of_sales}
                            loading={false}
                            onSubmit={() => { navigate("/sales") }}
                            Title={"Value Of Sales"}
                            width="25%"
                        />
                        <ButtonComponentsUi
                            count={data?.total_incentive}
                            loading={false}
                            onSubmit={() => { navigate("/incentive") }}
                            Title={"Total Incentive"} width="25%"
                        />  <ButtonComponentsUi
                            count={data?.total_customers}
                            loading={false}
                            onSubmit={() => { navigate("/customer") }}
                            Title={"Customers"} width="25%"
                        />  <ButtonComponentsUi
                            count={data?.total_trials}
                            loading={false}
                            onSubmit={() => { navigate("/trials") }}
                            Title={"Trials"} width="25%"
                        />
                    </Box>
                )}
            </Box>
            {/* <Box sx={{ mt: 4 }}>
                <FilterTitleBox>
                    <PaddingBoxInDesktop
                        sx={{
                            display: "flex",
                            width: "30vw",
                            flexDirection: "flex-start",
                        }}
                    >
                        <TimeRangeSelector
                            defaultVal={{ label: "All", _id: null }}
                            onChange={(val) => setFilters({ ...filters, ...val })}
                        />
                    </PaddingBoxInDesktop>

                    <PaddingBoxInDesktop p={2} sx={{ display: "flex", width: "20vw" }}>
                        <AsyncSearchBar
                            fullWidth
                            title="Search Name | Email"
                            size="small"
                            placeholder={"Search Name | Email"}
                            defaultValue={filters?.search}
                            onChange={(changedVal) => {
                                setFilters({
                                    ...filters,
                                    pageNo: 1,
                                    pageSize: 10,
                                    search: changedVal,
                                });
                            }}
                        />
                    </PaddingBoxInDesktop>
                </FilterTitleBox>
            </Box> */}
        </Box>


    );
};
export default CountListUi;