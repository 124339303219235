import React, { memo } from 'react'
import { Box, Grid, Typography } from '@mui/material';
import CustomDialog from '../../../../components/layouts/common/CustomDialog';

const TrialProductInformationUi = ({ res }) => {
    const DetailsBox = memo(({ title, data = [{ title: "", value: "" }] }) => {
        return (
            <Box
                sx={{
                    width: "100%",
                    borderRadius: "8px",
                    border: "1px solid #1B4381",
                    overflow: "hidden",
                    backgroundColor: "#FFFFFF"
                }}
            >
                {title && <Box
                    p={2}
                    sx={{
                        backgroundColor: "#F5F5F5",
                        borderBottom: "1px solid #D3D3D3",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{ color: "#333333", fontWeight: "bold", textAlign: "center" }}
                    >
                        {title}
                    </Typography>
                </Box>}
                <Grid container p={2} spacing={2}>
                    {data.map((item, index) => (
                        <React.Fragment key={index}>
                            <Grid item mt={1} xs={6} sm={6} md={4}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: "bold", color: "#555555" }}
                                >
                                    {item?.title} :
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={8}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        color: "#777777",
                                        textAlign: "left",
                                        wordWrap: "break-word",
                                    }}
                                >
                                    {item?.value}
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </Box>
        );
    });
    return (
        <CustomDialog id={"Information"} title={"Device Information"}>
            <Box sx={{ padding: 2 }}>
                <DetailsBox
                    // title="Device Information"
                    data={[
                        {
                            title: "Product",
                            value: res?.product?.product_name ?? "NA",
                        },
                        {
                            title: "Box Id",
                            value: res?.box_id ?? "NA",
                        },
                        {
                            title: "Color",
                            value: res?.color?.name ?? "NA",
                        },
                        {
                            title: "Amount",
                            value: res?.product?.mrp ?? "NA",
                        },
                        {
                            title: "Code",
                            value: res?.product?.product_code ?? "NA",
                        },
                    ]}
                />
            </Box>
        </CustomDialog>
    )
}

export default TrialProductInformationUi