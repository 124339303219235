import { Grid2, Paper, Skeleton, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { memo } from "react";
import { findObjectKeyByValue, toTitleCase } from "../../utils/main";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import { DATE_TIME_FILTERS, FEEDBACK_TYPE } from "../../utils/constants";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import DataTable from "../../components/tables/DataTable";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import ButtonComponentsUi from "../../components/button/ButtonComponentsUi";
import OrderComponentsUi from "../../components/button/OrderComponentsUi";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
}));

const FeedbackListUi = ({ filters, setFilters, loading, columns, selectOrderType, list, setSelectOrderType, countLoading, count }) => {
  console.log("first selectOrderType", selectOrderType)
  return (
    <Paper elevation={0} sx={{ width: "100%", minHeight: "100%", padding: 3 }}>
      <Box>
        <FilterTitleBox>
          <Typography variant="h3" color={"primary"}>
            Feedback
          </Typography>
        </FilterTitleBox>
      </Box>
      {countLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            height: "15vh",
          }}
        >
          {[0, 1, 2, 3]?.map((row) => (
            <Skeleton
              key={row}
              variant="rectangular"
              width={"24%"}
              height={120}
              sx={{
                borderRadius: "8px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            />
          ))}
        </Box>
      ) :
        (<Grid2
          container
          mt={4}
          pl={2}
          size={12}
          spacing={2}
        >
          <Grid2
            display={"flex"}
            mb={2}
            flexWrap={"wrap"}
            size={3}
          >
            <OrderComponentsUi
              width="100%"
              count={count?.total_customer ?? 0}
              onSubmit={() => setSelectOrderType(0)}
              loading={loading}
              colorType={selectOrderType === 0}
              Title={"Customers"}
            />
          </Grid2>
          <Grid2
            display={"flex"}
            mb={2}
            flexWrap={"wrap"}
            size={3}
          >
            <OrderComponentsUi
              width="100%"
              count={count?.fd1 ?? 0}
              onSubmit={() => setSelectOrderType(FEEDBACK_TYPE["FEEDBACK 1"])}
              loading={loading}
              colorType={selectOrderType === FEEDBACK_TYPE["FEEDBACK 1"]}
              Title={"Feedback 1"}
            />
          </Grid2>
          <Grid2
            display={"flex"}
            mb={2}
            flexWrap={"wrap"}
            size={3}
          >
            <OrderComponentsUi
              width="100%"
              count={count?.fd2 ?? 0}
              onSubmit={() => setSelectOrderType(FEEDBACK_TYPE["FEEDBACK 2"])}
              loading={loading}
              colorType={selectOrderType === FEEDBACK_TYPE["FEEDBACK 2"]}
              Title={"Feedback 2"}
            />
          </Grid2>
          <Grid2
            display={"flex"}
            mb={2}
            flexWrap={"wrap"}
            size={3}
          >
            <OrderComponentsUi
              width="100%"
              count={count?.fd3 ?? 0}
              onSubmit={() => setSelectOrderType(FEEDBACK_TYPE["FEEDBACK 3"])}
              loading={loading}
              colorType={selectOrderType === FEEDBACK_TYPE["FEEDBACK 3"]}
              Title={"Feedback 3"}
            />
          </Grid2>
        </Grid2>
        )}

      <Box sx={{ mt: 4 }}>
        <FilterTitleBox>
          <PaddingBoxInDesktop
            sx={{
              display: "flex",
              width: "30vw",
              flexDirection: "flex-start",
            }}
          >
            <TimeRangeSelector
              defaultVal={{
                label: "Lifetime",
                _id: findObjectKeyByValue(
                  DATE_TIME_FILTERS.today,
                  DATE_TIME_FILTERS
                ),
              }}
              onChange={(val) => setFilters({ ...filters, ...val })}
            />
          </PaddingBoxInDesktop>

          <PaddingBoxInDesktop p={2} sx={{ display: "flex", width: "20vw" }}>
            <AsyncSearchBar
              fullWidth
              title="Search Name | Email"
              size="small"
              placeholder={"Search Name | Email"}
              defaultValue={filters?.search}
              onChange={(changedVal) =>
                setFilters({
                  ...filters,
                  pageNo: 1,
                  pageSize: 10,
                  search: changedVal,
                })
              }
            />
          </PaddingBoxInDesktop>
        </FilterTitleBox>

        <Box mt={2}>
          <DataTable
            key={JSON.stringify(list)}
            columns={columns}
            rows={list?.result ? list.result : []}
            count={list?.total ?? 0}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
          />
        </Box>
      </Box>
    </Paper>
  );
};
export default memo(FeedbackListUi);
