import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid2,
  Paper,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import { Box, useMediaQuery } from "@mui/system";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { memo, useState } from "react";
import ButtonComponentsUi from "../../components/button/ButtonComponentsUi";
import { findObjectKeyByValue, toTitleCase } from "../../utils/main";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import {
  CUSTOMER_TYPE,
  DATE_TIME_FILTERS,
  ORDER_STATUS,
} from "../../utils/constants";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import DataTable from "../../components/tables/DataTable";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import DownloadIcon from '@mui/icons-material/Download';
import { ImportExport } from "@mui/icons-material";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
}));

const SalesListUi = ({
  title,
  filters,
  setFilters,
  loading,
  setSelectSalesType,
  selectSalesType,
  count,
  countLoading,
  isAccordion,
  columns,
  list,
  salesValue,
  salesValueLoading,
  onDownloadCsv,
  excelloading
}) => {
  const user = useSelector((state) => state.user);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <Paper elevation={0} sx={{ width: "100%", minHeight: "100%", padding: 3 }}>
      <Box>
        <FilterTitleBox>
          <Typography variant="h3" color={"primary"}>
            Sales
          </Typography>
        </FilterTitleBox>

        <Box mt={4}>
          {countLoading || salesValueLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                height: "15vh",
              }}
              mt={4}
            >
              {[0, 1, 2, 3]?.map((row) => (
                <Skeleton
                  key={row}
                  variant="rectangular"
                  width={"24%"}
                  height={120}
                  sx={{
                    borderRadius: "8px",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                />
              ))}
            </Box>
          ) : (
            <Box
              mt={4}
              gap={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                height: isSmallScreen ? "10vh" : "15vh",
                width: "100%",
                alignItems: "center",
              }}
            >
              <ButtonComponentsUi
                count={salesValue}
                loading={loading}
                Title={"Sales Value"}
              />
              {count?.map((item) => (
                <ButtonComponentsUi
                  width="25%"
                  count={item?.count ?? 0}
                  onSubmit={() => setSelectSalesType(item?.order_status)}
                  loading={loading}
                  colorType={selectSalesType === item?.order_status}
                  Title={toTitleCase(
                    findObjectKeyByValue(item?.order_status, ORDER_STATUS)
                  )}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ mt: 4 }}>
        <FilterTitleBox>
          <PaddingBoxInDesktop
            sx={{
              display: "flex",
              width: "30vw",
              flexDirection: "flex-start",
            }}
          >
            <TimeRangeSelector
              defaultVal={{ label: "Lifetime", _id: null }}
              onChange={(val) => setFilters({ ...filters, ...val })}
            />
          </PaddingBoxInDesktop>

          <Box mb={4} sx={{ display: "flex", height: "100%" }}>

            <Box mr={2} height={"100%"}>
              <Button
                onClick={onDownloadCsv}
                disabled={excelloading}
                sx={{
                  minWidth: "8vw",
                  minHeight: "5vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#1B4381",
                    color: "white",
                  },
                }}
                variant="outlined"
              >

                {excelloading ? <CircularProgress size={25} /> : <ImportExport sx={{ mr: 1 }} />}
                <Typography variant="h4" sx={{ lineHeight: 1 }}>
                  Export
                </Typography>

              </Button>
            </Box>
            <AsyncSearchBar
              fullWidth
              title="Search Name | Email"
              size="small"
              placeholder={"Search Name | Email"}
              defaultValue={filters?.search}
              onChange={(changedVal) => {
                setFilters({
                  ...filters,
                  pageNo: 1,
                  pageSize: 10,
                  search: changedVal,
                });
              }}
            />
          </Box>
        </FilterTitleBox>

        <DataTable
          key={JSON.stringify(list)}
          columns={columns}
          rows={list?.result ? list.result : []}
          count={list?.total ?? 0}
          filters={filters}
          setFilters={setFilters}
          loading={loading}
        />
      </Box>
    </Paper>
  );
};
export default memo(SalesListUi);
