
import { Delete, Publish, Remove } from "@mui/icons-material"
import { Box, Button, Checkbox, Chip, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, ListItem, MenuItem, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import { memo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { closeModal } from "../../store/actions/modalAction"
import SubmitButton from "../../components/button/SubmitButton"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { fetchColorApi } from "../../apis/productColor.api"
import { color } from "framer-motion"

const ImportCsvCreateUi = ({ fields, selectedButtonName, setFields, onSubmit, onFileSelect, dispatchSheet, data, loading, hasError, product_id, fileErr, setFileErr, color, setColor, params }) => {

    const user = useSelector(state => state.user)

    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    console.log("object data", color);
    return <>

        <DialogTitle variant="h3">
            Import Stock
            <Typography variant="h6" color="red">{fields.err} </Typography>
        </DialogTitle>

        <Typography ml={5} variant="h6" color="red">{fileErr} </Typography>

        <DialogContent sx={{ minHeight: '100px', display: "flex", position: "relative" }}>

            <Box sx={{ minHeight: '100px', width: "100%", display: "flex" }}>
                {!loading && data && data.length == 0 &&
                    <Box width={"100%"} gap={2} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
                        <Box width={"100%"}>
                            <AsyncDropDown
                                defaultVal={
                                    color?.color_id ? {
                                        _id: color?.color_id?._id,
                                        name: color?.color_id?.name,
                                    }
                                        : null
                                }
                                lazyFun={async (para) => await fetchColorApi({ ...para })}
                                OptionComponent={({ option, ...rest }) => {
                                    return <ListItem {...rest}>{option.name}</ListItem >
                                }}
                                value={color?.color_id}
                                onChange={async (changedVal) => {
                                    setColor({ ...color, color_id: changedVal ? changedVal._id : null, color_name: changedVal ? changedVal.name : null });
                                }}

                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <StyledSearchBar placeholder={"Select Product Color*"} {...params} margin="none" />}
                            />
                        </Box>

                        <Box width={"100%"}>
                            <Button disabled={color?.color_id == "" || color?.color_id == null} sx={{ height: "8vh" }} variant="outlined" component="label" fullWidth={true}>
                                <Publish fontSize="large" />

                                <Typography >Upload CSV</Typography>
                                <input hidden accept=".csv" onChange={onFileSelect} type="file" />
                            </Button>
                        </Box>


                    </Box>
                }
                {
                    loading && <Box sx={{ width: "100%" }}><CircularProgress /></Box>
                }


                {!loading && data && data.length > 0 && <>


                    <Box sx={{ border: 1, p: 1, borderRadius: 1, flexDirection: "column", overflowY: "scroll", flex: 3, display: 'flex', maxHeight: "100%" }}>
                        <Typography variant="h5" mb={2}>data</Typography>
                        <TableContainer >


                            <Table stickyHeader aria-label="collapsible table">

                                <TableHead sx={{ zIndex: 1 }}>
                                    <TableRow sx={{ backgroundColor: 'red' }}>

                                        <TableCell>Box Id</TableCell>
                                        <TableCell>Color</TableCell>
                                        <TableCell>Product </TableCell>

                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    {
                                        data?.map((row, index) => index < data?.length - 1 &&
                                            <Row err={row?.hasError} key={row?.data?.id} id={row?.data?.id} dispatchSheet={dispatchSheet} box_id={row?.data?.box_id} color_id={color?.color_name} product_id={selectedButtonName} />)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>


                    </Box>
                </>}

            </Box>
        </DialogContent>
        <DialogActions>
            <SubmitButton onClick={handleClose} title={'Close'}></SubmitButton>
            <SubmitButton disabled={data?.length == 0} onClick={onSubmit ? onSubmit : ""} title="Import" ></SubmitButton>
        </DialogActions>


    </>
}


export default ImportCsvCreateUi

const Row = memo(({ dispatchSheet, err, id, box_id, product_id, color_id }) => {
    return <Tooltip title={err}>
        <TableRow sx={{ background: err != "" ? "pink" : "white" }}>
            <TableCell>{box_id?.value}</TableCell>
            <TableCell>{color_id}</TableCell>
            <TableCell>{product_id}</TableCell>
        </TableRow>
    </Tooltip>
})

