import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { openModal } from "../../store/actions/modalAction";
import { downloadDemoUnitsCsvApi, fetchDemoUnitsApi } from "../../apis/inventory.api";
import Franchise from "./Franchise";
import ChannelSaleCreateController from "../StockProduct/ChannelSaleCreateController";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";
import fileDownload from "js-file-download";

const FranchiseController = ({ title, id }) => {
  const dispatch = useDispatch();
  const fetchApi = fetchDemoUnitsApi;
  const { user } = useSelector((state) => state)

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    searchable: ["name", "address"],
    search: "",
    role: "",
    sort: "",
    sortDirection: -1,
    deleted: null,
  });
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
console.log("object iddddddd", id);

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ user_Id: id || user.data._id }),
        (response) => {
          setList(response);
          console.log("object list", response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  console.log("object iddddddd", id);

  useEffect(() => {
    fetchList();
  }, []);

      const onExportClick = () => {
    
        setLoading(true)
        dispatch(callApiAction(
          async () => await downloadDemoUnitsCsvApi({user_Id: id || user.data._id}),
          (response) => {
            fileDownload(response, "demo_units.csv")
            setLoading(false)
          },
          (err) => {
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
            setLoading(false)
          },
          true
        ))
    
      };

  const onChannelSaleClick = () => {
    dispatch(openModal(<ChannelSaleCreateController />, "md", false));
  };

  return (
    <>
      <Franchise
        title={title}
        list={list}
        onChannelSaleClick={onChannelSaleClick}
        id={id}
        onExportClick={onExportClick}
      />
    </>
  );
};

export default FranchiseController;
