import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/main";



export const getFeedBackCountApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.fetchFeedbackCount,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};
export const getFeedBackDataApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.fetchFeedbackData,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};

export const updateFeedBackDataApi = async (data) => {
    const callResponse = await axios({
        url: endpoints.UpdateFeedbackData,
        method: "patch",
        headers: getHeaders(),
        data,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};



export const fetchFeedbackProblemsApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.fetchFeedbackProblems,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};


export const updateFeedbackProblemsStatusApi = async (data) => {
    const callResponse = await axios({
        url: endpoints.updateFeedbackProblemsStatus,
        method: "patch",
        headers: getHeaders(),
        data,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};

