import { useEffect, useState, useMemo, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import moment from "moment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  findObjectKeyByValue,
  OrderColor,
  OrderProcessColor,
  RefundProcessColor,
  titleCase,
} from "../../utils/main";
import {
  CUSTOMER_TYPE,
  ORDER_PROCESS_STATUS,
  ORDER_PROCESS_STATUS_CHILDREN,
  ORDER_STATUS,
  REFUND_STATUS,
  TRIALS_STATUS,
  USER_ROLES,
} from "../../utils/constants";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal, openModal } from "../../store/actions/modalAction";
import MessageDilog from "../../components/MessageDilog";
import { Check, Clear, DoneAll } from "@mui/icons-material";
import {
  fetchOrderCountApi,
  fetchOrders,
  updateOrder,
} from "../../apis/order.api";
// import OrderShippController from "./Order Shipp/OrderShippController";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import OrderViewController from "./OrderViewController";
import CustomerInformationController from "../customer/CustomerViews/CustomerInformationController";
import {
  fetchRefundCountApi,
  fetchRefundDataApi,
  RefundApi,
} from "../../apis/payment.api";
import RefundListUi from "./RefundListUi";
import RefundViewController from "./RefundViewController";

const ActionComponent = ({ callback = () => {}, params, setParams }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  const actionFun = (data) => {
    console.log("data", data);
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await RefundApi({
            customer_id: data?.customer_id,
            refund_status: data?.refund_status,
          }),
        (response) => {
          //   setParams({ ...params });
          callback();
          dispatch(closeModal());
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const onTakeReturn = (id) => {
    dispatch(closeModal());
    dispatch(
      openModal(
        <CustomerInformationController
          id={id}
          callBack={() => {
            dispatch(closeModal());
            callback();
          }}
        />,
        "xl",
        true,
        "CustomerInformation"
      )
    );
  };

  const onRefund = (data) => {
    console.log("data", data);
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={() => {
            actionFun({
              refund_status: data?.status,
              customer_id: params?.customer_id?._id,
            });
          }}
          confirmText={"Yes"}
          title="Refund"
          message={`Are you sure you want to refund ${params?.refund_amount}?`}
        />,
        "sm"
      )
    );
  };

  const onVeiw = (id) => {
    dispatch(
      openModal(
        <RefundViewController id={params._id} params={params} />,
        "md",
        false,
        "RefundInformation"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "center", display: "flex" }}>
      <Tooltip title="View">
        <IconButton size="small" onClick={onVeiw}>
          <VisibilityIcon size="small" color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>

      {(user.data.role == USER_ROLES.SALES_STAFF ||
        user.data.role == USER_ROLES.FRANCHISE_PARTNER) &&
        params.refund_status == REFUND_STATUS.TRANSFERED && (
          <>
            <Tooltip size="large" title={"Received"}>
              <IconButton
                size="small"
                onClick={() => {
                  onRefund({
                    status: REFUND_STATUS.RECEIVED,
                    id: params?._id,
                  });
                }}
              >
                <Check color="success" fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </>
        )}
      {(user.data.role == USER_ROLES.WEHEAR_ADMIN ||
        user.data.role == USER_ROLES.SUPER_ADMIN) && (
        <>
          {(user.data.role == USER_ROLES.WEHEAR_ADMIN ||
            user.data.role == USER_ROLES.SUPER_ADMIN) &&
            params.refund_status == REFUND_STATUS.INITIATED && (
              <>
                <Tooltip title={"Refund"}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      onRefund({
                        status: REFUND_STATUS.TRANSFERED,
                        id: params?._id,
                      });
                    }}
                  >
                    <Check color="success" fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </>
            )}

          {!(
            user.data.role == USER_ROLES.WEHEAR_ADMIN ||
            user.data.role == USER_ROLES.SUPER_ADMIN
          ) &&
            params.status == ORDER_PROCESS_STATUS_CHILDREN.ORDER_DELIVERED && (
              <>
                <Tooltip title={"Fulfill"}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      onRefund({
                        status: ORDER_PROCESS_STATUS.FULFILLED,
                        id: params?._id,
                        params: params,
                      });
                    }}
                  >
                    <DoneAll color="success" fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </>
            )}
        </>
      )}
    </Box>
  );
};
const RefundListController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});
  const [selectRefundType, setSelectRefundType] = useState(0);
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    searchable: ["customer_id.first_name"],
    search: "",
    role: "",
    sort: "createdAt",
    sortDirection: -1,
    startDate: "",
    endDate: "",
    deleted: false,
  });

  const onVeiw = (params) => {
    dispatch(
      openModal(
        <CustomerInformationController id={params?.customer_id?._id} />,
        "xl",
        false,
        "CustomerInformation"
      )
    );
  };

  const columns = useMemo(() => {
    return [
      {
        id: 1,
        fieldName: "date",
        label: "Date",
        align: "left",
        sort: true,
        renderValue: (params) => moment(params?.createdAt).format("DD/MM/YYYY"),
      },
      {
        id: 2,
        fieldName: "first_name",
        label: "Customer",
        align: "left",
        sort: true,
        renderValue: (params) => (
          <Typography
            variant="body2"
            onClick={() => {
              onVeiw(params);
            }}
            sx={{ cursor: "pointer" }}
          >
            {params?.customer_id?.first_name +
              " " +
              params?.customer_id?.last_name}
          </Typography>
        ),
      },
      {
        id: 3,
        fieldName: "email",
        label: "Email",
        align: "left",
        renderValue: (params) => params?.customer_id?.email,
      },
      {
        id: 4,
        fieldName: "refund_amount",
        label: "Refund Amount",
        align: "left",
        renderValue: (params) => params?.refund_amount,
      },
      {
        id: 5,
        fieldName: "status",
        label: "Status",
        align: "left",
        sort: true,
        renderValue: (params) => {
          return (
            <Chip
              sx={{ minWidth: "120px" }}
              size="medium"
              color={RefundProcessColor(params?.refund_status)}
              label={titleCase(
                findObjectKeyByValue(params?.refund_status, REFUND_STATUS)
              )}
            />
          );
        },
      },
      {
        id: 6,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            params={params}
            setParams={setParams}
            callback={() => fetchRefundCount()}
          />
        ),
      },
    ];
  }, [dispatch]);

  const fetchRefundList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchRefundDataApi({
            ...filters,
            refund_status: selectRefundType,
          }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (selectRefundType != null) {
      fetchRefundList();
    }
  }, [filters, selectRefundType]);
  const [countLoading, setCountLoading] = useState(false);
  const [count, setCount] = useState();
  const fetchRefundCount = () => {
    console.log("fetchRefundCount");
    setCountLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchRefundCountApi({ ...filters }),
        (response) => {
          setCount(response);
          setCountLoading(false);
        },
        (err) => {
          setCountLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchRefundCount();
  }, [filters.startDate, filters.endDate]);

  useEffect(() => {
    if (count?.length > 0) {
      if (selectRefundType == null) {
        setSelectRefundType(count[0].status);
      } else {
        fetchRefundList();
      }
    }
  }, [count]);

  return (
    <RefundListUi
      columns={columns}
      list={list}
      filters={filters}
      loading={loading}
      setFilters={setFilters}
      setSelectRefundType={setSelectRefundType}
      selectRefundType={selectRefundType}
      setCountLoading={setCountLoading}
      countLoading={countLoading}
      count={count}
    />
  );
};
export default memo(RefundListController);
