import { Box, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import { callApiAction } from "../../store/actions/commonAction";
import { getUserEmailListApi } from "../../apis/user.api";
import StaffListController from "./staffWiseAnalysis/StaffListController";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
}));

const ReimburesmentDashboardMain = () => {
  const { user } = useSelector((state) => state);
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    sort: "createdAt",
    sortDirection: -1,
    startDate: "",
    endDate: "",
    deleted: false,
  });


  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState([]);


  const fetchData = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getUserEmailListApi(),
        async (response) => {
          setDataList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <Box width={"100%"} p={2} sx={{ backgroundColor: "white" }} height={"100%"}>
      {/* <CountListController setFilters={setFilters} filters={filters} /> */}
      <Box sx={{ m: 4 }}>
        <FilterTitleBox>
          <Typography color="primary" variant="h4">
            Expense Dashbord
          </Typography>
          <PaddingBoxInDesktop
            sx={{
              display: "flex",
              width: "30vw",
              flexDirection: "flex-start",
            }}
          >
            <TimeRangeSelector
              defaultVal={{ label: "Lifetime", _id: null }}
              onChange={(val) => setFilters({ ...filters, ...val })}
            />
          </PaddingBoxInDesktop>
        </FilterTitleBox>
      </Box>
      <StaffListController list={dataList} filters={filters} />
    </Box>
  );
};
export default ReimburesmentDashboardMain;
