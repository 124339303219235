import {
  AddShoppingCart,
  Apartment,
  Assessment,
  Beenhere,
  ColorLens,
  Cyclone,
  Dashboard,
  Feedback,
  FormatListBulleted,
  Groups,
  Groups2Outlined,
  LocalAtm,
  PointOfSale,
  Settings,
  ShoppingBag,
  ShoppingCart,
} from "@mui/icons-material";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Navigate } from "react-router-dom";
import AppContainer from "./components/layouts/common/AppContainer";
import SignInController from "./pages/signin/SignInController";
import ListController from "./pages/user/UserLIstController";
import ProductTypeController from "./pages/product-type/ProductTypeController";
import PagenotFound from "./components/layouts/PagenotFound";
import ProductColorController from "./pages/product-color/ProductColorController";
import ProductListController from "./pages/products/ProductListController";
import MODULES from "./utils/module.constant";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import StockOfProductListController from "./pages/StockProduct/StockOfProductListController";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import ReplacementController from "./pages/replacement/ReplacementController";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import RepairController from "./pages/repair/RepairController";
import ReturnDataListController from "./pages/Return/ReturnDataListController";
import Customer from "./pages/customer/CustomerViews/Customer";
import CustomerCreateController from "./pages/customer/CustomerCreate/CustomerCreateController";
import DemoList from "./pages/demoUnits/DemoList";
import CategoryIcon from "@mui/icons-material/Category";
import SalesMain from "./pages/Sales/SalesMain";
import TrialsMain from "./pages/Trials/TrialsMain";
import DashboardMain from "./pages/dashboard/DashboardMain";
import PercentIcon from "@mui/icons-material/Percent";
import UserIncentiveLIstController from "./pages/Incentive Percentage/UserIncentiveLIstController";
import OrderListController from "./pages/Order/OrderListController";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import IncentiveMain from "./pages/incentive/IncentiveMain";
import CreateProfileUI from "./pages/profile/CreateProfileUI";
import RefundListController from "./pages/Refund/RefundListController";
import LoginFileController from "./pages/login/LoginFileController";
import FeedbackListController from "./pages/Feedback/FeedbackListController";
import LeadDashboardMain from "./pages/LeadDashboard/LeadDashboardMain";
import LeadListController from "./pages/leads/LeadListController";
import ReimburesmentDashboardMain from "./pages/ReimburesmentDashboard/ReimburesmentDashboardMain";
const defaultRedirect = (user) => {
  if (!user.isLoggedIn) {
    return "/sign-in";
  }
  if (user.data.modules?.includes(MODULES.DASHBOARD_MODULE)) {
    return "/dashboard";
  }
  if (user.data.modules?.includes(MODULES.INVENTORY_MODULE)) {
    return "/inventory-stock";
  }

  if (user.data.modules?.includes(MODULES.USER_MODULE)) {
    return "/users";
  }
  // if (user.data.modules?.includes(MODULES.STOCK_MODULE)) {
  //   return "/stock";
  // }
  if (user.data.modules?.includes(MODULES.SALES)) {
    return "/sales";
  }
  if (user.data.modules?.includes(MODULES.CUSTOMER_LIST_MODULE)) {
    return "/customer";
  }
  if (user.data.modules?.includes(MODULES.RETURN)) {
    return "/returns";
  }
  if (user.data.modules?.includes(MODULES.DEMO_UNITS)) {
    return "/demounit";
  }

  if (user.data.modules?.includes(MODULES.PRODUCT_MODULE)) {
    return "/product";
  }
  if (user.data.modules?.includes(MODULES.INCENTIVE)) {
    return "/incentive";
  }
  if (user.data.modules?.includes(MODULES.ORDER)) {
    return "/order";
  }
  // if (user.data.modules?.includes(MODULES.RETURN_PERSON_MODULE)) {
  //   return "/sales-person";
  // }

  if (user.data.modules?.includes(MODULES.RETURN)) {
    return "/returns";
  }

  if (user.data.modules?.includes(MODULES.PRODUCT_COLOR_MODULE)) {
    return "/product-color";
  }

  if (user.data.modules?.includes(MODULES.PRODUCT_TYPE_MODULE)) {
    return "/product-type";
  }
  if (user.data.modules?.includes(MODULES.INCENTIVE_PERCENTAGE)) {
    return "/inventory-percentage";
  }
  // if (user.data.modules?.includes(MODULES.LEDGER_MODULE)) {
  //   return "/ledger";
  // }
  if (user.data.modules?.includes(MODULES.TRIALS)) {
    return "/trials";
  }
  if (user.data.modules?.includes(MODULES.LEAD)) {
    return "/lead";
  }
  if (user.data.modules?.includes(MODULES.REFUND)) {
    return "/refund";
  }
  return "/sign-in";
};
const moduleAccessCondition = (user, modules = [], roles = []) => {
  if (!user?.isLoggedIn) return false;

  let moduleAcces = false;
  let roleAccess = false;
  const userAllowedModules = user.data?.modules ?? [];
  const userRole = user.data?.role;

  for (let userAllowedModule of userAllowedModules) {
    for (let accessModule of modules) {
      if (userAllowedModule == accessModule) {
        moduleAcces = true;
        break;
      }
    }
  }
  roleAccess = roles?.includes(userRole);
  return moduleAcces || roleAccess;
};
const defineRoutes = (user) => {
  const redirectDefault = defaultRedirect(user);

  return [
    {
      path: "",
      element: !user.isLoggedIn ? (
        <SignInController />
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
      hideInPannel: true,
    },
    {
      path: "sign-in",
      element: !user.isLoggedIn ? (
        <SignInController />
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
      hideInPannel: true,
    },
    {
      path: "profile",
      title: "",
      hideInPannel: true,
      element: user.isLoggedIn ? (
        <AppContainer>
          <CreateProfileUI />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "dashboard",
      icon: <Dashboard />,
      title: "Dashboard",

      hideInPannel: !moduleAccessCondition(user, [MODULES.DASHBOARD_MODULE]),
      element: moduleAccessCondition(user, [MODULES.DASHBOARD_MODULE]) ? (
        <AppContainer>
          <DashboardMain />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "lead-dashboard",
      icon: <Assessment />,
      title: "Lead Dashboard",

      hideInPannel: !moduleAccessCondition(user, [MODULES.LEAD_DASHBOARD]),
      element: moduleAccessCondition(user, [MODULES.LEAD_DASHBOARD]) ? (
        <AppContainer>
          <LeadDashboardMain />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "inventory-stock",
      icon: <Apartment />,
      title: "Inventory",

      hideInPannel: !moduleAccessCondition(user, [MODULES.INVENTORY_MODULE]),
      element: moduleAccessCondition(user, [MODULES.INVENTORY_MODULE]) ? (
        <AppContainer>
          <StockOfProductListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },

    {
      path: "demounit",
      icon: <CategoryIcon />,
      title: "Demo Units",
      hideInPannel: !moduleAccessCondition(user, [MODULES.DEMO_UNITS]),
      element: moduleAccessCondition(user, [MODULES.DEMO_UNITS]) ? (
        <AppContainer>
          <DemoList />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "lead",
      icon: <SupportAgentIcon />,
      title: "Leads",
      hideInPannel: !moduleAccessCondition(user, [MODULES.LEAD]),
      element: moduleAccessCondition(user, [MODULES.LEAD]) ? (
        <AppContainer>
          <LeadListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "customer",
      icon: <Groups />,
      title: "Customers",
      hideInPannel: !moduleAccessCondition(user, [
        MODULES.CUSTOMER_LIST_MODULE,
      ]),
      element: moduleAccessCondition(user, [MODULES.CUSTOMER_LIST_MODULE]) ? (
        <AppContainer>
          <Customer />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "customer/create",
      icon: <Groups2Outlined />,
      title: "Customers",
      hideInPannel: true,
      element: moduleAccessCondition(user, [MODULES.CUSTOMER_CREATE_MODULE]) ? (
        <AppContainer>
          <CustomerCreateController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "trials",
      icon: <Cyclone />,
      title: "Trials",
      hideInPannel: !moduleAccessCondition(user, [MODULES.TRIALS]),
      element: moduleAccessCondition(user, [MODULES.TRIALS]) ? (
        <AppContainer>
          <TrialsMain />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "sales",
      icon: <PointOfSale />,
      title: "Sales",
      hideInPannel: !moduleAccessCondition(user, [MODULES.SALES]),
      element: moduleAccessCondition(user, [MODULES.SALES]) ? (
        <AppContainer>
          <SalesMain />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    // {
    //   path: "reimburesment-dashboard",
    //   icon: <LocalAtm />,
    //   title: "Expenses",
    //   hideInPannel: !moduleAccessCondition(user, [MODULES.RIEMBURESMENT_DASHBOARD]),
    //   element: moduleAccessCondition(user, [MODULES.RIEMBURESMENT_DASHBOARD]) ? (
    //     <AppContainer>
    //       <ReimburesmentDashboardMain />
    //     </AppContainer>
    //   ) : (
    //     <Navigate replace to={redirectDefault} />
    //   ),
    // },
    {
      path: "incentive",
      icon: <MonetizationOnIcon />,
      title: "Incentive",
      hideInPannel: !moduleAccessCondition(user, [MODULES.INCENTIVE]),
      element: moduleAccessCondition(user, [MODULES.INCENTIVE]) ? (
        <AppContainer>
          <IncentiveMain />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "order",
      icon: <ShoppingCart />,
      title: "Orders",
      hideInPannel: !moduleAccessCondition(user, [MODULES.ORDER]),
      element: moduleAccessCondition(user, [MODULES.ORDER]) ? (
        <AppContainer>
          <OrderListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },

    {
      path: "Refund",
      icon: <CreditScoreIcon />,
      title: "Refund",
      hideInPannel: !moduleAccessCondition(user, [MODULES.REFUND]),
      element: moduleAccessCondition(user, [MODULES.REFUND]) ? (
        <AppContainer>
          <RefundListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "feedback",
      icon: <Feedback />,
      title: "Feedback",
      hideInPannel: !moduleAccessCondition(user, [MODULES.FEEDBACK]),
      element: moduleAccessCondition(user, [MODULES.FEEDBACK]) ? (
        <AppContainer>
          <FeedbackListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "replacement",
      icon: <PublishedWithChangesIcon />,
      title: "Replacement",
      element: <Navigate replace to={redirectDefault} />,
      hideInPannel: !moduleAccessCondition(user, [MODULES.REPLACEMENT_MODULE]),

      element: moduleAccessCondition(user, [MODULES.REPLACEMENT_MODULE]) ? (
        <AppContainer>
          <ReplacementController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "repair",
      icon: <PrecisionManufacturingIcon />,
      title: "Repair",
      element: <Navigate replace to={redirectDefault} />,
      hideInPannel: !moduleAccessCondition(user, [MODULES.REPAIR]),

      element: moduleAccessCondition(user, [MODULES.REPAIR]) ? (
        <AppContainer>
          <RepairController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "returns",
      icon: <AssignmentReturnIcon />,
      title: "Return",
      element: <Navigate replace to={redirectDefault} />,
      hideInPannel: !moduleAccessCondition(user, [MODULES.RETURN]),

      element: moduleAccessCondition(user, [MODULES.RETURN]) ? (
        <AppContainer>
          <ReturnDataListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "users",
      icon: <Groups2Outlined />,
      title: "Users",
      hideInPannel: !moduleAccessCondition(user, [MODULES.USER_MODULE]),
      element: moduleAccessCondition(user, [MODULES.USER_MODULE]) ? (
        <AppContainer>
          <ListController />
        </AppContainer>
      ) : (
        <Navigate replace to={redirectDefault} />
      ),
    },
    {
      path: "settings",
      icon: <Settings />,
      title: "Settings",
      hideInPannel: !moduleAccessCondition(user, [
        MODULES.PRODUCT_COLOR_MODULE,
        MODULES.PRODUCT_TYPE_MODULE,
      ]),
      element: moduleAccessCondition(user, [
        MODULES.PRODUCT_COLOR_MODULE,
        MODULES.PRODUCT_TYPE_MODULE,
      ]) ? (
        <AppContainer />
      ) : (
        <Navigate replace to={redirectDefault} />
      ),

      children: [
        {
          path: "product",
          icon: AddShoppingCart,
          title: "Product",

          hideInPannel: !moduleAccessCondition(user, [MODULES.PRODUCT_MODULE]),
          element: moduleAccessCondition(user, [MODULES.PRODUCT_MODULE]) ? (
            <ProductListController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "incentive-percentage",
          icon: PercentIcon,
          title: "Incentive Percentage",

          hideInPannel: !moduleAccessCondition(user, [
            MODULES.INCENTIVE_PERCENTAGE,
          ]),
          element: moduleAccessCondition(user, [
            MODULES.INCENTIVE_PERCENTAGE,
          ]) ? (
            <UserIncentiveLIstController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "product-color",
          title: "Product Color",
          icon: ColorLens,

          hideInPannel: !moduleAccessCondition(user, [
            MODULES.PRODUCT_COLOR_MODULE,
          ]),
          element: moduleAccessCondition(user, [
            MODULES.PRODUCT_COLOR_MODULE,
          ]) ? (
            <ProductColorController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
        {
          path: "product-type",
          title: "Product Type",
          icon: FormatListBulleted,

          hideInPannel: !moduleAccessCondition(user, [
            MODULES.PRODUCT_TYPE_MODULE,
          ]),
          element: moduleAccessCondition(user, [
            MODULES.PRODUCT_TYPE_MODULE,
          ]) ? (
            <ProductTypeController />
          ) : (
            <Navigate replace to={redirectDefault} />
          ),
        },
      ],
    },

    {
      path: "*",
      element: !user.isLoggedIn ? (
        <Navigate replace to={"/sign-in"} />
      ) : (
        <AppContainer>
          <PagenotFound />
        </AppContainer>
      ),
      hideInPannel: true,
    },
  ];
};
export default defineRoutes;
