import React from "react";
import { Box, Paper, Typography, Grid, Step, StepLabel } from "@mui/material";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import {
  CustomStepper,
  CustomConnector,
  CustomStepIcon,
} from "../../components/layouts/common/Stepper";
import moment from "moment";
import { ORDER_PROCESS_STATUS, REFUND_STATUS, TRIALS_STATUS } from "../../utils/constants";
import { findObjectKeyByValue, titleCase } from "../../utils/main";

const RefundViewController = ({ params }) => {
  // STEP MAPPING: Map each status to the correct step index

  return (
    <CustomDialog id={"RefundInformation"} title={"Refund Details"}>
      <Box>
        <Paper
          elevation={0}
          sx={{ width: "100%", minHeight: "100%", padding: 3 }}
        >
          <Box mt={4} sx={{ border: "1px solid #1B4381", borderRadius: "5px" }}>
            <Grid container sx={{ p: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body1">Customer Name :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                {params?.customer_id?.first_name ?? "NA"} {params?.customer_id?.last_name}
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ p: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body1">Amount :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {params?.refund_amount}
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ p: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body1">Product :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {params?.customer_id?.trial_details?.product?.product_name ?? "NA"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2, p: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body1">Box Id :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {params?.customer_id?.trial_details?.box_id ?? "NA"}
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ p: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body1">Refund Status :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1">
                  {titleCase(
                    findObjectKeyByValue(
                      params?.refund_status,
                      REFUND_STATUS
                    )
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </CustomDialog>
  );
};

export default RefundViewController;
