import React, { useState } from "react";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { createLead } from "../../../apis/lead.api";
import CreateLeadUi from "./CreateLeadUi";
import moment from "moment";
import useValidate from "../../../store/hooks/useValidator";
import { closeModal } from "../../../store/actions/modalAction";

const CreateLeadController = ({ callBack = () => {}, params }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const validate = useValidate();

  const [fields, setFields] = useState({
    err: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    phone: "",
    email: "",
    age: 0,
    dob: moment(),
    gender: null,
    address: "",
    state: "",
    city: "",
    zip_code: null,
    comment: "",
    source: "",
  });

  const validationSchema = React.useMemo(
    () => [
      {
        required: true,
        value: fields.first_name,
        field: "first_name",
        message: "First Name",
      },
      {
        required: true,
        value: fields.phone,
        field: "phone_no",
        message: "Phone number",
      },
      // {
      //   required: true,
      //   value: fields.email,
      //   field: "email",
      //   isEmail: true,
      //   message: "Valid email",
      // },
      {
        required: true,
        value: fields.dob,
        field: "dob",
        message: "Date of Birth",
      },
      {
        required: true,
        value: fields.address,
        field: "address",
        message: "Address",
      },
      {
        required: true,
        value: fields.state,
        field: "state",
        message: "State",
      },
      {
        required: true,
        value: fields.city,
        field: "city",
        message: "City",
      },
      {
        required: true,
        value: fields.zip_code,
        field: "zip_code",
        message: "Zip Code",
      },
      {
        required: true,
        value: fields.gender,
        field: "gender",
        message: "Gender",
      },
    ],
    [fields]
  );

  const createLeadFun = (e) => {
    e.preventDefault();
    setLoading(true);
    const validationResponse = validate(validationSchema);
    if (validationResponse === true) {
      dispatch(
        callApiAction(
          async () => await createLead({ ...fields }),
          (response) => {
            setLoading(false);
            callBack();
            dispatch(closeModal("CreateLead"));
          },
          (err) => {
            setLoading(false);
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
      setLoading(false);
    }
  };

  return (
    <CustomDialog
      id={"CreateLead"}
      loading={loading}
      onSubmit={createLeadFun}
      title={"Create Lead"}
      closeText="Close"
      confirmText={"Submit"}
    >
      <CreateLeadUi params={params} fields={fields} setFields={setFields} />
    </CustomDialog>
  );
};

export default CreateLeadController;
