import { useEffect } from "react";
import { callApiAction } from "../../../store/actions/commonAction";
import { useDispatch } from "react-redux";
import { useState } from "react";
import StaffListUi from "./staffListUi";
import { leadDashboardCount } from "../../../apis/lead.api";
import { USER_ROLES } from "../../../utils/constants";

const StaffListController = ({ role, filters, step, reiList }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);

  const fetchData = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await leadDashboardCount({ ...filters, role: step }),
        async (response) => {
          setDataList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchData();
  }, [filters, step]);

  return <StaffListUi reiList={reiList} step={step} loading={loading} dataList={dataList} role={role} />;
};
export default StaffListController;

