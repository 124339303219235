import { Box, IconButton, Paper, Typography } from "@mui/material";
import { KeyboardDoubleArrowLeft, KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowRight } from '@mui/icons-material';
import moment from "moment";

const CustomisedMonthPicker = ({ date, setDate }) => {
    return (
        <Paper
            sx={{
                display: 'flex',
                padding: 2,
                flex: 1,
                backgroundColor: "#E6E6E6",
                height: '100%',
                boxShadow: 'none',
                border: '1px solid #E6E6E6',
                marginBottom: '20px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}
            >
                {/* Double Arrow Left (Year Backwards) */}
                <IconButton
                    onClick={() => setDate(moment(date).subtract(1, 'year'))}
                >
                    <KeyboardDoubleArrowLeft color="primary" />
                </IconButton>

                {/* Single Arrow Left (Month Backwards) */}
                <IconButton
                    onClick={() => setDate(moment(date).subtract(1, 'month'))}
                >
                    <KeyboardArrowLeft color="primary" />
                </IconButton>
            </Box>

            <Typography variant="h3" sx={{ display: "flex", flex: 1, justifyContent: 'center', alignItems: 'center' }} >
                {date && date.format('MMMM YYYY')} {/* Show only the month and year */}
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}
            >
                {/* Single Arrow Right (Month Forward) */}
                <IconButton
                    onClick={() => setDate(moment(date).add(1, 'month'))}
                >
                    <KeyboardArrowRight color="primary" />
                </IconButton>

                {/* Double Arrow Right (Year Forward) */}
                <IconButton
                    onClick={() => setDate(moment(date).add(1, 'year'))}
                >
                    <KeyboardDoubleArrowRight color="primary" />
                </IconButton>
            </Box>
        </Paper>
    );
};

export default CustomisedMonthPicker;
