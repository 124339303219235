import React from "react";
import { styled, keyframes } from "@mui/material/styles";
import { Step, StepLabel, Stepper, StepConnector } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const fillLineAnimation = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`;

export const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "&.MuiStepConnector-alternativeLabel": {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  "& .MuiStepConnector-line": {
    position: "relative",
    height: 3,
    border: 0,
    backgroundColor: theme.palette.grey[300],
    borderRadius: 1,
    overflow: "hidden",
  },
  // Active or completed => fill in green by default
  "&.Mui-active .MuiStepConnector-line::before, &.Mui-completed .MuiStepConnector-line::before":
  {
    content: '""',
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "#4caf50",
    animation: `${fillLineAnimation} 2s forwards`,
    animationDelay: "var(--connector-delay, 0s)",
  },
}));

export const CustomStepper = styled(Stepper)(() => ({
  // Delay the fill animation for each connector
  "& .MuiStepConnector-root:nth-of-type(1)": {
    "--connector-delay": "0s",
  },
  "& .MuiStepConnector-root:nth-of-type(2)": {
    "--connector-delay": "2s",
  },
  "& .MuiStepConnector-root:nth-of-type(3)": {
    "--connector-delay": "4s",
  },
  "& .MuiStepConnector-root:nth-of-type(4)": {
    "--connector-delay": "6s",
  },
}));

export const CustomStepIcon = (props) => {
  const { active, completed, className, label, activeStep, index } = props;

  // You can detect if the step is "Order Rejected"
  const isRejectedStep = label === "Order Rejected";

  // Decide circle color
  let circleColor = "#bdbdbd"; // default (gray)

  circleColor = completed
    ? "#4caf50"
    : index == activeStep + 1
      ? "#bdbdbd"
      : "#bdbdbd";

  // if ( active) {
  //     circleColor = isRejectedStep ? '#1B4381' : '#1B4381';
  // }
  // Circle style
  const circleStyle = {
    backgroundColor: circleColor,
    borderRadius: "50%",
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: `background-color 0.5s ease`,
    color: "#fff",
  };

  return (
    <div className={className} style={circleStyle}>
      {completed ? <CheckIcon key={label} style={{ fontSize: 16 }} /> : null}
    </div>
  );
};
