import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import {
  Add,
  BugReport,
  Delete,
  RemoveCircle,
  Star,
} from "@mui/icons-material";
import { SOLUTION_STATUS } from "../../../utils/constants";
import { useDispatch } from "react-redux";
import { openModal } from "../../../store/actions/modalAction";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import {
  fetchFeedbackProblemsApi,
  updateFeedbackProblemsStatusApi,
} from "../../../apis/feedback.api";
import { callApiAction } from "../../../store/actions/commonAction";
import ProblemSummaryDetailsView from "./ProblemSummaryDetailsView";

const ProblemField = ({ fields, setFields, index, qNum }) => {
  console.log("fields", fields);
  return (
    <OutlinedInput
      value={fields[qNum]?.problems[index]?.problem}
      fullWidth
      onChange={(e) =>
        setFields((prev) => {
          let temp = [...prev[qNum]?.problems];
          temp[index] = { ...temp[index], problem: e.target.value };
          return { ...prev, [qNum]: { ...prev[qNum], problems: temp } };
        })
      }
      id="outlined-adornment-weight"
      endAdornment={
        <InputAdornment position="end">
          {" "}
          <Tooltip title="Remove Problem">
            <RemoveCircle
              sx={{ cursor: "pointer" }}
              onClick={(e) =>
                setFields((prev) => {
                  let temp = [...prev[qNum]?.problems];
                  temp.splice(index, 1);
                  return { ...prev, [qNum]: { ...prev[qNum], problems: temp } };
                })
              }
              color="error"
            />
          </Tooltip>
        </InputAdornment>
      }
      aria-describedby="outlined-weight-helper-text"
      inputProps={{
        "aria-label": "weight",
      }}
    />
  );
};
const ProblemComponent = ({ fields, setFields, qNum }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Button
        startIcon={<Add />}
        variant="contained"
        onClick={() => {
          setFields({
            ...fields,
            [qNum]: {
              ...fields[qNum],
              problems: [
                ...fields[qNum].problems,
                { problem: "", status: SOLUTION_STATUS.PENDING },
              ],
            },
          });
        }}
      >
        <Typography>Add Problem</Typography>
      </Button>
    </Box>
  );
};

const FeedBack2Ui = ({ fields, setFields, error, lastCompleted }) => {
  const dispatch = useDispatch();

  const [isProblem, setIsProblem] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("this is lastCompleted current///....", lastCompleted);
  }, [lastCompleted]);

  const fetchFeedbackProblems = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchFeedbackProblemsApi({ feedback_id: lastCompleted }),
        (response) => {
          for (let i = 0; i <= 15; i++) {
            if (response[`q${i}`]?.problems?.length > 0) {
              const isNotSolvedProblem = response[`q${i}`]?.problems?.some(
                (item) => item.status == SOLUTION_STATUS.PENDING
              );
              if (isNotSolvedProblem) {
                setIsProblem(true);
                break;
              }
            }
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (lastCompleted) {
      fetchFeedbackProblems();
    }
  }, [lastCompleted]);

  const problemSummary = () => {
    dispatch(
      openModal(
        <ProblemSummaryDetailsView id={lastCompleted} />,
        "md",
        false,
        "problem-views"
      )
    );
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        {!isProblem && <Typography color="error">{error}</Typography>}
        {isProblem && (
          <Alert variant="outlined" severity="error">
            Resolve Feedback 1 issue before taking Feedback 2.
          </Alert>
        )}
        <Button variant="outlined" onClick={problemSummary}>
          <Typography variant="body1">FB1 Summary</Typography>
        </Button>
      </Box>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "50px",
            justifyContent: "space-between",
          }}
        >
          <FormLabel color="primary" id="demo-row-radio-buttons-group-label">
            1.Are you wearing the device regularly?
          </FormLabel>
          <Box minHeight={"38px"}>
            {" "}
            {fields?.q16.ans == "no" && (
              <ProblemComponent
                qNum={"q16"}
                fields={fields}
                setFields={setFields}
              />
            )}
          </Box>
        </FormControl>
        <RadioGroup
          defaultValue={"no"}
          onChange={(e, newVal) => {
            setFields({ ...fields, q16: { ...fields?.q16, ans: newVal } });
          }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
        <Box width={"100%"}>
          {fields["q16"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q16"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mb: "10px" }}
        >
          <Typography>2.How long do you wear it for?</Typography>
          <ProblemComponent
            qNum={"q17"}
            fields={fields}
            setFields={setFields}
          />
        </Box>
        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q17: { ...fields?.q17, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q17"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q17"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mb: "10px" }}
        >
          <Typography>
            3.Have you tried any new scenarios since the last time?
          </Typography>
          <ProblemComponent
            qNum={"q18"}
            fields={fields}
            setFields={setFields}
          />
        </Box>

        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q18: { ...fields?.q18, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />

        <Box width={"100%"}>
          {fields["q18"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q18"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mb: "10px" }}
        >
          <Typography>
            4.How often do you need to charge the device during the day?
          </Typography>
          <ProblemComponent
            qNum={"q19"}
            fields={fields}
            setFields={setFields}
          />
        </Box>

        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q19: { ...fields?.q19, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q19"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q19"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mb: "10px" }}
        >
          <Typography>
            5.When do you typically put it for charge and for how long?
          </Typography>
          <ProblemComponent
            qNum={"q20"}
            fields={fields}
            setFields={setFields}
          />
        </Box>

        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q20: { ...fields?.q20, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q20"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q20"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mb: "10px" }}
        >
          <Typography>6.How's your comfort in wearing the device?</Typography>
          <ProblemComponent
            qNum={"q21"}
            fields={fields}
            setFields={setFields}
          />
        </Box>

        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q21: { ...fields?.q21, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q21"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q21"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mb: "10px" }}
        >
          <Typography>
            7.Have you tried connecting the device with any device via bluetooth
          </Typography>
          <ProblemComponent
            qNum={"q22"}
            fields={fields}
            setFields={setFields}
          />
        </Box>

        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q22: { ...fields?.q22, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q22"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q22"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mb: "10px" }}
        >
          <Typography>
            8.Did you try using the controls provided in the application?
          </Typography>
          <ProblemComponent
            qNum={"q23"}
            fields={fields}
            setFields={setFields}
          />
        </Box>

        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q23: { ...fields?.q23, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q23"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q23"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mb: "10px" }}
        >
          <Typography>
            9.Have you tried used the streaming function of the device?
          </Typography>
          <ProblemComponent
            qNum={"q24"}
            fields={fields}
            setFields={setFields}
          />
        </Box>

        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q24: { ...fields?.q24, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q24"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q24"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "10px",
            }}
          >
            <FormLabel color="primary" id="demo-row-radio-buttons-group-label">
              10.How are the controls of the buttons? Have you used it
              frequently?
            </FormLabel>
            {fields.q25 == "no" && (
              <ProblemComponent
                qNum={"q25"}
                fields={fields}
                setFields={setFields}
              />
            )}
          </Box>

          <RadioGroup
            row
            onChange={(e, newVal) => {
              setFields({ ...fields, q25: { ...fields?.q25, ans: newVal } });
            }}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="yes " control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        <Box width={"100%"}>
          {fields["q25"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q25"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
      {/* Other */}

      <Box
        mb={4}
        p={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #c4c4c4",
          borderRadius: "4px",
        }}
      >
        <Box width={"100%"}>
          <Box
            width={"100%"}
            sx={{
              // ml: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              11.What would you rate the overall audio experience of the device
              from 1 - 5
            </Typography>
            <Rating
              onChange={(e, newVal) => {
                setFields({ ...fields, q26: { ...fields?.q26, ans: newVal } });
              }}
              name="text-feedback"
              // value={fields.rating_parameters.parameter1}
              sx={{ gap: 2 }}
              precision={0.5}
              icon={<Star style={{ opacity: 1 }} fontSize="large" />}
              emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="large" />}
            />
          </Box>
          <Divider sx={{ backgroundColor: "#e2e2e2" }} />
        </Box>
        <Box width={"100%"}>
          <Box
            width={"100%"}
            sx={{
              // ml: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              12.What would you rate the overall ease of use of the device from
              1 - 5
            </Typography>
            <Rating
              onChange={(e, newVal) => {
                setFields({ ...fields, q27: { ...fields?.q27, ans: newVal } });
              }}
              name="text-feedback"
              // value={fields.rating_parameters.parameter2}
              sx={{ gap: 2 }}
              precision={0.5}
              icon={<Star style={{ opacity: 1 }} fontSize="large" />}
              emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="large" />}
            />
          </Box>
          <Divider sx={{ backgroundColor: "#e2e2e2" }} />
        </Box>{" "}
        <Box width={"100%"}>
          <Box
            width={"100%"}
            sx={{
              // ml: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              13.What would you rate the battery life from 1 - 5
            </Typography>
            <Rating
              name="text-feedback"
              onChange={(e, newVal) => {
                setFields({ ...fields, q28: { ...fields?.q28, ans: newVal } });
              }}
              // value={fields.rating_parameters.parameter3}
              sx={{ gap: 2 }}
              precision={0.5}
              icon={<Star style={{ opacity: 1 }} fontSize="large" />}
              emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="large" />}
            />
          </Box>
          <Divider sx={{ backgroundColor: "#e2e2e2" }} />
        </Box>{" "}
        <Box width={"100%"}>
          <Box
            width={"100%"}
            sx={{
              // ml: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              14.What would you rate the connectivity capabilities of the device
              from 1 - 5
            </Typography>
            <Rating
              onChange={(e, newVal) => {
                setFields({ ...fields, q29: { ...fields?.q29, ans: newVal } });
              }}
              name="text-feedback"
              // value={fields.rating_parameters.parameter4}
              sx={{ gap: 2 }}
              precision={0.5}
              icon={<Star style={{ opacity: 1 }} fontSize="large" />}
              emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="large" />}
            />
          </Box>
        </Box>{" "}
      </Box>
      {/* //IF - using another device before */}
      <Box
        sx={{
          border: "1px solid gray",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mb: "10px" }}
        >
          <Typography>
            15.Any additional feedback you would like to give?
          </Typography>
          <ProblemComponent
            qNum={"q30"}
            fields={fields}
            setFields={setFields}
          />
        </Box>

        <TextField
          sx={{ marginBottom: "10px" }}
          fullWidth
          onChange={(e, newVal) => {
            setFields({
              ...fields,
              q30: { ...fields?.q30, ans: e.target.value },
            });
          }}
          autoFocus
          type="text"
          id="outlined-textarea"
        />
        <Box width={"100%"}>
          {fields["q30"]?.problems?.map((item, index) => (
            <Box mt={2}>
              <ProblemField
                qNum={"q30"}
                fields={fields}
                setFields={setFields}
                index={index}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default FeedBack2Ui;
