import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import QRCode from "qrcode";
import { getPaymentURL } from "../../../../apis/payment.api";
import loadingImg from "../../../../assets/images/loading.gif";
import { callSnackBar } from "../../../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../../../utils/constants";
import { formatNumberCustomPattern } from "../../../../utils/helper";
import { ContentCopy } from "@mui/icons-material";

const Payment = ({
  amount,
  totalAmount,
  remainingAmount,
  advanceAmount,
  date,
  type,
}) => {
  const [qrCode, setQrCode] = useState(""); // State to store QR code image URL
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const dispatch = useDispatch();

  const generateQRurl = async () => {
    setLoading(true);
    try {
      if (type === "Trial") {
        const response = await getPaymentURL({
          amount: parseInt(amount) * 1000,
        });
        setUrl(response?.data?.short_url);
        setResponse(response?.data); // Save the response to state for later use (order_id)
        setLoading(false);
      } else {
        const response = await getPaymentURL({
          amount: parseInt(remainingAmount) * 1000,
        });
        setUrl(response?.data?.short_url);
        setResponse(response?.data); // Save the response to state for later use (order_id)
        setLoading(false);
      }
    } catch (error) {
      console.error("Error generating payment URL:", error);
      setLoading(false);
    }
  };

  const generateQRCode = async () => {
    try {
      const qrCodeURL = await QRCode.toDataURL(url, {
        width: 300,
        margin: 2,
      });
      setQrCode(qrCodeURL); // Save the generated QR code URL to state
    } catch (err) {
      console.error("Error generating QR code:", err);
    }
  };

  useEffect(() => {
    // Generate the payment URL and then the QR code
    generateQRurl();
  }, []);

  useEffect(() => {
    if (url) {
      generateQRCode();
    }
  }, [url]);

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "40vh",
            justifyContent: "center",
          }}
        >
          <img
            src={loadingImg}
            alt="Loading..."
            style={{ width: "60px", marginTop: "10px" }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            padding: "16px",
            minWidth: "60%",
          }}
        >
          {" "}
          <Typography variant="h4">
            Scan the QR code to proceed with payment using a card
          </Typography>
          <Grid container alignItems="center">
            {/* Left Section: Basic Details */}
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "8px",
                  padding: "16px",
                }}
              >
                {type === "Trial" && (
                  <Typography variant="body1">
                    <strong>Amount :</strong>{" "}
                    {formatNumberCustomPattern(amount)}
                  </Typography>
                )}
                {type === "Purchase" && (
                  <>
                    <Typography variant="body1">
                      <strong>Total Amount :</strong> {totalAmount}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Advance Pay Amount :</strong> {advanceAmount}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Remainning Amount :</strong> {remainingAmount}
                    </Typography>
                  </>
                )}

                <Typography variant="body1">
                  <strong>Date :</strong>{" "}
                  {new Date(date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Typography>
              </Box>
            </Grid>

            {/* Right Section: QR Code */}
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                {qrCode ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <img
                      src={qrCode}
                      alt="QR Code"
                      style={{ width: "200px", marginTop: "10px" }}
                    />
                    <Typography
                      variant="body1"
                      sx={{ marginTop: 2, marginBottom: 2 }}
                    >
                      {url}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard
                          .writeText(url)
                          .then(() => {
                            dispatch(
                              callSnackBar(
                                "Copied to clipboard",
                                SNACK_BAR_VARIETNS.suceess
                              )
                            );
                          })
                          .catch((err) => {
                            dispatch(
                              callSnackBar(
                                "Failed to Copy",
                                SNACK_BAR_VARIETNS.error
                              )
                            );
                          });
                      }}
                    >
                      <ContentCopy />
                    </IconButton>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h6">Something went wrong!</Typography>
                    <button onClick={generateQRurl}>Retry</button>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Payment;
