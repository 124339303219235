import { Box, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "../assets/images/searchIcon.svg";
import loadingImg from "../assets/images/loading.gif";
import CustomizedAccordions from "./AccordionComponent";
import { useDispatch } from "react-redux";
import { callApiAction } from "../store/actions/commonAction";
import { USER_ROLES } from "../utils/constants";
import { getUserApi } from "../apis/user.api";
import { findObjectKeyByValue } from "../utils/main";
const ScpOrFranchiseList = ({ step, module, franchise, Component }) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const fetchApi = getUserApi;

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    searchable: ["name", "email", "phone"],
    search: "",
  });

  // const role =
  //   step == 1 ? USER_ROLES.SALES_CHANNEL_PARTNER : USER_ROLES.FRANCHISE_PARTNER;

  let role = 1;

  switch (step) {
    case 1:
      role = USER_ROLES.SALES_CHANNEL_PARTNER;
      break;
    case 2:
      role = USER_ROLES.FRANCHISE_PARTNER;
      break;
    case 3:
      role = USER_ROLES.SALES_STAFF;
      break;
    case 4:
      role = USER_ROLES.CRM;
      break;
    default:
      role = USER_ROLES.SALES_CHANNEL_PARTNER;
  }

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters, role }),
        (response) => {
          setList(response.result);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <Paper
       
        elevation={0}
        sx={{ width: "100%", minHeight: "65vh", padding: 3 ,marginTop:"10px"}}
      >
        <Box>
          <TextField
            id="outlined-start-adornment"
            fullWidth
            onChange={(e) => {
              setFilters({ ...filters, search: e.target.value });
            }}
            placeholder="Search "
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40vh",
            }}
          >
            <img src={loadingImg} alt="Loading.." />
          </Box>
        ) : list.length > 0 ? (
          <Box sx={{ mt: 2 }}>
            {list.map((item) => (
              <Box mt={2}>
                <CustomizedAccordions
                  Component={Component}
                  module={module}
                  franchise={franchise}
                  item={item}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40vh",
            }}
          >
            <Typography sx={{ fontWeight: "700" }}>{`No ${findObjectKeyByValue(
              role,
              USER_ROLES
            )} Found.`}</Typography>
          </Box>
        )}
      </Paper>
    </>
  );
};

export default ScpOrFranchiseList;
