import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup } from "@mui/material";
import CustomizedAccordions from "../../components/AccordionComponent";
import ScpOrFranchiseList from "../../components/ScpOrFranchiseList";
import { USER_ROLES } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import DemoUnitListController from "./DemoUnitListController";
import { FetchChildRolesApi } from "../../apis/incentive.api";
import FranchiseController from "./FranchiseController";
import { callApiAction } from "../../store/actions/commonAction";

const DemoList = () => {
  const { user } = useSelector((state) => state);
  const [step, setStep] = useState(1);

  const [list, setList] = useState();
  const dispatch = useDispatch();
  const FetchChildRoles = () => {
    dispatch(
      callApiAction(
        async () => await FetchChildRolesApi(),
        (response) => {
          setList(response);
        },
        (err) => {}
      )
    );
  };
  useEffect(() => {
    FetchChildRoles();
  }, []);

  return (
    <Box>
      {user.data.role != USER_ROLES.SALES_STAFF && (
        <Box mb={2}>
          <ButtonGroup
            sx={{ color: "#101536" }}
            fullWidth
            size="large"
            aria-label="Large button group"
          >
            {/* {(user.data.role == USER_ROLES.WEHEAR_ADMIN ||
                user.data.role == USER_ROLES.SALES_CHANNEL_PARTNER) && (
                <Button
                  sx={step !== 0 && { backgroundColor: "white" }}
                  variant={step == 0 ? "contained" : "outlined"}
                  onClick={() => setStep(0)}
                  fullWidth
                >
                  ALL
                </Button>
              )} */}
            {(user.data.role == USER_ROLES.WEHEAR_ADMIN ||
              user.data.role == USER_ROLES.SALES_CHANNEL_PARTNER ||
              user.data.role == USER_ROLES.FRANCHISE_PARTNER ||
              user.data.role == USER_ROLES.SUPER_ADMIN) && (
              <Button
                sx={step !== 1 && { backgroundColor: "white" }}
                variant={step == 1 ? "contained" : "outlined"}
                onClick={() => setStep(1)}
                fullWidth
              >
                {user.data.role == USER_ROLES.SALES_CHANNEL_PARTNER ||
                user.data.role == USER_ROLES.FRANCHISE_PARTNER
                  ? "My Demo Device"
                  : "SCP"}
              </Button>
            )}
            {(user.data.role == USER_ROLES.WEHEAR_ADMIN ||
              user.data.role == USER_ROLES.SALES_CHANNEL_PARTNER ||
              user.data.role == USER_ROLES.SUPER_ADMIN ||
              user.data.role == USER_ROLES.FRANCHISE_PARTNER) && (
              <Button
                sx={step !== 3 && { backgroundColor: "white" }}
                variant={step == 3 ? "contained" : "outlined"}
                onClick={() => setStep(3)}
                disabled={
                  user.data.role === USER_ROLES.WEHEAR_ADMIN ||
                  user.data.role === USER_ROLES.SUPER_ADMIN
                    ? false
                    : !list?.SS
                }
                fullWidth
              >
                Sales Staff
              </Button>
            )}
            {(user.data.role == USER_ROLES.WEHEAR_ADMIN ||
              user.data.role == USER_ROLES.SALES_CHANNEL_PARTNER ||
              user.data.role == USER_ROLES.SUPER_ADMIN) && (
              <Button
                sx={step !== 2 && { backgroundColor: "white" }}
                variant={step == 2 ? "contained" : "outlined"}
                onClick={() => setStep(2)}
                disabled={
                  user.data.role === USER_ROLES.WEHEAR_ADMIN ||
                  user.data.role === USER_ROLES.SUPER_ADMIN
                    ? false
                    : !list?.FP
                }
                fullWidth
              >
                Franchise
              </Button>
            )}
          </ButtonGroup>
        </Box>
      )}
      {/* {step == 0 && <DemoUnitListController />} */}
      {step == 1 &&
        (user.data.role == USER_ROLES.SALES_CHANNEL_PARTNER ||
        user.data.role == USER_ROLES.FRANCHISE_PARTNER ||
        user.data.role == USER_ROLES.SALES_STAFF ? (
          <DemoUnitListController step={step} />
        ) : (
          <ScpOrFranchiseList Component={DemoUnitListController} step={step} />
        ))}
      {step == 2 && (
        <ScpOrFranchiseList Component={FranchiseController} step={step} />
      )}
      {step == 3 && (
        <ScpOrFranchiseList Component={FranchiseController} step={step} />
      )}
    </Box>
  );
};

export default DemoList;
