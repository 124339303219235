import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/main";


export const createLead = async (data) => {
  const callResponse = await axios({
    url: endpoints.createLeadAPI,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};

export const fetchleadsCount = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchleadsCount,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const fetchleadsData = async (params) => {
  const callResponse = await axios({
    url: endpoints.fetchleadsData,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const assignedCustomerAPI = async (data) => {
  const callResponse = await axios({
    url: endpoints.assignedCustomer,
    method: "patch",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};


export const leadDashboardCount = async (params) => {
  const callResponse = await axios({
    url: endpoints.leaddashboard,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
