import { Box, Card, CardContent, Container, Divider, Grid, Grid2, Paper, Rating, TextField, Typography } from "@mui/material";
import React from "react";
import FileInput from "../../../components/upload/FileInput";
import { Star } from "@mui/icons-material";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import ProblemSummaryDetailsView from "../FeedbackUpdate/ProblemSummaryDetailsView";

const FeedBackViewUi = ({ fields, fb1, fb2, fb3 }) => {
  console.log("fieldsaaaaaaaa", fields);
  let feedback = fb1 ? 1 : fb2 ? 2 : fb3 ? 3 : "NA";


  const feedback1Data = [
    { question: "Are you wearing the device regularly?", answer: fields?.q1?.ans },
    { question: "How long do you wear it for?", answer: fields?.q2?.ans },
    { question: "How was your experience talking to people one on one at home?", answer: fields?.q3?.ans ?? "NA" },
    { question: "How was your experience talking to people one on one outside in a crowd?", answer: fields?.q4?.ans ?? "NA" },
    { question: "How is your experience with being in a crowded area with loud noises?", answer: fields?.q5?.ans ?? "NA" },
    { question: "How is your experience listening to people in front of you/behind you?", answer: fields?.q6?.ans ?? "NA" },
    { question: "How is your experience listening to one person when you are with multiple people?", answer: fields?.q7?.ans ?? "NA" },
    { question: "How is your experience listening to one person when you are with multiple people and in a crowded area?", answer: fields?.q8?.ans ?? "NA" },
    { question: "How is the sound experience when a person is talking from a distance?", answer: fields?.q9?.ans ?? "NA" },
    { question: "Have you charged the device since you bought it?", answer: fields?.q10?.ans ?? "NA" },
    { question: "How long did you let it charge approximately?", answer: fields?.q11?.ans ?? "NA" },
    { question: "Have you tried connecting the device with any device via bluetooth?", answer: fields?.q12?.ans ?? "NA" },
    { question: "Have you tried using the streaming function of the device?", answer: fields?.q13?.ans ?? "NA" },
    { question: "How’s your comfort in wearing the device?", answer: fields?.q14?.ans ?? "NA" },
    { question: "Any additional feedback you would like to give?", answer: fields?.q15?.ans ?? "NA" },
  ];


  const feedback2Data = [
    { question: "Are you wearing the device regularly?", answer: fields?.q16?.ans },
    { question: "How long do you wear it for?", answer: fields?.q17?.ans },
    { question: "Have you tried any new scenarios since the last time?", answer: fields?.q18?.ans },
    { question: "How often do you need to charge the device during the day?", answer: fields?.q19?.ans },
    { question: "When do you typically put it for charge and for how long?", answer: fields?.q20?.ans },
    { question: "How's your comfort in wearing the device?", answer: fields?.q21?.ans },
    { question: "Have you tried connecting the device with any device via Bluetooth?", answer: fields?.q22?.ans },
    { question: "Did you try using the controls provided in the application?", answer: fields?.q23?.ans },
    { question: "Have you tried using the streaming function of the device?", answer: fields?.q24?.ans },
    { question: "How are the controls of the buttons? Have you used it frequently?", answer: fields?.q25?.ans },
    { question: "What would you rate the overall audio experience of the device from 1 - 5", answer: fields?.q26?.ans },
    { question: "What would you rate the overall ease of use of the device from 1 - 5", answer: fields?.q27?.ans },
    { question: "What would you rate the battery life from 1 - 5", answer: fields?.q28?.ans },
    { question: "What would you rate the connectivity capabilities of the device from 1 - 5", answer: fields?.q29?.ans },
    { question: "Any additional feedback you would like to give?", answer: fields?.q30?.ans },
  ];


  const feedback3Data = [
    { question: "Are you wearing the device regularly?", answer: fields?.q31?.ans },
    { question: "How is the hearing experience?", answer: fields?.q32?.ans },
    { question: "Has your frequency of using bluetooth and app increased?", answer: fields?.q33?.ans },
    { question: "What new features have you used via bluetooth?", answer: fields?.q34?.ans },
    { question: "What's something new you have watched recently using streaming?", answer: fields?.q35?.ans },
    { question: "Does the device sit well around your ear?", answer: fields?.q36?.ans },
    { question: "Does the device stay in place?", answer: fields?.q37?.ans },
    { question: "How is the feel of the device?", answer: fields?.q38?.ans },
    { question: "How is the feel of the button/ touch ? What functions have you tried using?", answer: fields?.q39?.ans },
    { question: "How often do you place it on charge during the day/week?", answer: fields?.q40?.ans },
    { question: "Are the indications clear enough?", answer: fields?.q41?.ans },
    { question: "What would you rate the audio experience from 1- 5?", answer: fields?.q42?.ans },
    { question: "What would you rate the blue tooth and functionality from 1 - 5?", answer: fields?.q43?.ans },
    { question: "What would you rate the overall comfort of the device from 1- 5", answer: fields?.q44?.ans },
    { question: "What would you rate the overall working and charging of the device from 1 - 5?", answer: fields?.q45?.ans },
    { question: "What would you rate the overall experience from 1 - 5?", answer: fields?.q46?.ans },
    { question: "What difference do you feel in the device you were using before and this one?", answer: fields?.q47?.ans },
    { question: "If you had to compare how much would you rate our device and your old device?", answer: fields?.q48?.ans },
  ]

  return (
    <CustomDialog id={"feedback-view"} title={`FeedBack ${feedback}  Details`}>
      <Box p={2} border={"1px solid #c4c4c4"} position="relative" sx={{ overflow: "auto" }} height={"70vh"} width="100%">
        {fb1 && (
          <>
            {/* <Container maxWidth="md">
              <Grid container spacing={2} mt={2}>

                <Grid item xs={12}>

                  <Grid container spacing={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Grid item xs={6}>
                      <Typography variant="h6">Are you wearing the device regularly?</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Answer 1</Typography>
                    </Grid>
                  </Grid>

                  <Divider />
                </Grid>
                <Grid item xs={12}>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6">How long do you wear it for?</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Answer 2</Typography>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={12}>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6">How was your experience talking to people one on one at home?</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Answer 3</Typography>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={12}>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6">How was your experience talking to people one on one outside in a crowd?</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Answer 4</Typography>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={12}>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6">How is your experience with being in a crowded area with loud noises?</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Answer 5</Typography>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={12}>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6">How is your experience listening to people in front of you/behind you?</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Answer 6</Typography>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={12}>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6">How is your experience listening to one person when you are with multiple people?</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Answer 7</Typography>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={12}>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6">How is your experience listening to one person when you are with multiple people and in a crowded area?</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Answer 8</Typography>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={12}>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6">How is the sound experience when a person is talking from a distance?</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Answer 9</Typography>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={12}>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h6">Are you facing any issues with sound clarity?</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Answer 10</Typography>
                    </Grid>
                  </Grid>

                </Grid>

              </Grid>
            </Container> */}

            <Container maxWidth="md" sx={{ mt: 4 }}>
              <Grid container spacing={2}>
                {feedback1Data?.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Card sx={{ p: 1, boxShadow: 1, borderRadius: 2 }}>
                      <CardContent>
                        <Typography variant="body1" fontWeight="bold">
                          {item.question}
                        </Typography>
                        <Typography variant="body1" sx={{ color: "gray", mt: 1 }}>
                          {item.answer}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <ProblemSummaryDetailsView isNotModel={true} id={fields._id} />
            </Container>
          </>
        )}


        {(fb2) && (
          <>
            <Container maxWidth="md" sx={{ mt: 4 }}>
              <Grid container spacing={2}>
                {feedback2Data?.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Card sx={{ p: 1, boxShadow: 1, borderRadius: 2 }}>
                      <CardContent>
                        <Typography variant="body1" fontWeight="bold">
                          {item.question}
                        </Typography>
                        <Typography variant="body1" sx={{ color: "gray", mt: 1 }}>
                          {item.answer}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <ProblemSummaryDetailsView isNotModel={true} id={fields._id} />
            </Container>
          </>
        )}

        {fb3 && (
          <>
            <Container maxWidth="md" sx={{ mt: 4 }}>
              <Grid container spacing={2}>
                {feedback3Data?.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Card sx={{ p: 1, boxShadow: 1, borderRadius: 2 }}>
                      <CardContent>
                        <Typography variant="body1" fontWeight="bold">
                          {item.question}
                        </Typography>
                        <Typography variant="body1" sx={{ color: "gray", mt: 1 }}>
                          {item.answer}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              <ProblemSummaryDetailsView isNotModel={true} id={fields._id} />
            </Container>
          </>
        )}

        {fb3 && (
          <video controls width="100%">
            <source src={fields.video_link} type={fields?.video_link?.type} />
            Your browser does not support the video tag.
          </video>
        )}
      </Box>
    </CustomDialog >
  );
};

export default FeedBackViewUi;
