import { Box, Divider, Rating, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import FileInput from "../../../components/upload/FileInput";
import { Feed, Star } from "@mui/icons-material";
import FeedBack1Ui from "./FeedBack1Ui";
import FeedBack2Ui from "./FeedBack2Ui";
import FeedBack3Ui from "./FeedBack3Ui";
import FeedBack1Controller from "./FeedBack1Controller";
import FeedBack2Controller from "./FeedBack2Controller";
import FeedBack3Controller from "./FeedBack3Controller";

const FeedbackUpdateUi = ({
  fields,
  setFields,
  error,
  fb1,
  fb2,
  fb3,
  lastCompleted,
  callBack = () => {},
}) => {
  useEffect(() => {
    console.log("tghuis is lastCompleted", lastCompleted);
  }, [lastCompleted]);
  return (
    <Box position="relative" height={"100%"} width="100%">
      <Typography mb={2} color="error" variant="body1">
        {error}
      </Typography>

      {fb1 && <FeedBack1Controller callBack={callBack} fb1={fb1} />}

      {fb2 && (
        <FeedBack2Controller
          callBack={callBack}
          lastCompleted={lastCompleted}
          fb2={fb2}
        />
      )}
      {fb3 && (
        <FeedBack3Controller
          callBack={callBack}
          lastCompleted={lastCompleted}
          fb3={fb3}
        />
      )}
    </Box>
  );
};

export default FeedbackUpdateUi;
