import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  OutlinedInput,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import AsyncDropDown from "../../../../components/inputs/AsyncDropDown";
import { fetchProductApi } from "../../../../apis/product.api";
import { StyledSearchBar } from "../../../../components/inputs/SearchBar";
import CustomInput from "../../../../components/inputs/CustomInputs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../../store/actions/modalAction";
import QrScannerPopup from "../../../Scanner/QrScannerPopup";
import { CloudUpload, QrCodeScanner, Upload, Visibility } from "@mui/icons-material";
import TrialProductInformationUi from "./TrialProductInformationUi";
import { callApiAction } from "../../../../store/actions/commonAction";
import { useEffect, useState } from "react";
import { fetchFindByBoxIdApi } from "../../../../apis/productStock.api";
import { findObjectKeyByValue, titleCase } from "../../../../utils/main";
import { PROOF_TYPE } from "../../../../utils/constants";
import FileInput from "../../../../components/upload/FileInput";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const TrialProductDetailsUi = ({ loading, fields, setFields, tempData, setTempData }) => {
  const dispatch = useDispatch();
  const onScan = (data) => {
    setFields({
      ...fields,
      sold_details: {
        ...fields.sold_details,
        box_id: data.text,
      },
    });
  };

  const Information = (data) => {
    dispatch(
      openModal(
        <TrialProductInformationUi
          res={data}
        />,
        "xs",
        false,
        "Information"
      )
    );
  };

  const [loadingVerified, setLoadingVerified] = useState(false);




  const FileUploadCard = () => {
    return (
      <Box
        mt={2}
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: "4px",
          padding: "2px",
          width: "100%",
          border: "1.5px dashed",
        }}
      >
        <Box
          sx={{
            width: "50px",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#f5f5f5",
            borderRadius: "4px",
          }}
        >
          <CloudUpload color="primary" fontSize="medium" />
        </Box>

        {/* File Info */}
        <Box flex="1" mx={2}>
          <Typography variant="h5">Upload File</Typography>
          <Typography variant="caption" color="gray">Only png,jpeg,jpg,pdf files are allowed! less than 1 MB</Typography>
        </Box>

        {false && <> <Box flex="1" mx={2}>
          <Typography variant="body1">Only png,jpeg,jpg,pdf files are allowed! less than 1 MB</Typography>
        </Box>
          <IconButton size="small" sx={{ ml: 1 }}>
            <CloseIcon />
          </IconButton>
        </>}
      </Box>
    );
  };




  const onVerifiedBoxId = () => {
    dispatch(
      callApiAction(
        async () =>
          await fetchFindByBoxIdApi({
            startBoxId: fields?.sold_details?.box_id,
            endBoxId: fields?.sold_details?.box_id,
            product_id: fields?.sold_details?.product?._id
          }),
        (response) => {
          if (response.length < 1) {
            setFields({ ...fields, err: "Enter a valid Box ID" });
            setLoadingVerified(false);
          } else {
            setFields({
              ...fields, err: "", sold_details: {
                ...fields.sold_details,
                amount: response[0]?.product?.mrp * 0.2,
              }
            });
            Information(response[0]);
            setTempData(response[0])
            setLoadingVerified(false);
          }
        },
        (err) => {
          setFields({ ...fields, err });
          setLoadingVerified(false);
        }
      )
    );
  };

  useEffect(() => {
    setFields({ ...fields, err: "" });
    if (fields?.sold_details?.box_id && fields?.sold_details?.product?._id && tempData?.box_id != fields?.sold_details?.box_id) {
      const timeoutId = setTimeout(() => {
        setLoadingVerified(true);
        onVerifiedBoxId();
      }, 2000);
      return () => clearTimeout(timeoutId);
    } else if (fields?.sold_details?.box_id && !fields?.sold_details?.product) {
      setFields({ ...fields, err: "Please Select the Product." });
    }
  }, [fields?.sold_details?.box_id, fields?.sold_details?.product?._id]);

  const onQrBtnClick = () => {
    dispatch(
      openModal(
        <QrScannerPopup
          setFields={setFields}
          fields={fields}
          onScan={onScan}
          onClose={() => dispatch(closeModal("scanner"))}
        />, // Pass onClose to handle modal close
        "sm",
        false,
        "scanner"
      )
    );
  };
  return (
    <>
      <Box mt={4}>
        <Box mb={2} height={"10px"}>
          <Typography variant="h5" color={"red"}>
            {fields?.err}
          </Typography>
        </Box>
        <Box
          gap={4}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box mt={2} width={"50%"}>
            <AsyncDropDown
              defaultVal={
                fields?.sold_details?.product?._id && {
                  _id: fields?.sold_details?.product?._id,
                  product_name: fields?.sold_details?.product?.product_name,
                }
              }
              lazyFun={async (para) =>
                await fetchProductApi({ ...para, allStatus: true })
              }
              OptionComponent={({ option, ...rest }) => {
                console.log("optionss", option);
                return <ListItem {...rest}>{option.product_name}</ListItem>;
              }}
              onChange={(changedVal) => {
                console.log("this is changes value", changedVal);
                setFields((prevFields) => ({
                  ...prevFields,
                  sold_details: {
                    ...prevFields?.sold_details,
                    product: changedVal ?? {},
                  },
                  err: "",
                }));
              }}
              titleKey={"product_name"}
              valueKey={"_id"}
              InputComponent={(params) => (
                <StyledSearchBar
                  placeholder={"Select Product*"}
                  {...params}
                  margin="none"
                />
              )}
            />
          </Box>

          <Box width={"50%"}>
            <FormControl color="primary" fullWidth variant="outlined">
              <InputLabel color="primary" htmlFor="outlined-adornment-boxid">
                Enter Box Id*
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-boxid"
                value={fields?.sold_details?.box_id || ""}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    sold_details: {
                      ...fields.sold_details,
                      ...fetchProductApi.sold_details,
                      box_id: e.target.value,
                    },
                  })
                }
                type="text"
                autoFocus={!loadingVerified && true}
                disabled={loadingVerified}
                endAdornment={<>
                  {loadingVerified && <CircularProgress size={30} />}
                  <InputAdornment position="end">
                    <IconButton onClick={onQrBtnClick} edge="end">
                      <QrCodeScanner />
                    </IconButton>
                  </InputAdornment>
                </>}
                color="primary"
                label="Enter Box Id*"
              />
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <Box width={"50%"}>
            <CustomInput
              disabled={true}
              value={fields?.sold_details?.amount}
              onChange={(e) =>
                setFields({
                  err: "",
                  ...fields,
                  sold_details: {
                    ...fields.sold_details,
                    amount: e.target.value,
                  },
                })
              }
              type="text"
              label={"Amount*"}
            />
          </Box>

          <Box width={"50%"}>
            <DesktopDatePicker
              // sx={{ width: "18vw", height: "100%" }}
              disableFuture
              inputFormat="DD/MM/yyyy"

              renderInput={(props) => <CustomInput {...props} />}
              label={"Date*"}
              disabled={true}
              value={fields?.sold_details?.date}
              onChange={(e) => {
                setFields({
                  ...fields,
                  sold_details: {
                    ...fields.sold_details,
                    date: moment(e).toISOString(),
                  },
                });
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <Box width={"50%"}>
            <Autocomplete
              disableClearable
              options={Object.values(PROOF_TYPE).map((value) => { return { key: value, label: titleCase(findObjectKeyByValue(value, PROOF_TYPE)) } })}
              value={findObjectKeyByValue(fields.sold_details?.id_proof_type, PROOF_TYPE)}
              onChange={(e, newVal) => {
                setFields({ ...fields, sold_details: { ...fields.sold_details, id_proof_type: newVal ? newVal.key : null } });
              }}
              sx={{
                width: "100%",
                display: "flex",
                "*": { display: "flex", justifyContent: "center" },
              }}
              renderInput={(params) => (
                <CustomInput
                  placeholder="Select Proof Type*"
                  {...params}
                  label="Select Proof Type*"
                />
              )}
            />
          </Box>
          {/* <Box width={"50%"} mt={2}>
            
          </Box> */}
          <Box width={"50%"}>
            <FileInput
              onChange={(files) => {
                setFields({
                  ...fields,
                  err: "",
                  sold_details: { ...fields.sold_details, id_proof: files },
                })
              }}
              defaults={fields?.sold_details?.id_proof ? [fields?.sold_details?.id_proof] : []}
              accept="image/*,.pdf"
              title="Upload id proof"
              subTitle="Only png,jpeg,jpg,pdf files are allowed! less than 1 MB"
            />


          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TrialProductDetailsUi;
