import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { findNameByRole } from "../../utils/main";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { Edit } from "@mui/icons-material";
import UserIncentiveListUi from "./UserIncentiveLIstUI";
import CreateUserController from "../user/CreateUserController";
import { fetchUserIncentiveApi } from "../../apis/incentive-percentage";
import { openModal } from "../../store/actions/modalAction";
import UpdateIncentivePercentageController from "./UpdateIncentivePercentageController";
import TrialIncentivePermissionController from "./TrialIncentivePermissionController";

const ActionComponent = ({ params, setParams, callBack }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  const onEdit = () => {
    dispatch(
      openModal(
        <UpdateIncentivePercentageController
          callBack={callBack}
          id={params._id}
        />,
        "sm"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <IconButton disabled={loading} size="inherit" onClick={onEdit}>
        <Edit color="info" fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

const UserIncentiveLIstController = () => {
  const dispatch = useDispatch();

  const title = "User Incentive";
  const fetchApi = fetchUserIncentiveApi;
  const { user } = useSelector((state) => state);

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    searchable: ["userId.name","userId.role"],
    search: "",
    role: "",
    sort: "",
    sortDirection: -1,
    deleted: null,
  });

  const columns = useMemo(() => {
    const arr = [
      {
        id: 1,
        fieldName: "userId.name",
        label: "Name",
        align: "left",
        sort: true,
        renderValue: (params) => params?.userId?.name || "",
      },
      {
        id: 2,
        fieldName: "userId.role",
        label: "Role",
        sort: true,
        align: "left",
        renderValue: (params) => findNameByRole(params?.userId?.role) || "",
      },
      {
        id: 3,
        fieldName: "scp_percentage",
        label: "SCP (%)",
        align: "left",
        sort: true,
      },
      {
        id: 4,
        fieldName: "fp_percentage",
        label: "FP (%)",
        align: "left",
        sort: true,
      },
      {
        id: 5,
        fieldName: "sales_staff_percentage",
        label: "Sales Staff (%)",
        align: "left",
        sort: true,
      },
      {
        id: 6,
        fieldName: "crm_incentive_percentage",
        label: "CRM (%)",
        align: "left",
        sort: true,
      },
      // {
      //   id: 7,
      //   fieldName: "",
      //   label: "Trial Incentive",
      //   align: "left",
      //   minWidth: "120px",
      //   renderValue: (params, setParams) => (
      //     <TrialIncentivePermissionController params={params} />
      //   ),
      // },
      {
        id: 8,
        fieldName: "action",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            key={Math.random()}
            params={params}
            setParams={setParams}
            callBack={fetchList}
          />
        ),
      },
    ];
    return arr;
  }, [dispatch, filters.deleted]);

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters, isIncentive: true }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <UserIncentiveListUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default UserIncentiveLIstController;
