import { Box, Card, CardContent, CardHeader, Collapse, Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { findNameByRole } from '../../utils/main'
import ProfilePicture from '../../components/upload/ProfilePicture'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AccountBalance, CallOutlined, CreditCard, Edit, MailOutline, Person, PinDrop, Subtitles } from '@mui/icons-material'
import FileDownloadComponent from '../../components/layouts/FileDownloadComponent'
import UpdateUserController from '../user/UpdateUserController'
import { openModal } from '../../store/actions/modalAction'

const Accordion = memo(({ CardHeaderTitle, data, personal, onClick }) => {
    const { user } = useSelector(state => state)

    const collapsContainer = {
        borderBottom: "1px solid #7F7F7F",
    };

    return (
        <Card
            sx={{
                cursor: "pointer",
                minWidth: 300,
                border: "1px solid #7F7F7F",
                marginTop: "12px",
                boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
            }}
        >
            <CardHeader
                title={CardHeaderTitle}
                onClick={onClick}
                action={
                    <IconButton aria-label="expand" size="small">
                        {personal ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                }
                sx={collapsContainer}
            ></CardHeader>
            <Box>
                <Collapse in={personal} timeout="auto">
                    <CardContent>
                        <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                            <TableContainer
                                sx={{ boxShadow: "none" }}
                                component={Paper}
                            >
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableBody>
                                        {data?.length > 0 && data?.reduce((rows, item, index) => {
                                            if (index % 2 === 0) {
                                                rows.push([item]);
                                            } else {
                                                rows[rows.length - 1].push(item);
                                            }
                                            return rows;
                                        }, []).map((row, rowIndex) => (
                                            <TableRow key={rowIndex}>
                                                {row.map((cell, cellIndex) => (<>
                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                alignItems: "center",
                                                                display: "flex",
                                                                lineHeight: "100%",
                                                            }}
                                                            variant="h6"
                                                        >
                                                            {cell.Icon && <cell.Icon />}
                                                            &nbsp;&nbsp;{cell?.fieldsTitle ? cell?.fieldsTitle : "NA"}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {(cell?.fieldsValue || cell?.isurl) ? cell?.fieldsValue : "NA"} {cell?.isurl && <FileDownloadComponent src={cell?.isurl} />}
                                                    </TableCell>
                                                </>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                            </TableContainer>
                        </Container>
                    </CardContent>
                </Collapse>
            </Box>
        </Card>
    )
})

const CreateProfileUI = () => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch();

    const data = {
        Personaldetails: "Personaldetails",
        Bankdetails: "Bankdetails"
    };

    const collapsContainer = {
        borderBottom: "1px solid #7F7F7F",
    };

    const UserUpdate = () => {
        console.log("first")
        dispatch(
            openModal(
                <UpdateUserController
                    id={user?.data?._id}
                />,
                "lg", true, "UpdateUser"
            )
        );
    }


    const [dropdown, setDropdown] = useState({
        Personaldetails: true,
        Bankdetails: false
    })
    return (<>
        {/* {!loading && state && ( */}
        <Box mt={3} mb={3}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <Box
                    mb={5}
                    sx={{
                        marginTop: "48px",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <ProfilePicture></ProfilePicture>
                    <Typography sx={{
                        marginTop: "24px", alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                    }} variant="h3">

                        {user?.data?.name} &nbsp;
                        <Box sx={{ cursor: "pointer" }} onClick={() => UserUpdate()} >
                            <Edit />
                        </Box>
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column", ml: "0"
                        }}
                        color={"grey"}
                        ml={2}
                    >
                        {findNameByRole(user.data.role)}
                    </Typography>
                </Box>
            </Box>
            <Accordion
                CardHeaderTitle={"Personal details"}
                onClick={() => setDropdown((prevFields) => ({
                    ...prevFields,
                    Personaldetails: !prevFields.Personaldetails
                }))}
                personal={dropdown.Personaldetails}
                data={[
                    {
                        Icon: MailOutline,
                        fieldsTitle: "email",
                        fieldsValue: user?.data?.email,
                    },
                    {
                        Icon: CallOutlined,
                        fieldsTitle: "Phone Number",
                        fieldsValue: user?.data?.phone,
                    },
                    {
                        Icon: PinDrop,
                        fieldsTitle: "Address",
                        fieldsValue: user?.data?.address,
                    },
                    {
                        Icon: CreditCard,
                        fieldsTitle: "Pan Number",
                        fieldsValue: user?.data?.pan_no,
                        isurl: user?.data?.pan_url
                    },
                ]}

            />
            <Accordion
                CardHeaderTitle={"Bank details"}
                onClick={() => setDropdown((prevFields) => ({
                    ...prevFields,
                    Bankdetails: !prevFields.Bankdetails
                }))}

                personal={dropdown.Bankdetails}
                data={[
                    {
                        Icon: AccountBalance,
                        fieldsTitle: "Bank Name",
                        fieldsValue: user?.data?.bank_details?.bank_name,
                    },
                    {
                        Icon: Subtitles,
                        fieldsTitle: "Bank IFSC",
                        fieldsValue: user?.data?.bank_details?.ifsc_code,
                    },
                    {
                        Icon: AccountBalance,
                        fieldsTitle: "Bank Account No.",
                        fieldsValue: user?.data?.bank_details?.account_no,
                    },
                    {
                        Icon: AccountBalance,
                        fieldsTitle: "Bank Proof.",
                        fieldsValue: "",
                        isurl: user?.data?.bank_details?.cancel_check,
                    },
                ]}
            />
        </Box >
        {/* )} */}
    </>)
}

export default CreateProfileUI