const mode = "development";
let domain = "";

switch (mode) {
  case "ip":
    domain = "http://10.10.111.181:8001/";
    break;
  case "local":
    domain = "http://localhost:8000/";
    break;
  case "development":
    domain = "https://wehear-backendv2-dms-20-development.up.railway.app/";
    break;
  case "production":
    domain = "https://wehear-backendv2-dms-20-production.up.railway.app/";
    break;
  default:
    domain = "/";
}    

const hrmsDomain = "https://payrollv2-production-6bc2.up.railway.app/";
// const hrmsDomain = "http://localhost:8001/";

const endpoints = { 
  root: domain,
  fileImage: `${domain}api/file/image`,
  fileFile: `${domain}api/file/file`,
  fileBase: `${domain}api/file`,
  signIn: `${domain}api/auth/login`,
  resetToken: `${domain}api/auth/reset-token`,
  resetPassword: `${domain}api/auth/reset-password`,
  userCount: `${domain}api/user/count`,
  userAndSystemFetchByToken: `${domain}api/user/fetch-by-token`,
  userBase: `${domain}api/user/`,
  userById: `${domain}api/user/fetch-by-id`,

  userEmailList: `${domain}api/user/fetch-emails`,

  userReimbursement: `${hrmsDomain}api/dms/fetch-reimbursement`,

  userProfilePicture: `${domain}api/user/update-profile`,
  fileOrImage: `${domain}api/file`,
  file: `${domain}api/file/file`,
  image: `${domain}api/file/image`,

  userDeleteUndo: `${domain}api/user/undo-delete`,
  userPassword: `${domain}api/user/reset-password`,
  secretsBase: `${domain}api/secrets/`,
  secretsById: `${domain}api/secrets/by-id`,

  notificationBase: `${domain}api/notification/`,
  notificationFetch: `${domain}api/notification/fetch`,
  notificationLastReadTimeStamp: `${domain}api/notification/last-read-timestamp`,
  notificationSetTimeStamp: `${domain}api/notification/set-last-read-timestamp`,

  ProductCreate: `${domain}api/product/`,
  ProductFetch: `${domain}api/product/fetch`,
  prodctListFetch: `${domain}api/product/all`,
  ProductDelete: `${domain}api/product/delete`,
  ProductUpdate: `${domain}api/product/update`,
  ProductFetchById: `${domain}api/product/fetch-by-id`,
  FetchProductStockById: `${domain}api/product/stock-byid`,

  createProductStock: `${domain}api/inventory/`,
  fetchProductStock: `${domain}api/inventory/`,
  fetchFindByBoxId: `${domain}api/inventory/fetch-boxId`,
  transferProductStock: `${domain}api/inventory/transfer-devices`,
  returnProductStock: `${domain}api/inventory/return-devices`,
  fetchProductStockCounts: `${domain}api/inventory/product-wise-count`,
  fetchDemoUnits: `${domain}api/inventory/fetch-demo-units`,
  fetchDemoUnitsInformation: `${domain}api/inventory/fetch-demo-units-info`,
  downloadCsvTemplate: `${domain}api/inventory/download-inventory-csv-template`,
  downloadDemoUnitsCsv: `${domain}api/inventory/download-demounits-info`,

  colorCreate: `${domain}api/product-color/`,
  colorFetch: `${domain}api/product-color/fetch`,
  colorFetchById: `${domain}api/product-color/by-id`,
  colorDelete: `${domain}api/product-color/delete`,
  colorUpdate: `${domain}api/product-color/update`,

  productTypeCreate: `${domain}api/product-type/`,
  productTypeFetch: `${domain}api/product-type/fetch`,
  productTypeFetchById: `${domain}api/product-type/by-id`,
  productTypeDelete: `${domain}api/product-type/delete`,
  productTypeUpdate: `${domain}api/product-type/`,

  updateStock: `${domain}api/stock/update-stock`,
  fetchStock: `${domain}api/stock/fetch-logs`,
  fetchStockById: `${domain}api/stock/`,
  fetchReturnStockById: `${domain}api/stock/fetch-return-data`,
  fetchReturnStockCountById: `${domain}api/stock/fetch-return-count`,
  fetchStockByStoreId: `${domain}api/stock/fetch-store-stock`,
  fetchwareHouseStock: `${domain}api/stock/warehouse-count`,
  fetchwareHouseStockData: `${domain}api/stock/warehouse-data`,

  createCustomer: `${domain}api/customer/create`,
  updateCustomer: `${domain}api/customer/update`,
  fetchCustomer: `${domain}api/customer/by-storeid`,
  fetchCustomerById: `${domain}api/customer/by-id`,
  deleteCustomer: `${domain}api/customer/delete`,
  stockLogsApi: `${domain}api/stock/fetch-logs`,
  stockCountApi: `${domain}api/stock/store-stock-count`,

  scpOrFpDataApi: `${domain}api/dashboard/scp-wise-analysis`,
  fpDataApi: `${domain}api/dashboard/fp-wise-analysis`,
  productDashboardDataApi: `${domain}api/dashboard/fetch-device-status`,
  dashboardCountData: `${domain}api/dashboard/fetch-count`,

  salesCountApi: `${domain}api/sales/sales-count`,
  fetchSalesData: `${domain}api/sales/sales-data`,
  fetchSalesValue: `${domain}api/sales/sales-value`,
  updateSalesStatus: `${domain}api/sales/sales-status`,

  trialsCountApi: `${domain}api/trials/trials-count`,
  trialsDataApi: `${domain}api/trials/trials-data`,

  //incentive-percentage
  getUserIncentive: `${domain}api/incentive-percentage/fetch`,
  getUserIncentiveById: `${domain}api/incentive-percentage/by-id`,
  updateUserIncentive: `${domain}api/incentive-percentage/update`,

  //Order
  getOrderAPI: `${domain}api/order/fetch`,
  updateOrderAPI: `${domain}api/order/update`,
  fetchOrderCountApi: `${domain}api/order/fetch-count`,

  //lead
  createLeadAPI: `${domain}api/lead/create`,
  fetchleadsCount: `${domain}api/lead/fetch-count`,
  fetchleadsData: `${domain}api/lead/fetch`,
  assignedCustomer: `${domain}api/lead/assigned`,
  leaddashboard: `${domain}api/lead/dashboard`,

  createCustomer: `${domain}api/customer/create`,
  updateCustomer: `${domain}api/customer/update`,
  fetchCustomer: `${domain}api/customer/by-storeid`,
  fetchCustomerById: `${domain}api/customer/by-id`,
  fetchCustomerCount: `${domain}api/customer/fetch-count`,
  fetchCustomerData: `${domain}api/customer/fetch-data`,
  deleteCustomer: `${domain}api/customer/delete`,
  downloadAudiogram: `${domain}api/customer/report`,
  stockLogsApi: `${domain}api/stock/fetch-logs`,
  stockCountApi: `${domain}api/stock/store-stock-count`,

  fetchSalesPerson: `${domain}api/sales-person/fetch`,
  fetchSalesPersonById: `${domain}api/sales-person/by-id`,
  fetchSalesPersonBycode: `${domain}api/sales-person/by-code`,
  createSalesPerson: `${domain}api/sales-person/`,
  updateSalesPerson: `${domain}api/sales-person/update`,
  deleteSalesPerson: `${domain}api/sales-person/delete`,

  FetchCustomerOrSales: `${domain}api/stock/sales-data`,

  CreatePaymentStatus: `${domain}api/stock/create-stock`,

  ledgerPdf: `${domain}api/ledger/pdf`,

  fetchrepairDataBoxId: `${domain}api/query/repair-data`,
  repairUpdatestatus: `${domain}api/query/repair-status`,

  fetchQueryByIdApi: `${domain}api/query/by-id`,
  fetchQueryListApi: `${domain}api/query/`,
  QueryCreate: `${domain}api/query/`,
  QueryDelete: `${domain}api/query/`,
  QueryCount: `${domain}api/query/status-count`,

  getPaymentUrl: `${domain}api/payment/create-guest`,
  addPaymentWithUpi: `${domain}api/payment/generate-qr`,

  updatePaymentApi: `${domain}api/customer/payment-details`,
  initiateRefundApi: `${domain}api/customer/initiate-refund`,
  fetchRefundDataApi: `${domain}api/customer/fetch-refund-data`,
  refundApi: `${domain}api/customer/refund`,
  fetchRefundCountApi: `${domain}api/customer/fetch-refund-count`,

  fetchIncentive: `${domain}api/customer/fetch-incentive`,
  CreateIncentive: `${domain}api/incentive/create`,
  UpdateIncentive: `${domain}api/incentive/update`,
  FetchuserIncentive: `${domain}api/incentive/fetch-user`,
  fetchDetailIncentive: `${domain}api/incentive/fetch-incentive`,

  FetchChildRoles: `${domain}api/incentive/fetch-child-roles`,
  salesdataexcel: `${domain}api/sales/sales-data-excel`,

  //Feedback
  fetchFeedbackCount: `${domain}api/feedback/fetch-count`,
  fetchFeedbackData: `${domain}api/feedback/fetch-data`,
  UpdateFeedbackData: `${domain}api/feedback/update`,
  fetchFeedbackProblems: `${domain}api/feedback/fetch-problems`,
  updateFeedbackProblemsStatus: `${domain}api/feedback/update-status`,
};

export default endpoints;
