
import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import RIGHTARROWICON from "../assets/images/rightarrow.svg";
import LEFTARROWICON from "../assets/images/leftIcon.svg";
import ucl_right from "../assets/images/ucl_right.svg";
import ucl_left from "../assets/images/ucl_left.svg";
import mcl_right from "../assets/images/mcl_right.svg";
import mcl_left from "../assets/images/mcl_left.svg";
import LetterAIcon from "../assets/images/letter-a.svg";



const ChartComponent = ({
  data,
  title,
  onValueChange,
  boneData = [],
  boneTitle = "",
  UclTitle = "",
  MclTitle = "",
  aidedData,
  UclData = [],
  MclData = [],
  rightEar,
  allowed_ac,
  allowed_bc,
  allowed_ucl,
  allowed_mcl,
  mode,
  readonly

}) => {
  const theme = useTheme();
  const [editingIndex, setEditingIndex] = useState(undefined);

  const labels = ["", "250", "500", "1k", "2k", "4k", "8k"];
  const boneImg = new Image();
  if (rightEar) {
    boneImg.src = RIGHTARROWICON;
  } else {
    boneImg.src = LEFTARROWICON;
  }
  boneImg.height = 8;
  boneImg.width = 4;

  const UclImg = new Image();
  if (rightEar) {
    UclImg.src = ucl_right;
  } else {
    UclImg.src = ucl_left;
  }
  UclImg.height = 16;
  UclImg.width = 16;

  const MclImg = new Image();
  if (rightEar) {
    MclImg.src = mcl_right;
  } else {
    MclImg.src = mcl_left;
  }
  MclImg.height = 16;
  MclImg.width = 16;

  const aidedImg = new Image();
  aidedImg.src = LetterAIcon;

  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const dataFields = {
    labels,
    datasets: [
      ...(allowed_ac || readonly
        ? [
          {
            label: title,
            data: data.map((item) => (item !== null ? item : null)),
            pointBackgroundColor: "white",
            pointStyle: rightEar ? "circle" : "crossRot",
            hoverBorderColor: rightEar ? "#C24747" : "#1265A1",
            borderColor: rightEar ? "#C24747" : "#1265A1",
            radius: rightEar ? 4 : 7,
            tension: 0, // Ensure lines are straight
          },
        ]
        : []),
      ...(allowed_bc || readonly
        ? [
          {
            label: boneTitle,
            data: boneData.map((item) => (item !== null ? item : null)),
            pointBackgroundColor: "white",
            pointStyle: boneImg,
            hoverBorderColor: rightEar ? "#C24747" : "#1265A1",
            borderColor: rightEar ? "#C24747" : "#1265A1",
            radius: rightEar ? 4 : 7,
            hoverRadius: rightEar ? 4 : 7,
            borderWidth: rightEar ? 1 : 1,
            hoverBorderWidth: rightEar ? 1 : 1,
            tension: 0, // Ensure lines are straight
          },
        ]
        : []),
      ...(allowed_ucl
        ? [
          {
            label: UclTitle,
            data: UclData.map((item) => (item !== null ? item : null)),
            pointBackgroundColor: "white",
            pointStyle: UclImg,
            hoverBorderColor: rightEar ? "#C24747" : "#1265A1",
            borderColor: rightEar ? "#C24747" : "#1265A1",
            radius: rightEar ? 4 : 7,
            hoverRadius: rightEar ? 4 : 7,
            borderWidth: rightEar ? 1 : 1,
            hoverBorderWidth: rightEar ? 1 : 1,
            tension: 0, // Ensure lines are straight
          },
        ]
        : []),
      ...(allowed_mcl
        ? [
          {
            label: MclTitle,
            data: MclData.map((item) => (item !== null ? item : null)),
            pointBackgroundColor: "white",
            pointStyle: MclImg,
            hoverBorderColor: rightEar ? "#C24747" : "#1265A1",
            borderColor: rightEar ? "#C24747" : "#1265A1",
            radius: rightEar ? 4 : 7,
            hoverRadius: rightEar ? 4 : 7,
            borderWidth: rightEar ? 1 : 1,
            hoverBorderWidth: rightEar ? 1 : 1,
            tension: 0, // Ensure lines are straight
          },
        ]
        : []),
    ],
  };

  return (
    <Line
      width="100%"
      height="100%"
      options={{
        interaction: {
          mode: "nearest",
          intersect: false, // Ensures the tooltip appears on hover anywhere
        },
        plugins: {
          datalabels: {
            display: 0,
            backgroundColor: "red",
          },
          tooltip: {
            enabled: true,
            mode: "nearest",
            intersect: false,
            // callbacks: {
            //   title: (tooltipItems) => ${tooltipItems[0].label},
            //   label: (tooltipItem) => ${-tooltipItem.raw},
            // },
          },
          legend: {
            display: false,
          },
        },
        animation: false,
        elements: {
          point: {
            radius: 0, // Hide point hover effects
          },
          line: {
            borderWidth: 1,
            tension: 0, // Ensure lines are straight
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: "Threshold",
              color: "#3C508B",
              font: {
                size: 10,
              },
            },
            min: -10,
            max: 120,
            reverse: "true",
            ticks: {
              callback: (value) => value,
              font: {
                size: 10,
              },
              stepSize: 10,
            },
          },
          x: {
            title: {
              display: true,
              text: "Frequency",
              color: "#3C508B",
              font: {
                size: 10,
              },
            },
            ticks: {
              font: {
                size: 10,
              },
            },
          },
        },
        onClick: (e, ele, chart) => {
          if (readonly) {
            return;
          }
          const xValue = chart.scales.x.getValueForPixel(e.x);
          const yValue = chart.scales.y.getValueForPixel(e.y);
          const nearestIndex = Math.round(xValue);

          if (nearestIndex >= 0 && nearestIndex < labels.length) {
            const newValue = Math.round(yValue / 5) * 5;

            if (newValue >= 0 && newValue <= 120) {
              onValueChange(nearestIndex, newValue, mode);
              setEditingIndex(nearestIndex); // set the index to edit
            } else if (newValue < 0) {
              onValueChange(nearestIndex, 0, mode);
              setEditingIndex(nearestIndex); // set the index to edit
            } else {
              onValueChange(nearestIndex, 120, mode);
              setEditingIndex(nearestIndex); // set the index to edit
            }
          }
        },
        onHover: (e, chartElement, chart) => {
          const tooltip = chart.tooltip;
          const xValue = chart.scales.x.getValueForPixel(e.x);
          const yValue = chart.scales.y.getValueForPixel(e.y);

          if (xValue !== null && yValue !== null) {
            chart.canvas.style.cursor = "pointer";
            // tooltip.setActiveElements(
            //   [{ datasetIndex: 0, index: Math.round(xValue) }],
            //   { x: e.x, y: e.y }
            // );
            tooltip.update(true);
            chart.draw();
          }
        },
        onLeave: (e, chart) => {
          chart.canvas.style.cursor = "default";
          const tooltip = chart.tooltip;
          tooltip.setActiveElements([], {});
          tooltip.update(true);
          chart.draw();
        },
      }}
      data={dataFields}
    />
  );
};

export default ChartComponent;
