import { Height, Menu } from "@mui/icons-material";
import {
  Box,
  IconButton,
  List,
  ListSubheader,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useSelector } from "react-redux";

import Profile from "./Profile";
// import NotificationModel from "../../../pages/notification/NotificationModel";
import { memo } from "react";
// import { USER_ROLES } from "../../../utils/constants/main";
import Logo from "./Logo";
import { findNameByRole, findObjectKeyByValue, toTitleCase } from "../../../utils/main";
import NotificationModel from "../../../pages/notification/NotificationModel";
import { USER_ROLES } from "../../../utils/constants";
import { titleCaseUpperCase } from "../../../utils/helper";

const headerStyle = (theme) => ({
  width: "100%",
  background: "white",
  position: "sticky",
  top: "0px",
  display: "flex",
  alignItems: "center",
  pt: 2,
  pb: 4,
  pr: 3,
  height: "72px",
  // borderBottom: "4px solid " + theme.palette.grey.main,
  zIndex: 11,
  // borderRadius: "0px",
});

const Header = ({ open, setOpen }) => {
  const user = useSelector((state) => state.user);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  // Get user role using the helper function
  const role = findNameByRole(user?.data?.role);

  return (
    <Paper elevation={0} sx={headerStyle}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        {!open && (
          <Box sx={{ width: "17vw", height: "5vh" }}>
            <Logo />
          </Box>
        )}

        <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
          {isSmallScreen ? (
            <IconButton onClick={() => setOpen(!open)}>
              <Menu />
            </IconButton>
          ) : (
            <></>
          )}
          <List
            sx={{
              // width: "260px",
              maxWidth: "100%",
              bgcolor: "background.paper",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                sx={{
                  display: "flex", alignItems: "center"
                }}
                component={Box}
                variant="subtitle1"
                pt={2}
                mt={2}
                gap={1}
                color="text.primary"
                id="nested-list-subheader"
              >
                <Typography lineHeight="150%" color="primary" variant="h4">
                  {(user.data.name &&
                    toTitleCase(user.data.name)) + " " + (user.data?.city?.length > 0 ? toTitleCase(user.data.city) : "")}
                </Typography>
                <Typography mt={1} lineHeight="100%" variant="subtitle1" color={"gray"}>
                  {user.data.role == USER_ROLES.SALES_STAFF ? "(BDE)" : "(" + titleCaseUpperCase(findObjectKeyByValue(user.data.role, USER_ROLES)) + ")"}
                </Typography>
              </ListSubheader>
            }
          ></List>
          {/* Centered User Info */}
          {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor:'white',
                justifyContent: "center",
                zIndex:"1",
                flex: 1,
              }}
            >
              <Typography variant="h2" >{user?.data?.name || "Guest User"}</Typography>
              <Typography variant="h5" color="textSecondary">
                {role || "Unknown Role"}
              </Typography>
            </Box> */}
        </Box>

        <Box mt={2} mr={2}>
          <NotificationModel />
          <Profile />
        </Box>
      </Box>
    </Paper>
  );
};

export default memo(Header);
