import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/main";

export const fetchUserIncentiveApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.getUserIncentive,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateUserIncentiveApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.updateUserIncentive,
    method: "patch",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({
      status: 0,
      response: err.response,
      code: err.response.status,
    }));

  return callResponse;
};
